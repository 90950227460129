import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Card from "./Card";

const PropertiesContent = () => {

    const { propertiesData, roomsData } = useSelector((state) => state.root)
    
    return(
        <div className="w-full justify-center px-20 py-10 sm:px-5">
            
                <div className="flex flex-col gap-8">
                     <div dangerouslySetInnerHTML={{__html: propertiesData.properties_desc}}/>
                    <div className="flex flex-wrap justify-center items-center gap-10">
                        {roomsData.map((data) => (
                            <Link to={`/properties/${data._id}`}>
                                <Card pic={data.intro_image.url} title={`${data.bedroom_name} (${data.bedroom_type})`}/>
                            </Link>
                        ))}
                    </div>
                </div>
        </div>
    )
}

export default PropertiesContent;