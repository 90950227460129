import { Modal } from "antd"
import { useEffect, useState } from "react"
import { FaCartPlus, FaPhoneAlt } from "react-icons/fa"
import { useSelector } from "react-redux"

const VehicleDescription = ({id}) => {

    const [showAddEditModal,setShowAddEditModal] = useState(false)
    const [selectedItemforEdit,setSelectedItemforEdit] = useState(null)
    const [model_name,setModelName] = useState("")
    const [model_year,setModelYear] = useState("")
    const [model_image,setModelImage] = useState("")
    const [fullName,setFullName] = useState("")
    const [phone,setPhone] = useState("")
    const [email,setEmail] = useState("")
    const [quantity, setQuantity] = useState(1)
    const {vehicleProductsData} = useSelector((state) => state.root)

    const handleDecrement = () => {
        if(quantity > 1){
            setQuantity(prevcount => prevcount - 1)
        }
    }

    const handleIncrement = () => {
        if(quantity < 100){
            setQuantity( prevcount => prevcount + 1)
        }
    }

    useEffect(() => {
        if(selectedItemforEdit){
            setModelName(selectedItemforEdit.model_name)
            setModelYear(selectedItemforEdit.model_year)
            setModelImage(selectedItemforEdit.model_images[0].url)
        }
        else{
            setModelName("")
            setModelYear("")
            setModelImage("")
        }
    },[selectedItemforEdit])

    const handleRequest = (e) => {
        e.preventDefault();
        console.log(fullName, phone, email, quantity, model_image,model_name,model_year)
    }

    return(
        <div className="flex flex-col gap-3 lg:px-0 vsmm:px-8 lgg:mt-8 ">
           {vehicleProductsData.filter(vehicle => vehicle._id === id).map(vehicle => (
                <div>
                    <div className="w-[300px] flex flex-col border-2 rounded">
                        <h1 className="text-xl p-2 uppercase font-semibold text-primary text-center">{vehicle.model_name} ({vehicle.model_year})</h1>
                        <hr/>
                        <h1 className="text-xl py-1 px-5"><span className="font-bold">Brand:-</span> {vehicle.model_brand}</h1>
                        <hr/>
                        <h1 className="text-xl py-1 px-5"><span className="font-bold">Enginelabel:-</span> {vehicle.model_enginelabel}</h1>
                        <hr/>
                        <h1 className="text-xl py-1 px-5"><span className="font-bold">Gearbox:-</span> {vehicle.model_gearbox}</h1>
                        <hr/>
                        <h1 className="text-xl py-1 px-5"><span className="font-bold">Fueltype:-</span> {vehicle.model_fueltype}</h1>
                        <hr/>
                        <h1 className="text-xl py-1 px-5"><span className="font-bold">Cylinder:-</span> {vehicle.model_cylinder}</h1>
                        <hr/>
                        <h1 className="text-xl py-1 px-5"><span className="font-bold">Seats:-</span> {vehicle.model_seats}</h1>
                        <hr/>
                        <h1 className="text-xl py-1 px-5"><span className="font-bold">Availability:-</span> {vehicle.model_availability}</h1>
                    </div>
                    <a href="tel:+251907687719">
                        <div className="bg-secondary mt-5 w-[300px] p-2 rounded flex gap-5 items-center justify-center text-white">
                            <FaPhoneAlt />
                            <h1>Call Us</h1>
                        </div>
                    </a>
                    <button className="bg-primary mt-3 text-white w-[300px] p-2 rounded flex gap-5 justify-center items-center" onClick={() => {
                        setSelectedItemforEdit(vehicle)
                        setShowAddEditModal(true)
                    }}>
                        <FaCartPlus size="20"/>
                        Request                                       
                    </button>
                </div>
           ))}
            <Modal visible={showAddEditModal}  footer={null} onCancel={() => {setShowAddEditModal(false); setSelectedItemforEdit(null)}}>
                <div>
                    <div className="flex items-center justify-center mt-8 mb-3">
                        <h1 className="text-xl vsmm:text-sm font-semibold text-primary uppercase">Request For {model_name} ({model_year})</h1>
                    </div>
                    <form onSubmit={handleRequest}>
                        <div className="flex flex-col gap-5">
                            <input type="text" className="cinput w-full" placeholder="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)}/>
                            <div className="flex gap-2">
                                <input type="text" className="cinput w-2/12" disabled value={"+251"}></input>
                                <input type="number" className="cinput w-10/12" placeholder="Ex 987654321" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                            </div>
                            <input type="email" className="cinput w-full" placeholder="example@example.com" value={email} onChange={(e) => setEmail(e.target.value)}/>
                            <div className="flex items-center gap-5">
                                <img src={model_image} className="w-1/2 rounded-md" alt="VehicleImage"/>
                                <div className="flex">
                                    <button type="button" className=" bg-gray-300 h-[34px] w-[30px] rounded-l-md" onClick={handleDecrement}>-</button>
                                    <div className="w-[80px] p-1 border-l-0  border-r-0 border-2 text-center">{quantity}</div>
                                    <button type="button" className=" bg-gray-300 h-[34px] w-[30px] rounded-r-md" onClick={handleIncrement}>+</button>
                                </div>
                            </div>
                            <div className="flex justify-end ">
                                <button className="cbutton w-[120px]">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    )
}

export default VehicleDescription