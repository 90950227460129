import { useSelector } from "react-redux";

const BimerAddress = () => {
    const {contactData} = useSelector((state) => state.root)
    const {location,phonenumber,email} = contactData
    
    return(
        <div className="w-[340px] llg2:w-8/12" >
            <div className="flex llg2:flex-col gap-5 justify-center items-center ">
                <div className="flex flex-col gap-3">
                    <div className="flex flex-col gap-1">
                        <h1 className="text-xl font-bold text-white uppercase">location:</h1>
                        <h1 className="text-white">{location}</h1>
                    </div>
                    <div className="flex flex-col gap-1">
                        <h1 className="text-xl font-bold uppercase text-white">Call us:</h1>
                        <h1 className="text-white">{phonenumber}</h1>
                    </div>
                    <div className="flex flex-col gap-1">
                        <h1 className="text-xl font-bold uppercase text-white">Email:</h1>
                        <h1 className="text-white">{email}</h1>
                    </div>
                </div>
            </div>       
        </div>
    )
}

export default BimerAddress;