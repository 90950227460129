

const Card = ({img,name}) => {

    

    return(
        <div className="flex flex-col">
            
                <div className="w-[300px] flex justify-center items-end h-[200px] " style={{backgroundImage: `url(${img})`,backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                    <div className="flex justify-center items-center flex-col inset-x-0 bottom-5 w-[300px]  h-[200px] bg-black bg-opacity-30 ">
                        <h1 className="text-4xl p5 text-center uppercase font-semibold mb-5 text-white">{name}</h1>   
                     </div>
                </div>
            
        </div>
    )
}

export default Card;