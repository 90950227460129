import { message, Modal } from "antd"
import axios from "axios"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { hiddenloading, ReloadData, showloading } from "../../../../Redux/server/rootSlice"
import {URL} from '../../../../Url/Url'

const ImportVehicles = () => {

    const { importData } = useSelector((state) => state.root)

    const [showAddEditModal,setShowAddEditModal] = useState(false)
    const [selectedItemforEdit,setSelectedItemforEdit] = useState(null)
    const [vehicles_image,setVehiclesImage] = useState('')
    const [vehicles_name,setVehiclesName] = useState('')
    const [preview,setPreview] = useState('')
    const dispatch = useDispatch()


    const token = localStorage.getItem('token')

    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        
        transformFile(file)
    }

    useEffect(() => {
        if(selectedItemforEdit){
            setVehiclesImage(selectedItemforEdit.vehicles_image)
            setVehiclesName(selectedItemforEdit.vehicles_name)
            setPreview(selectedItemforEdit.vehicles_image.url)
        }
    },[selectedItemforEdit])

    const transformFile = (file) => {
        const reader = new FileReader();
        if(file){
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setVehiclesImage(reader.result);
                setPreview(reader.result)
            };
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.patch(`${URL}/api/Bimer/vehicles/import/`+ selectedItemforEdit._id,{
                vehicles_image,vehicles_name
            },config)
            dispatch(hiddenloading())
            if(data.success === true){
                message.success('Vehicles content Updated Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
                setShowAddEditModal(false)
                setVehiclesImage('')
                setVehiclesName('')
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }

    return(
        <div>
            <div className="flex flex-wrap gap-5">
                {importData.map((data) => (
                    <div className="border-2 rounded w-[500px] h-[400px]">
                        <img className="w-[500px] h-auto" src={data.vehicles_image.url} alt=""/>
                        <div className="flex justify-between m-5 items-center">
                            <h1 className="text-2xl font-semibold text-primary">{data.vehicles_name}</h1>
                            <button className="bg-primary text-white w-[100px] px-5 py-1 rounded" onClick={() => {
                            setSelectedItemforEdit(data);
                            setShowAddEditModal(true)
                            }}>Update</button>
                        </div>
                    </div>
                ))}
            </div>
            <Modal visible={showAddEditModal} footer={null} onCancel={() => {setShowAddEditModal(false);setSelectedItemforEdit(null)}}>
                <form onSubmit={onSubmit} >
                    <div className="flex flex-col mt-10 gap-3  ">
                        <img className="w-full h-auto" src={preview} alt=""/>
                        <input className="cinput" type="file" onChange={handleFileInputChange}/>
                        <input className="cinput" placeholder="Vehicles Name" onChange={(e) => setVehiclesName(e.target.value)} value={vehicles_name}/>
                        <div className="flex mt-5 justify-end w-full">
                            <button type="submit" className="bg-primary text-white w-[100px] py-2 px-5 rounded">Update</button>
                        </div>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export default ImportVehicles