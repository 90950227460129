import { hiddenloading, ReloadData, showloading } from "../../../../Redux/server/rootSlice"
import JoditEditor from 'jodit-react';
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import axios from "axios";
import {URL} from '../../../../Url/Url'

const PropertiesDescription = () => {

    const [intro_image,setIntroImage] = useState('')
    const [properties_desc,setPropertiesDesc] = useState('')
    const [preview,setPreview] = useState('')
    const editor = useRef(null);
    const token = localStorage.getItem('token')
    const dispatch = useDispatch()

    const { propertiesData } = useSelector((state) => state.root)
    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        
        transformFile(file)
    }

    useEffect(() => {
        setPropertiesDesc(propertiesData.properties_desc)
        setIntroImage(propertiesData.intro_image)
        setPreview(propertiesData.intro_image.url)
    },[propertiesData])

    const transformFile = (file) => {
        const reader = new FileReader();
        if(file){
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setIntroImage(reader.result);
                setPreview(reader.result)
            };
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.patch(`${URL}/api/Bimer/properties`,{
                intro_image,properties_desc
            },config)
            dispatch(hiddenloading())
            if(data.success === true){
                message.success('About content Updated Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }

    return(
        <div className="flex flex-col gap-5">

           <form onSubmit={onSubmit}>
                
                <div className="flex flex-col w-full gap-3 mb-5 items-center">
                        <img className="w-full h-[500px] border-2 rounded" src={preview} alt=""/>
                        <input type="file" className="ml-5 cinput w-full" onChange={handleFileInputChange}/>
                </div>
                <div className="flex flex-col gap-5">
                <JoditEditor
                ref={editor}
                value={properties_desc}
                onChange={newContent => setPropertiesDesc(newContent)}     
                />
                </div>
                <div className="flex mt-5 justify-end w-full">
                        <button type="submit" className="bg-primary text-white w-[100px] py-2 px-5 rounded">Update</button>
                </div>
           </form>
        </div>
    )
}

export default PropertiesDescription