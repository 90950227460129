import { Modal, message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReloadData, hiddenloading, showloading } from "../../../../Redux/server/rootSlice";
import {URL} from '../../../../Url/Url'

const AdminExportItem = () => {

    const { exportItemData } = useSelector((state) => state.root)

    const [showAddEditModal,setShowAddEditModal] = useState(false)
    const [selectedItemforEdit,setSelectedItemforEdit] = useState(null)
    const [item_image,setItemImage] = useState('')
    const [item_name,setItemName] = useState('')
    const [preview, setPreview] = useState('')
    const dispatch = useDispatch()

    const token = localStorage.getItem('token')

    useEffect(() => {
        if(selectedItemforEdit){
            setItemImage(selectedItemforEdit.item_image)
            setItemName(selectedItemforEdit.item_name)
            setPreview(selectedItemforEdit.item_image.url)
        }
    },[selectedItemforEdit])

    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        
        transformFile(file)
    }

    const transformFile = (file) => {
        const reader = new FileReader();
        if(file){
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setItemImage(reader.result);
                setPreview(reader.result)
            };
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.patch(`${URL}/api/Bimer/export/items/`+ selectedItemforEdit._id,{item_image,item_name},config)
            // const {data} = await axios.post(`${URL}/api/Bimer/export/items`,{item_image,item_name},config)
                if(data.success === true){
                    dispatch(showloading())
                    setShowAddEditModal(false)
                    setItemImage('')
                    setItemName('')
                    setPreview('')
                    dispatch(ReloadData(true))
                    dispatch(hiddenloading())
                    message.success('Export Item Updated successfully')
                }
                
        }catch(err){

        }
    }

    return(
        <div>
            <div className="flex flex-col">
                <div className="flex flex-wrap gap-5">
                    {exportItemData.map((data) => (
                        <div className="flex flex-col w-[300px] border-2 rounded">
                            <img className=" w-[300px]  " src={data.item_image.url} alt="Clients" />
                            <h1 className="font-bold text-2xl uppercase text-primary px-2">{data.item_name}</h1>
                            <button  className="bg-red-500 mt-[2px] text-white w-full p-2" onClick={() => {
                        setSelectedItemforEdit(data);
                        setShowAddEditModal(true)
                        }}>Update</button>
                        </div>
                    ))}
                </div>
            </div>
            <Modal visible={showAddEditModal}  footer={null} onCancel={() => {setShowAddEditModal(false); setSelectedItemforEdit(null)}}>'
            <h1 className="text-center text-xl uppercase font-semibold mt-5 mb-5">Update Export Item</h1>
                <img className="w-full h-[250px] border-2 rounded object-cover " src={preview} alt=""/>
                <form onSubmit={handleSubmit}>
                    <input className="cinput w-full" type="file" onChange={handleFileInputChange} />
                    <div className=" flex flex-col gap-1 mt-2">
                        <label className="font-semibold uppercase">Item Name</label>
                        <input className="cinput w-full" placeholder="Services" onChange={(e) => setItemName(e.target.value)} value={item_name}/>
                    </div>
                    <div className="flex justify-end mt-3 gap-5 w-full">
                        <button type="submit" className="bg-primary text-white w-[150px] px-5 py-1 rounded">Update Item</button>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export default AdminExportItem;