/* eslint-disable no-unused-vars */
import { message, Modal } from "antd";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hiddenloading, ReloadData, showloading } from "../../../../Redux/server/rootSlice"
import {URL} from '../../../../Url/Url'

const VehiclesShowroom = () => {

    const {showroomData} = useSelector((state) => state.root)

    const [showAddEditModal,setShowAddEditModal] = useState(false)
    const [selectedItemforEdit,setSelectedItemforEdit] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteID,setDeleteId] = useState(null)
    const [showroom_image,setShowroomImage] = useState('')
    const [preview,setPreview] = useState('')
    const dispatch = useDispatch()

    const token = localStorage.getItem('token')

    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        
        transformFile(file)
    }

    const transformFile = (file) => {
        const reader = new FileReader();
        if(file){
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setShowroomImage(reader.result);
                setPreview(reader.result)
            };
        }
    }

    const handleDelete = async (id) => {
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const data = await axios.delete(`${URL}/api/Bimer/vehicles/showroom/`+ id,config)
            dispatch(hiddenloading())
            if(data.data.success === true){
                message.success('Showroom Image Deleted Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            message.error(err.message)
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.post(`${URL}/api/Bimer/vehicles/showroom`,{
                showroom_image
            },config)
            dispatch(hiddenloading())
            if(data.success === true){
                message.success('Vehicles Image Added Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
                setShowAddEditModal(false)
                setShowroomImage('')
                setPreview('')
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }


    return(
        <div className="flex flex-col">
            <div className=" flex  justify-end"> 
                <button className="bg-primary text-white w-[200px] py-2 px-5 rounded" onClick={() => {
                setSelectedItemforEdit(null);
                setShowAddEditModal(true)
                }}>Add Image</button>
            </div>
            <hr className="mt-5 mb-5"/>
            <div className="flex flex-wrap gap-5">
                    {showroomData.map((data) => (
                        <div className="flex flex-col w-[300px] h-[240px] border-2 ">
                            <img className=" w-[300px] h-[196px] " src={data.showroom_image.url} alt="Clients" />
                            <button onClick={() => {
                                setDeleteId(data._id)
                                setShowDeleteModal(true)
                            }} className="bg-red-500 mt-[2px] text-white w-full p-2">Delete</button>
                        </div>
                    ))}
            </div>
            <Modal visible={showAddEditModal} footer={null} onCancel={() => {setShowAddEditModal(false);setSelectedItemforEdit(null)}}>
                <form onSubmit={onSubmit} >
                    <div className="flex flex-col mt-10 gap-3  ">
                        <img className="w-[600px] h-[400px] border-2 rounded" src={preview} alt=""/>
                        <input className="cinput" type="file" onChange={handleFileInputChange}/>
                        <div className="flex mt-5 justify-end w-full">
                            <button type="submit" className="bg-primary text-white w-[100px] py-2 px-5 rounded">Add</button>
                        </div>
                    </div>
                </form>
            </Modal>
            <Modal visible={showDeleteModal} footer={null} closable={false} centered={true} onCancel={() => {setShowDeleteModal(false); setDeleteId(null)}}>
                    <h1 className="text-center text-2xl">Are you sure want to delete?</h1>
                    <div className="flex justify-center items-center gap-5 mt-5">
                        <button className="bg-primary w-[80px] p-1 rounded text-white" onClick={() => {handleDelete(deleteID); setShowDeleteModal(false)}}>Ok</button>
                        <button className="bg-red-500 w-[80px] p-1 rounded text-white" onClick={() => {
                            setShowDeleteModal(false) 
                            setDeleteId(null)
                        }}>Cancel</button>
                    </div>
            </Modal>
        </div>
    )
}

export default VehiclesShowroom;