const StoreNavbar = () => {
    return(
        <div className="flex items-center gap-3 bg-primary p-1 justify-between h-[80px] py-2">
            
            <img className="w-[275px] ml-5 vvsm:w-[175px] vvsm:ml-0" src="https://res.cloudinary.com/dtlrrlpag/image/upload/v1679665050/Bimer%20Website/Bimer%20Logo/Bimer_Store_Logo_spy8xi.png" alt="logo"/>
            
        </div>
    )
}

export default StoreNavbar;