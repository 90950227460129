import { Modal, message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { ReloadData, hiddenloading, showloading, setVehicleProductsData } from "../../../../Redux/server/rootSlice";
import {URL} from '../../../../Url/Url'
import Pagination from "./Pagination";
import { useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

const VehiclesManagement = () => {

    const [showAddEditModal,setShowAddEditModal] = useState(false)
    const [selectedItemforEdit,setSelectedItemforEdit] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteID,setDeleteId] = useState(null)
    const [model_name,setModelName] = useState('')
    const [model_brand,setModelBrand] = useState('')
    const [model_category,setModelCategory] = useState('')
    const [model_cylinder,setModelCylinder] = useState('')
    const [model_enginelabel,setModelEngineLabel] = useState('')
    const [model_fueltype,setModelFuelType] = useState('')
    const [model_gearbox,setModelGearbox] = useState('')
    const [model_images,setModelImages] = useState([])
    const [preview, setPreview] = useState([])
    const [newPreview,setNewPreview] = useState([])
    const [model_price,setModelPrice] = useState('')
    const [model_seats,setModelSeats] = useState('')
    const [model_year,setModelYear] = useState('')
    const [markSearch,setMarkSearch] = useState('')
    const [nameSearch,setNameSearch] = useState('')
    const [yearSearch,setYearSearch] = useState('')
    const [model_availability,setModelAvailability] = useState('in stock')
    const token = localStorage.getItem('token')
    const dispatch = useDispatch()
    const today = new Date();
    const year = today.getFullYear();
    const NewYear = []
    const query = useQuery()
    const page = query.get('page') || 1; 

    for(var i=year;i>=1950;i--){
        NewYear.push(i)
    }

    useEffect(() => {
        if(selectedItemforEdit){
            setModelAvailability(selectedItemforEdit.model_availability)
            setModelBrand(selectedItemforEdit.model_brand)
            setModelCategory(selectedItemforEdit.model_category)
            setModelCylinder(selectedItemforEdit.model_cylinder)
            setModelEngineLabel(selectedItemforEdit.model_enginelabel)
            setModelFuelType(selectedItemforEdit.model_fueltype)
            setModelGearbox(selectedItemforEdit.model_gearbox)
            setModelImages(selectedItemforEdit.model_images)
            setPreview(selectedItemforEdit.model_images)
            setModelName(selectedItemforEdit.model_name)
            setModelPrice(selectedItemforEdit.model_price)
            setModelSeats(selectedItemforEdit.model_seats)
            setModelYear(selectedItemforEdit.model_year)
        }else{
            setModelAvailability('')
            setModelBrand('')
            setModelCategory('')
            setModelCylinder('')
            setModelEngineLabel('')
            setModelFuelType('')
            setModelGearbox('')
            setModelImages([])
            setPreview([])
            setModelName('')
            setModelPrice('')
            setModelSeats('')
            setModelYear('')
        }
    },[selectedItemforEdit])

    useEffect(() => {
        if(showAddEditModal === false) {
            setPreview([])
            setNewPreview([])
        }
    },[showAddEditModal])
    
    

    const handleImageChange = (e) => {
        const files = e.target.files;
        const imagesArray = [];
    
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          // eslint-disable-next-line no-loop-func
          reader.onload = () => {
            if (reader.readyState === 2) {
              imagesArray.push(reader.result);
              setModelImages(imagesArray);
              setPreview(imagesArray)
            }
          };
    
          reader.readAsDataURL(files[i]);
        }
      };

      const handleNewImageInput = (e) => {
        const files = e.target.files;
        const imagesArray = [];
    
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          // eslint-disable-next-line no-loop-func
          reader.onload = () => {
            if (reader.readyState === 2) {
              imagesArray.push(reader.result);
              setModelImages(imagesArray);
              setNewPreview(imagesArray)
            }
          };
    
          reader.readAsDataURL(files[i]);
        }
      };


    const { brandData, vehicleProductsData, vehicleProductslengthData } = useSelector((state) => state.root)
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            if(selectedItemforEdit){
                const {data} = await axios.patch(`${URL}/api/Bimer/vehicleProducts/`+ selectedItemforEdit._id,{model_availability,model_brand,model_category,model_cylinder,model_enginelabel,model_fueltype,model_gearbox,model_name,model_price,model_seats,model_year},config)
                dispatch(hiddenloading())
                if(data.success === true){
                    setShowAddEditModal(false)
                    setModelAvailability('in stock')
                    setModelBrand('')
                    setModelCategory('')
                    setModelCylinder('')
                    setModelEngineLabel('')
                    setModelFuelType('')
                    setModelGearbox('')
                    setModelImages([])
                    setPreview([])
                    setModelName('')
                    setModelPrice('')
                    setModelSeats('')
                    setModelYear('')
                    message.success("Vehicle Updated Successfuly")
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }else{
                const {data} = await axios.post(`${URL}/api/Bimer/vehicleProducts`,{model_availability,model_brand,model_category,model_cylinder,model_enginelabel,model_fueltype,model_gearbox,model_images,model_name,model_price,model_seats,model_year},config)
                dispatch(hiddenloading())
                if(data.success === true){
                    setShowAddEditModal(false)
                    setModelAvailability('in stock')
                    setModelBrand('')
                    setModelCategory('')
                    setModelCylinder('')
                    setModelEngineLabel('')
                    setModelFuelType('')
                    setModelGearbox('')
                    setModelImages([])
                    setPreview([])
                    setModelName('')
                    setModelPrice('')
                    setModelSeats('')
                    setModelYear('')
                    message.success("Vehicle Added Successfuly")
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }

    }

    const searchVehicles = async() => {
        try{     
            const responce = await axios.get(`${URL}/api/Bimer/vehicleProducts/search?searchQuery=${nameSearch || 'none'}&model_brand=${markSearch || 'none'}&model_year=${yearSearch || ''}`)
            dispatch(setVehicleProductsData(responce.data.vehicleproducts))
            dispatch(ReloadData(false))
            dispatch(hiddenloading())        
        }catch(err){
            dispatch(hiddenloading())
        }        
    }

    const handleImageDelete = async (id) => {
        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
            dispatch(showloading())
            const data = await axios.delete(`${URL}/api/Bimer/vehicleProducts/${selectedItemforEdit._id}/` + id, config)
            dispatch(hiddenloading())
            if(data.data.success === true){
                setShowAddEditModal(false)
                message.success('Vehicles Image Delete Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            message.error(err.message)
        }
    }

    const handleDelete = async (id) => {
        try{
            const config = {
                headers:{
                    Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const data = await axios.delete(`${URL}/api/Bimer/vehicleProducts/` + id, config)
            dispatch(hiddenloading())
            if(data.data.success === true){
                message.success('Vehicle Item Deleted Successfully')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            message.error(err.message)
        }
    }

    const handleNewImages = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            if(selectedItemforEdit){
                const {data} = await axios.patch(`${URL}/api/Bimer/vehicleProducts/image/` + selectedItemforEdit._id,{
                    model_images
                },config)
                dispatch(hiddenloading())
                if(data.success === true){
                    setShowAddEditModal(false)
                    setModelImages([])
                    setNewPreview([])
                    message.success('Vehicles Image Addes Successfuly')
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }

    useEffect(() => {

    },[vehicleProductsData])
    

    return(
        <div>
            <div className="flex flex-col">
                <div className="flex justify-end">
                    <button className="bg-primary  text-white w-[200px] py-2 px-5 rounded" onClick={() => {
                        setSelectedItemforEdit(null)
                        setShowAddEditModal(true)
                    }}>Add New Vehicle
                    </button>
                </div>
                <hr className="mt-5 mb-5"/>

                <div className="flex flex-col justify-center">
                   
                        <div className="flex justify-center gap-5">
                                <select className="cinput w-[400px]" value={markSearch} onChange={(e) => setMarkSearch(e.target.value)}>
                                    <option value="" disabled selected hidden>Mark</option>
                                    {brandData.map((data) => (
                                        <option value={data.brand_name}>{data.brand_name}</option>
                                    ))}
                                </select>
                            
                            
                                <select disabled={markSearch === ''} className="cinput w-[400px]" value={nameSearch} onChange={(e) => setNameSearch(e.target.value)}>
                                    <option value="" hidden>Model</option>
                                    {vehicleProductslengthData.filter((name) => markSearch === name.model_brand).map((data) => (
                                        <option value={data.model_name}>{data.model_name}</option>
                                    ))}
                                </select>
                           
                        </div>
                        <div className="flex -ml-5 mt-3 justify-center gap-5">
                                <select className="cinput w-[400px]" value={yearSearch} onChange={(e) => setYearSearch(e.target.value)}>
                                    <option value="">Model Year</option>
                                    {NewYear.map((year) => (
                                        <option value={year}>{year}</option>
                                    ))}
                                </select>
                        
                                <button onClick={searchVehicles} className="cbutton w-[400px]">
                                    Search
                                </button>
                        

                        </div>
                    
                </div>

                <div >
                    {vehicleProductsData.length === 0 ? <img className="w-[400px] object-contain" src="https://res.cloudinary.com/dbewtrc1q/image/upload/v1687779786/Bimer%20Extra%20Items/dprgbk8ejv3tw0pkocab.jpg" alt="no_item_found"/> : <div className="flex flex-wrap justify-center mt-10 gap-8 w-full">
                        {vehicleProductsData.map((item) => (
                            <div className="flex flex-col  w-[275px] rounded-md">
                                <img className="w-[275px] h-[200px] " src={item.model_images[0].url}  alt="VehicleImage"/>   
                                <h1 className="text-xl font-semibold text-center mt-3">{item.model_name}</h1>
                                <div className="flex gap-3 mt-3 w-full">
                                    <button className="p-2 text-white bg-primary w-1/2" onClick={() => {
                                        setSelectedItemforEdit(item)
                                        setShowAddEditModal(true)
                                    }}>Edit</button>
                                     <button className="p-2 text-white bg-red-500 w-1/2" onClick={() => {
                                        setDeleteId(item._id)
                                        setShowDeleteModal(true)
                                    }}>Delete</button>
                                </div>
                            </div>
                        ))}
                    </div>}
                </div>
                <div className={vehicleProductsData.length === 0 ? " hidden" : "flex justify-center mt-5 "}>
                        <Pagination page={page}/>
                </div>
            </div>
            <Modal visible={showAddEditModal} width={'800px'} footer={null} onCancel={() => {setShowAddEditModal(false); setSelectedItemforEdit(null)}}>
                <h1 className="text-center text-xl uppercase font-semibold mt-5 mb-5">{selectedItemforEdit ? 'Update Vehicle' : 'Add New Vehicle' }</h1>   
                <form onSubmit={handleSubmit}>
                    <div className="flex gap-5 w-full items-center">
                        <div className="flex flex-col w-1/2">
                        <div className="flex flex-col gap-1 w-full">
                            <label className="font-bold uppercase">Model Name:</label>
                            <input className="cinput w-full" type="text" onChange={(e) => setModelName(e.target.value)} value={model_name}/>
                        </div>
                        <div className="flex flex-col gap-1 w-full mt-2">
                            <label className="font-bold uppercase">Model Mark:</label>
                            <select className="cinput w-full" value={model_brand} onChange={(e) => setModelBrand(e.target.value)}>
                                <option value="" disabled selected hidden>Vehicle Mark</option>
                                {brandData.map((data) => (
                                    <option value={data.brand_name}>{data.brand_name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-col gap-1 w-full mt-2">
                            <label className="font-bold uppercase">Category:</label>
                            <select className="cinput w-full" value={model_category} onChange={(e) => setModelCategory(e.target.value)}>
                                <option value={"Vehicle"}>Vehicle</option>
                            </select>
                        </div>
                        <div className="flex flex-col gap-1 w-full mt-2">
                            <label className="font-bold uppercase">Model Year:</label>
                            <select className="cinput w-full" value={model_year} onChange={(e) => setModelYear(e.target.value)}>
                                <option value="" disabled selected hidden>Model Year</option>
                                {NewYear.map((year) => (
                                    <option value={year}>{year}</option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-col gap-1 w-full mt-2">
                            <label className="font-bold uppercase">Model Seats:</label>
                            <input className="cinput w-full" type="number" onChange={(e) => setModelSeats(e.target.value)} value={model_seats}/>
                        </div>
                        <div className="flex flex-col gap-1 w-full mt-2">
                            <label className="font-bold uppercase">Model Engine Label:</label>
                            <input className="cinput w-full" type="text" onChange={(e) => setModelEngineLabel(e.target.value)} value={model_enginelabel}/>
                        </div>
                        </div>
                        <div className="flex flex-col w-1/2">
                        <div className="flex flex-col gap-1 w-full">
                            <label className="font-bold uppercase">Model Cylinders:</label>
                            <input className="cinput w-full" type="number" onChange={(e) => setModelCylinder(e.target.value)} value={model_cylinder}/>
                        </div>
                        <div className="flex flex-col gap-1 w-full mt-2">
                            <label className="font-bold uppercase">Model Gearbox:</label>
                            <input className="cinput w-full" type="text" onChange={(e) => setModelGearbox(e.target.value)} value={model_gearbox}/>
                        </div>
                        <div className="flex flex-col gap-1 w-full mt-2">
                            <label className="font-bold uppercase">Model Fuel Type:</label>
                            <input className="cinput w-full" type="text" onChange={(e) => setModelFuelType(e.target.value)} value={model_fueltype}/>
                        </div>
                        <div className="flex flex-col gap-1 w-full mt-2">
                            <label className="font-bold uppercase">Model Price:</label>
                            <input className="cinput w-full" type="number" onChange={(e) => setModelPrice(e.target.value)} value={model_price}/>
                        </div>
                        <div className="flex flex-col gap-1 w-full mt-2">
                            <label className="font-bold uppercase">Model Availability:</label>
                            <select className="cinput w-full" value={model_availability} onChange={(e) => setModelAvailability(e.target.value)}>
                                <option  value="in stock">IN STOCK</option>
                                <option value="on shipping">ON SHIPPING</option>
                                <option value="out off stock">OUT OFF STOCK</option>
                            </select>
                        </div>
                        <div className={selectedItemforEdit ? 'hidden' : "flex flex-col gap-1 w-full mt-2"}>
                            <label className="font-bold uppercase">Model Images:</label>
                            <input className="cinput w-full" type="file" multiple onChange={handleImageChange}/>
                        </div>
                        </div>
                    </div>
                    <div className="flex justify-end mt-3 gap-5 w-full">
                        <button type="submit" className="bg-primary text-white w-[150px] px-5 py-1 rounded">{selectedItemforEdit ? 'Update Vehicle' : 'Add Vehicle'}</button>
                    </div>
                </form>
                <div className="flex flex-wrap gap-5 mt-5 justify-center items-center">
                    {preview.map((data) => (
                        <div className=" w-[200px] object-contain">
                            <img className="h-[135px] object-cover" src={selectedItemforEdit ? data.url : data} alt="pic"/>
                            <button className={selectedItemforEdit ? 'text-red-500 ml-[180px] -mt-[130px] absolute z-10' : 'hidden'} onClick={() => {
                                handleImageDelete(data._id)
                            }}><FaTrash/></button>
                        </div>
                    ))}
            </div>
            <div className={selectedItemforEdit ? "flex flex-wrap gap-5 mt-5 justify-center items-center" : "hidden"}>
                    {newPreview.map((data) => (
                        <div className=" w-[200px] object-contain">
                            <img className="h-[135px] object-cover" src={data} alt="pic"/>
                        </div>
                    ))}
            </div>
            <div className={selectedItemforEdit ? 'flex flex-col gap-2' : 'hidden'}>
                <form onSubmit={handleNewImages}>
                    <label className='font-bold mt-10 mb-3'>Project Images</label>
                    <input className='cinput w-full' type="file" multiple onChange={selectedItemforEdit ? handleNewImageInput : handleImageChange}/>
                    <div className="flex justify-end mt-3 gap-5 w-full">
                        <button type="submit" className="bg-primary text-white w-[150px] px-5 py-1 rounded">Add Image</button>
                    </div>
                </form>
            </div>    
           </Modal>
           <Modal visible={showDeleteModal} footer={null} closable={false} centered={true} onCancel={() => {setShowDeleteModal(false); setDeleteId(null)}}>
                    <h1 className="text-center text-2xl">Are you sure want to delete?</h1>
                    <div className="flex justify-center items-center gap-5 mt-5">
                        <button className="bg-primary w-[80px] p-1 rounded text-white" onClick={() => {handleDelete(deleteID); setShowDeleteModal(false)}}>Ok</button>
                        <button className="bg-red-500 w-[80px] p-1 rounded text-white" onClick={() => {
                            setShowDeleteModal(false) 
                            setDeleteId(null)
                        }}>Cancel</button>
                    </div>
            </Modal>
        </div>
    )
}

export default VehiclesManagement;