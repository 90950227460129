/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useSelector } from "react-redux"

const VehicleTitle = ({id}) => {

    const {vehicleProductsData} = useSelector((state) => state.root)

    useEffect(() => {
        vehicleProductsData.filter(vehicles => vehicles._id === id ).map(vehicles => (
            document.title = `${vehicles.model_name} - Bimer Business Group`
        ))
    },[id])

    return(
        <div className="flex items-center justify-center">
            {vehicleProductsData.filter(vehicle => vehicle._id === id).map(vehicle => (
                <h1 className="text-2xl mt-5 mb-10 uppercase font-bold">{vehicle.model_name} ({vehicle.model_year})</h1>
            ))}
        </div>
    )
}

export default VehicleTitle;