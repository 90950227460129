import { useEffect } from "react";
import { NavLink } from "react-router-dom";

const AccessDenied = () => {

    return(
        <div className="flex flex-col h-screen justify-center items-center">
            <div className="h-[20vh]">
                <lottie-player src="https://lottie.host/0b3061d8-00ee-477f-aa32-48922c3a44b6/OdE3Vj64g3.json"  background="transparent"  speed="1"  loop autoplay></lottie-player>
            </div>
            <h1 className="text-4xl vsmm:text-2xl vsmm:mb-3 mt-5 mb-5 font-bold uppercase text-primary">Access Denied</h1>
            <h1 className="text-[18px] w-[600px] text-center vs2:w-[450px] vsmm:text-[12px] vsmm:w-[300px]">Apologies! You currently don't have the necessary permissions to access this page. Please try again later.</h1>
            <NavLink to='/'><button className="bg-secondary text-white rounded-2xl mt-5  py-2 px-5 w-[150px] uppercase text-[14px]">Go to Home</button> </NavLink>
        </div>
    )
}

export default AccessDenied;