import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reset } from '../../Redux/auth/authSlice'
import Sidebar from "../Components/SidebarComponents/sidebar";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import Banner from "../Components/AdminHomePageComponents/BannerComponents/Banner";
import Services from "../Components/AdminHomePageComponents/ServicesComponents/Services";
import Testimonials from "../Components/AdminHomePageComponents/TestimonialsComponents/Testimonial";
import Clients from "../Components/AdminHomePageComponents/ClientComponents/Clients";
import ScreenError from "./ScreenError";

const AdminHomePage = () => {

    useEffect(() => {
        document.title = "Admin Home Page Management - Bimer Business Group"
    })

    const { bannerData,serviceData,testimonialData } = useSelector((state) => state.root)
    const [show,setShow] = useState(true)

    const ScreenSize = () => {
        if(window.innerWidth <= 1080){
            setShow(false)
        }
        else {
            setShow(true)
        }
    }

    useEffect(() => {
        ScreenSize()
        window.addEventListener('resize',() => {ScreenSize()})
    },[])

    const navigate = useNavigate()
        const dispatch = useDispatch()
    
        const { user } = useSelector((state) => state.auth)
    
        useEffect(() => {
        
            if (!user) {
              navigate('/administrator')
            }
        
            return () => {
              dispatch(reset())
            }
          }, [user, navigate, dispatch])


    return(
        <div>
            {!show ? <ScreenError/> : <div>
            {bannerData && serviceData && testimonialData && (
                <div className="flex">
                    <Sidebar/>
                    <div className="flex flex-col w-full p-20 ml-[260px]">
                        <Tabs  defaultActiveKey="1">
                            <TabPane tab='Banner' key="1">
                                <Banner/>
                            </TabPane>
                            <TabPane tab='Services' key="2">
                                <Services/>
                            </TabPane>
                            <TabPane tab='Testimonials' key="3">
                                <Testimonials/>
                            </TabPane>
                            <TabPane tab='Clients' key="4">
                                <Clients/>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            )}
        
        </div>}
        </div>
    )
}

export default AdminHomePage;