import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    bannerData: null,
    serviceData:null,
    testimonialData:null,
    clientData:null,
    contactData:null,
    teamData:null,
    aboutData:null,
    logisticsData:null,
    liftingData:null,
    vehiclesData:null,
    importData:null,
    showroomData: null,
    propertiesData: null,
    roomsData:null,
    onebedroomAData:null,
    onebedroomBData:null,
    onebedroomCData:null,
    twobedroomAData:null,
    twobedroomBData:null,
    threebedroomData:null,
    safetyData:null,
    brandData:null,
    vehicleProductsData: null,
    vehicleProductslengthData:null,
    sparepartItemsData: null,
    sparepartItemsLengthData: null,
    exportData: null,
    exportItemData:null,
    room201Data:null,
    room202Data:null,
    room203Data:null,
    room301Data:null,
    room302Data:null,
    room303Data:null,
    room401Data:null,
    room402Data:null,
    room403Data:null,
    room501Data:null,
    room502Data:null,
    room503Data:null,
    room601Data:null,
    room602Data:null,
    room603Data:null,
    room701Data:null,
    room702Data:null,
    room703Data:null,
    room801Data:null,
    room802Data:null,
    room803Data:null,
    room901Data:null,
    room902Data:null,
    room903Data:null,
    isLoading: false,
    message: '',
    reloadData: false
}

export const rootSlice = createSlice({
    name: 'root',
    initialState,
    reducers: {
        showloading: (state,action) => {
            state.isLoading = true
        },
        hiddenloading: (state,action) => {
            state.isLoading = false
        },
        setBannerData: (state,action) => {
            state.bannerData = action.payload
        },
        setServicesData: (state,action) => {
            state.serviceData = action.payload
        },
        setTestimonialData:(state,action) => {
            state.testimonialData = action.payload
        },
        setClientData:(state,action) => {
            state.clientData = action.payload
        },
        setContactData:(state,action) => {
            state.contactData = action.payload
        },
        setTeamData:(state,action) => {
            state.teamData = action.payload
        },
        setAboutData:(state,action) => {
            state.aboutData = action.payload
        },
        setLogisticsData:(state,action) => {
            state.logisticsData = action.payload
        },
        setLiftingData:(state,action) => {
            state.liftingData = action.payload
        },
        setVehiclesData:(state,action) => {
            state.vehiclesData = action.payload
        },
        setImportData:(state,action) => {
            state.importData = action.payload
        },
        setShowroomData: (state,action) => {
            state.showroomData = action.payload
        },
        setPropertiesData: (state,action) => {
            state.propertiesData = action.payload
        },
        setRoomsData: (state,action) => {
            state.roomsData = action.payload
        },
        setOneBedroomAData:(state,action) => {
            state.onebedroomAData = action.payload
        },
        setOneBedroomBData:(state,action) => {
            state.onebedroomBData = action.payload
        },
        setOneBedroomCData:(state,action) => {
            state.onebedroomCData = action.payload
        },
        setTwoBedroomAData:(state,action) =>{
            state.twobedroomAData = action.payload
        },
        setTwoBedroomBData:(state,action) =>{
            state.twobedroomBData = action.payload
        },
        setThreeBedroomData:(state,action) =>{
            state.threebedroomData = action.payload
        },
        setSafetyData:(state,action) => {
            state.safetyData = action.payload
        },
        setBrandData:(state,action) => {
            state.brandData = action.payload
        },
        setVehicleProductsData: (state,action) => {
            state.vehicleProductsData = action.payload
        },
        setVehicleProductslengthData: (state,action) => {
            state.vehicleProductslengthData = action.payload
        },
        setSparepartItemsData : (state,action) => {
            state.sparepartItemsData = action.payload
        },
        setSparepartItemsLengthData: (state,action) => {
            state.sparepartItemsLengthData = action.payload
        },
        setExportData: (state,action) => {
            state.exportData = action.payload
        },
        setExportItemData: (state,action) => {
            state.exportItemData = action.payload
        },
        setRoom201Data: (state,action) => {
            state.room201Data = action.payload
        },
        setRoom202Data: (state,action) => {
            state.room202Data = action.payload
        },
        setRoom203Data: (state,action) => {
            state.room203Data = action.payload
        },
        setRoom301Data: (state,action) => {
            state.room301Data = action.payload
        },
        setRoom302Data: (state,action) => {
            state.room302Data = action.payload
        },
        setRoom303Data: (state,action) => {
            state.room303Data = action.payload
        },
        setRoom401Data: (state,action) => {
            state.room401Data = action.payload
        },
        setRoom402Data: (state,action) => {
            state.room402Data = action.payload
        },
        setRoom403Data: (state,action) => {
            state.room403Data = action.payload
        },
        setRoom501Data: (state,action) => {
            state.room501Data = action.payload
        },
        setRoom502Data: (state,action) => {
            state.room502Data = action.payload
        },
        setRoom503Data: (state,action) => {
            state.room503Data = action.payload
        },
        setRoom601Data: (state,action) => {
            state.room601Data = action.payload
        },
        setRoom602Data: (state,action) => {
            state.room602Data = action.payload
        },
        setRoom603Data: (state,action) => {
            state.room603Data = action.payload
        },
        setRoom701Data: (state,action) => {
            state.room701Data = action.payload
        },
        setRoom702Data: (state,action) => {
            state.room702Data = action.payload
        },
        setRoom703Data: (state,action) => {
            state.room703Data = action.payload
        },
        setRoom801Data: (state,action) => {
            state.room801Data = action.payload
        },
        setRoom802Data: (state,action) => {
            state.room802Data = action.payload
        },
        setRoom803Data: (state,action) => {
            state.room803Data = action.payload
        },
        setRoom901Data: (state,action) => {
            state.room901Data = action.payload
        },
        setRoom902Data: (state,action) => {
            state.room902Data = action.payload
        },
        setRoom903Data: (state,action) => {
            state.room903Data = action.payload
        },
        ReloadData: (state,action) => {
            state.reloadData = action.payload 
        }
    }
})

export const {
    showloading,
    hiddenloading,
    setBannerData,
    setServicesData,
    setTestimonialData,
    setClientData,
    setContactData,
    setTeamData,
    setAboutData,
    setLogisticsData,
    setLiftingData,
    setVehiclesData,
    setImportData,
    setShowroomData,
    setPropertiesData,
    setRoomsData,
    setOneBedroomAData,
    setOneBedroomBData,
    setOneBedroomCData,
    setTwoBedroomAData,
    setTwoBedroomBData,
    setThreeBedroomData,
    setSafetyData,
    setBrandData,
    setVehicleProductsData,
    setVehicleProductslengthData,
    setSparepartItemsData,
    setSparepartItemsLengthData,
    setExportData,
    setExportItemData,
    setRoom201Data,
    setRoom202Data,
    setRoom203Data,
    setRoom301Data,
    setRoom302Data,
    setRoom303Data,
    setRoom401Data,
    setRoom402Data,
    setRoom403Data,
    setRoom501Data,
    setRoom502Data,
    setRoom503Data,
    setRoom601Data,
    setRoom602Data,
    setRoom603Data,
    setRoom701Data,
    setRoom702Data,
    setRoom703Data,
    setRoom801Data,
    setRoom802Data,
    setRoom803Data,
    setRoom901Data,
    setRoom902Data,
    setRoom903Data,
    ReloadData 
} = rootSlice.actions
export default rootSlice.reducer