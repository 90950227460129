/* eslint-disable no-unused-vars */
import { Form, message } from "antd"
import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { hiddenloading, ReloadData, showloading } from "../../../../Redux/server/rootSlice"
import JoditEditor from 'jodit-react';
import {URL} from '../../../../Url/Url'


const VisionAndValue = () => {
    const { aboutData } = useSelector((state) => state.root)

    const [vision_desc,setVisionDesc] = useState('')
    const [vision_image,setVisionImage] = useState('')
    const [preview,setPreview] = useState('')
    const token = localStorage.getItem('token')
    const dispatch = useDispatch()
    const editor = useRef(null);

    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        
        transformFile(file)
    }

    const transformFile = (file) => {
        const reader = new FileReader();
        if(file){
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setVisionImage(reader.result);
                setPreview(reader.result)
            };
        }
    }

    useEffect(() => {
        setVisionDesc(aboutData.vision_desc)
        setVisionImage(aboutData.vision_image)
        setPreview(aboutData.vision_image.url)
    },[aboutData])
    
    const onSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.patch(`${URL}/api/Bimer/about/vision`,{
                vision_desc,vision_image
            },config)
            dispatch(hiddenloading())
            if(data.success === true){
                message.success('About content Updated Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }
    return(
        <div className="flex flex-col gap-5">
        <form onSubmit={onSubmit}>
            <h1 className="text-2xl mt-5 font-semibold text-primary text-center uppercase">Our Vision and Values</h1>
                <div className="flex flex-col mt-5 gap-5">
                <JoditEditor
                ref={editor}
                value={vision_desc}
                onChange={newContent => setVisionDesc(newContent)}     
                />
                </div>
                <h1 className="text-2xl mt-5 font-semibold text-primary text-center uppercase">Our Vision and Values Image</h1>
                <div className="flex mt-5 w-full gap-5 items-center">
                    <div className="w-1/2">
                        <img className="w-[400px] h-[300px] border-2 rounded" src={preview} alt=""/>
                    </div>
                    <div className="flex flex-col w-1/2">
                        <input type="file" className="cinput w-[400px]" onChange={handleFileInputChange}/>
                    </div>
                </div>
                <div className="flex mt-5 justify-end w-full">
                        <button type="submit" className="bg-primary text-white w-[100px] py-2 px-5 rounded">Update</button>
                </div>
        </form>
     </div>
    )
}

export default VisionAndValue