const Dots = ({activeIndex, onClick, carouselData}) => {
    return(
        <div className="w-full absolute inset-x-0 bottom-5 gap-3 flex justify-center items-center">
            {carouselData.map((data,index) => (
                <span key={index} className={`cursor-pointer h-[12px] w-[12px] rounded-full inline-block ${activeIndex === index ? 'bg-primary': 'bg-secondary opacity-70'}`} onClick={() => onClick(index)}></span>
            ))}
        </div>
    )
}

export default Dots;