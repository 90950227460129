import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReloadData, hiddenloading, setVehicleProductsData } from "../../../../Redux/server/rootSlice";
import axios from "axios";
import { URL } from "../../../../Url/Url";
import Pagination from "./Pagination";
import { Link, useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

const VehicleStore = () => {

    const [markSearch,setMarkSearch] = useState('')
    const [nameSearch,setNameSearch] = useState('')
    const [yearSearch,setYearSearch] = useState('')
    const dispatch = useDispatch()
    const today = new Date();
    const year = today.getFullYear();
    const NewYear = []
    for(var i=year;i>=1950;i--){
        NewYear.push(i)
    }
    const query = useQuery()
    const page = query.get('page') || 1; 

    const {brandData,vehicleProductsData,vehicleProductslengthData} = useSelector((state) => state.root)

    const searchVehicles = async() => {
        try{     
            const responce = await axios.get(`${URL}/api/Bimer/vehicleProducts/search?searchQuery=${nameSearch || 'none'}&model_brand=${markSearch || 'none'}&model_year=${yearSearch || ''}`)
            dispatch(setVehicleProductsData(responce.data.vehicleproducts))
            dispatch(ReloadData(false))
            dispatch(hiddenloading())        
        }catch(err){
            dispatch(hiddenloading())
        }        
    }

    return(
        <div className="flex flex-col px-20 py-10 sm:px-5 sm:py-3">
            <div className="flex flex-col justify-center">
                   
                   <div className="flex justify-center gap-5">
                           <select className="cinput w-[400px]" value={markSearch} onChange={(e) => setMarkSearch(e.target.value)}>
                               <option value="" disabled selected hidden>Mark</option>
                               {brandData.map((data) => (
                                   <option value={data.brand_name}>{data.brand_name}</option>
                               ))}
                           </select>
                       
                       
                           <select disabled={markSearch === ''} className="cinput w-[400px]" value={nameSearch} onChange={(e) => setNameSearch(e.target.value)}>
                               <option value="" hidden>Model</option>
                               {vehicleProductslengthData.filter((name) => markSearch === name.model_brand).map((data) => (
                                   <option value={data.model_name}>{data.model_name}</option>
                               ))}
                           </select>
                      
                   </div>
                   <div className="flex llg:-ml-0 -ml-6 mt-3 justify-center gap-5">
                           <select className="cinput w-[400px]" value={yearSearch} onChange={(e) => setYearSearch(e.target.value)}>
                               <option value="">Model Year</option>
                               {NewYear.map((year) => (
                                   <option value={year}>{year}</option>
                               ))}
                           </select>
                   
                           <button onClick={searchVehicles} className={markSearch === "" ? "disabled cbutton w-[400px]" : 'cbutton w-[400px]'}>
                               Search
                           </button>
                   

                   </div>
               
           </div>
            <div>
                {vehicleProductsData.length === 0 ? <div className="flex justify-center mt-10"><img className="w-[400px]  object-contain" src="https://res.cloudinary.com/dbewtrc1q/image/upload/v1687779786/Bimer%20Extra%20Items/dprgbk8ejv3tw0pkocab.jpg" alt="no_item_found"/></div> : <div className="flex flex-wrap justify-center mt-10 gap-8 w-full">
                    {vehicleProductsData.map((item) => (
                        <Link className=" hover:text-secondary" to={`/bimerstore/vehicles/${item._id}`}>
                            <div className="flex flex-col  w-[275px] rounded-md">
                                <img className="w-[275px] h-[200px] " src={item.model_images[0].url}  alt="VehicleImage"/>   
                                <h1 className="text-xl font-semibold text-center mt-3">{item.model_name}</h1>
                            </div>
                        </Link>
                    ))}
                </div>}
            </div>
            <div className={vehicleProductsData.length === 0 ? " hidden" : "flex justify-center mt-10 sm:mt-5 "}>
                <Pagination page={page}/>
            </div>
        </div>
    )
}

export default VehicleStore;