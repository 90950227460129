const Card = ({pic,title}) => {
    return(
        <div className="flex flex-wrap gap-10  hover:text-primary">
            <div className="flex flex-col justify-center gap-3  items-center">
                <img className="w-[400px] vsmm:w-[300px] vsmm:h-[150px] h-[250px] hover:shadow-xl  border-2 rounded" src={pic} alt="BedroomImage"></img>
                <h1 className="text-xl text-center font-semibold">{title}</h1>
            </div>
        </div>
    )
}

export default Card