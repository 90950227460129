import { useEffect } from "react"
import { NavLink } from "react-router-dom";

const NotFoundPage = () => {

    useEffect(() => {
        document.title = "404 - Page Not Found"
        
    })
    return(
        <div className="bg-black h-[100vh] flex flex-col justify-center items-center gap-5">
            <div className = "h-[50vh]">
                <lottie-player src="https://assets6.lottiefiles.com/packages/lf20_q2pevjuc.json"  background="transparent"  speed="1"  loop autoplay></lottie-player>
            </div>
            <h1 className="text-4xl sm:text-2xl text-white font-bold uppercase ">Page Not Found</h1>
            <h1 className="text-sm text-white w-full text-center">The Page you are looking for might have been removed <br/>had its name changed or is temporarily unavailable.</h1>
            <NavLink to='/'><button className="bg-secondary text-white rounded-2xl  py-2 px-5 w-[200px] uppercase">Go to Homepage</button> </NavLink>
        </div>
    )
}

export default NotFoundPage