import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReloadData, hiddenloading, setSparepartItemsData } from "../../../../Redux/server/rootSlice";
import { URL } from "../../../../Url/Url";
import Pagination from "./Pagination";
import { Link, useLocation } from "react-router-dom"

function useQuery() {
    return new URLSearchParams(useLocation().search)
}


const SparepartStore = () => {

    const { brandData, sparepartItemsData} = useSelector((state) => state.root)

    const [partNumberSearch,setPartNumberSearch] = useState('')
    const [typeSearch,setTypeSearch] = useState('')
    const [brandSearch,setBrandSearch] = useState('')
    const dispatch = useDispatch()
    const query = useQuery()
    const page = query.get('page') || 1; 

    const searchSparepart = async () => {
        try{
            const responce = await axios.get(`${URL}/api/Bimer/sparepartitems/search?searchQuery=${partNumberSearch || ""}&sparepart_type=${typeSearch || ""}&sparepart_brand=${brandSearch || ""}`)
            dispatch(setSparepartItemsData(responce.data.sparepartitems))
            dispatch(ReloadData(false))
            dispatch(hiddenloading())
        }catch(err){
            dispatch(hiddenloading())
        }
      }

    return(
        <div className="flex flex-col px-20 py-10 sm:px-5 sm:py-3">

            <div className="flex flex-col justify-center">
                   
                <div className="flex justify-center gap-5">
                           
                    <input className="cinput w-[400px]" value={partNumberSearch} onChange={(e) => setPartNumberSearch(e.target.value)} placeholder="Part Number"/>
                    <input className="cinput w-[400px]" value={typeSearch} onChange={(e) => setTypeSearch(e.target.value)} placeholder="Spare Part Name"/>
                      
                </div>
                <div className="flex -ml-5 mt-3 justify-center gap-5">
                           
                    <select className="cinput w-[400px]" value={brandSearch} onChange={(e) => setBrandSearch(e.target.value)}>
                        <option value="" disabled selected hidden>Mark</option>
                            {brandData.map((data) => (
                                <option value={data.brand_name}>{data.brand_name}</option>
                            ))}
                    </select>
                   
                    <button onClick={searchSparepart} className="cbutton w-[400px]">
                        Search
                    </button>
                   

                </div>
               
           </div>

            <div >
                {sparepartItemsData.length === 0 ? <div className="flex justify-center mt-10"><img className="w-[400px]  object-contain" src="https://res.cloudinary.com/dbewtrc1q/image/upload/v1687779786/Bimer%20Extra%20Items/dprgbk8ejv3tw0pkocab.jpg" alt="no_item_found"/></div>  : <div className="flex flex-wrap justify-center mt-10 gap-8 w-full">
                    {sparepartItemsData.map((item) => (
                        <Link className="hover:text-secondary" to={`/bimerstore/sparepart/${item._id}`}>
                            <div className="flex flex-col  w-[275px] rounded-md">
                                <img className="w-[275px] h-[200px] " src={item.sparepart_images[0].url}  alt="VehicleImage"/>   
                                <h1 className="text-xl font-semibold text-center mt-3">{item.sparepart_type}</h1>
                            </div>
                        </Link>
                    ))}
                </div>}
            </div>

            <div className={sparepartItemsData.length === 0 ? " hidden" : "flex justify-center mt-10 sm:mt-5 "}>
                <Pagination page={page}/>
            </div>

        </div>
    )
}

export default SparepartStore;