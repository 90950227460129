import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reset } from '../../Redux/auth/authSlice'
import Sidebar from "../Components/SidebarComponents/sidebar";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import PropertiesDescription from "../Components/AdminProperiesPageComponents/PropertiesDescription/PropertiesDescription";
import AddRooms from "../Components/AdminProperiesPageComponents/AddRooms/AdddRooms";
import ScreenError from "./ScreenError";

const AdminPropertiesPage = () => {

    useEffect(() => {
        document.title = "Admin Properties Page Management - Bimer Business Group"
    })

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.auth)
    const [show,setShow] = useState(true)

    const ScreenSize = () => {
        if(window.innerWidth <= 1080){
            setShow(false)
        }
        else {
            setShow(true)
        }
    }

    useEffect(() => {
        ScreenSize()
        window.addEventListener('resize',() => {ScreenSize()})
    },[])

    useEffect(() => {
    
        if (!user) {
          navigate('/administrator')
        }
    
        return () => {
          dispatch(reset())
        }
      }, [user, navigate, dispatch])

      const { propertiesData, roomsData} = useSelector((state) => state.root)
    
    return(
        <div>
          {!show ? <ScreenError/> : <div>
          {propertiesData && roomsData && (
          <div className="flex">
            <Sidebar/>
            <div className="flex flex-col w-full px-20 py-10 ml-[260px]">
              <Tabs  defaultActiveKey="1">
                <TabPane tab='Properties Description' key="1">
                  <PropertiesDescription/>
                </TabPane>
                <TabPane tab='BedRooms' key="2">
                   <AddRooms/>
                </TabPane>
              </Tabs>
            </div>
          </div>
          )}
        </div>}
        </div>
    )
}

export default AdminPropertiesPage;