import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { URL } from "../../../../Url/Url";
import {
  ReloadData,
  hiddenloading,
  showloading,
} from "../../../../Redux/server/rootSlice";
import axios from "axios";
import { Modal, message } from "antd";
import { AiFillLike } from "react-icons/ai";
import { BsPersonFillAdd } from "react-icons/bs";
import PhoneInput from "react-phone-number-input";

const Room802 = () => {
  useEffect(() => {
    document.title = "Tenant Registration Page - Bimer Business Group";
  });

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFollowModal, setShowFollowModal] = useState(false);
  const [button,setButton] = useState(true)
  const [fullName, setFullName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phonenumber, setPhoneNumber] = useState(null);
  const [date, setDate] = useState(null);
  const [answer, setAnswer] = useState(null);
  const dispatch = useDispatch();

  const { room802Data } = useSelector((state) => state.root);

  const whatsappGroupLink = "https://chat.whatsapp.com/IUWK1tszPpSJf9bQsg31iY";

  const handleJoinGroup = () => {
    window.open(whatsappGroupLink, "Welcome to Bimer Apartment Complex");
  };

  const [minDate, setMinDate] = useState("");

  useEffect(() => {
    const currentDate = new Date();
    const futureDate = new Date(currentDate);
    futureDate.setDate(currentDate.getDate() + 7);

    const formattedFutureDate = formatDate(futureDate);

    setMinDate(formattedFutureDate);
  }, []);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleOnChange = (value) => {
    setPhoneNumber(value);
  };

  const submitForm = async (e) => {
    e.preventDefault();

    try {
      const roomNo = 802;

      if (
        fullName === null &&
        email === null &&
        phonenumber === null &&
        date === null &&
        answer === null
      ) {
        message.error("No input received. Please provide valid input.");
      } else if (
        fullName === null ||
        email === null ||
        phonenumber === null ||
        date === null ||
        answer === null
      ) {
        message.error("No input received. Please provide valid input.");
      } else {
        setButton(false)
        dispatch(showloading());
        const { data } = await axios.post(`${URL}/api/Bimer/room/r802`, {
          fullName,
          email,
          phonenumber,
          date,
          answer,
        });

        const { newData } = await axios.post(
          `${URL}/api/Bimer/room/r802/sendTenantInfo`,
          { fullName, email, phonenumber, date, answer, roomNo }
        );

        if (data.success === true) {
          setShowAddEditModal(false);
          setFullName("");
          setEmail("");
          setDate("");
          setPhoneNumber("");
          dispatch(ReloadData(true));
          dispatch(hiddenloading());
          setShowSuccessModal(true);
        }
      }
    } catch (err) {
      dispatch(hiddenloading());
      message.error(err.message);
    }
  };

  return (
    <div>
      {room802Data && (
        <div>
          <div className="flex sm:flex-col w-full">
            <div className="flex bg-primary h-screen sm:h-[400px] sm:mb-5 sm:w-full w-1/2">
              <img
                src="https://res.cloudinary.com/dbewtrc1q/image/upload/v1709886093/Bimer%20Motion%20Graphics/zbk9pxaqglgqtkyd70in.jpg"
                alt="Bimer Bulding"
              />
            </div>
            <div className="flex flex-col sm:w-full w-1/2 justify-center items-center gap-12">
              <div className="flex flex-col text-center">
                <h1 className="text-[50px] font-bold uppercase">WELCOME TO</h1>
                <h1 className="text-[22px] font-bold uppercase">
                  BIMER APARTMENT COMPLEX
                </h1>
                <h1 className="text-2xl font-bold uppercase mt-3">
                  Room - 802
                </h1>
              </div>
              <div className="flex gap-5">
                <button
                  className="cbutton m-5"
                  onClick={() => {
                    room802Data.length >= 1
                      ? setShowSuccessModal(true)
                      : setShowAddEditModal(true);
                  }}
                >
                  <div className="flex justify-center items-center gap-3">
                    <BsPersonFillAdd />
                    <h1 className="uppercase">REGISTER HERE</h1>
                  </div>
                </button>
                <button
                  className="fbutton m-5"
                  onClick={() => {
                    setShowFollowModal(true);
                  }}
                >
                  <div className="flex justify-center items-center gap-3">
                    <AiFillLike />
                    <h1 className="uppercase">Follow Us</h1>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <Modal
            visible={showAddEditModal}
            footer={null}
            onCancel={() => {
              setShowAddEditModal(false);
            }}
          >
            <div>
              <h1 className="text-center text-xl uppercase font-semibold mt-5 mb-5">
                Registration Form
              </h1>
              <hr />
              <form onSubmit={submitForm}>
                <div className="flex flex-col gap-2 mt-2">
                  <label className="font-semibold uppercase">Full Name:</label>
                  <input
                    className="cinput w-full"
                    placeholder="Please Enter Your Name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
                <div className="flex flex-col gap-2 mt-2">
                  <label className="font-semibold uppercase">Email:</label>
                  <input
                    className="cinput w-full"
                    type="email"
                    placeholder="Please Enter Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>
                </div>
                <div className="flex flex-col gap-2 mt-2">
                  <label className="font-semibold uppercase">
                    Phone Number:
                  </label>
                  <PhoneInput
                    className="cinput w-full"
                    international
                    defaultCountry="ET"
                    placeholder="Enter phone number"
                    onChange={handleOnChange}
                  />
                </div>
                <div className="flex flex-col gap-2 mt-2">
                  <label className="font-semibold uppercase">
                    May I ask how you heard about us?
                  </label>
                  <select
                    className="cSelect"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                  >
                    <option value="null" disabled selected>
                      Please select...
                    </option>
                    <option value={"Online search"}>Online Search</option>
                    <option value={"Instagram"}>Instagram</option>
                    <option value={"LinkedIn"}> LinkedIn</option>
                    <option value={"Word of Mouth"}>Word of Mouth</option>
                    <option value={"Referral"}>Referral</option>
                    <option value={"Other"}>Other</option>
                  </select>
                </div>
                <div className="flex flex-col gap-2 mt-2">
                  <label className="font-semibold uppercase">Tenure:</label>
                  <div className="flex gap-2 justify-center items-center">
                    <input
                      className="cinput w-1/2"
                      type="date"
                      min={minDate}
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                    <button
                      type="submit"
                      className="bg-primary text-white w-1/2 py-[10px] mt-2 rounded "
                      disabled = {button === false}
                    >
                      Register
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
          <Modal
            visible={showFollowModal}
            footer={null}
            onCancel={() => {
              setShowFollowModal(false);
            }}
          >
            <h1 className="text-center text-xl uppercase font-semibold mt-5 mb-5">
              Follow Us
            </h1>
            <hr />
            <div className="flex flex-col justify-center items-center gap-5 mt-10">
              <div className="flex flex-col gap-5">
                <a href="https://www.instagram.com/bimer.ethiopia/">
                  <div className="w-[390px] flex gap-5 h-[70px] bg-white shadow-lg border-2 border-[#f5f5f5] rounded-md">
                    <div>
                      <lottie-player
                        src="https://lottie.host/6f10a622-6f24-41f2-84e5-b63c2e7942ef/PMrGsfHo1v.json"
                        background="transparent"
                        speed="1"
                        loop
                        autoplay
                      ></lottie-player>
                    </div>
                    <div className="flex flex-col justify-center">
                      <h1 className="text-lg text-black">Instagram</h1>
                      <h1 className="text-sm text-gray-800">
                        instagram.com/bimer.ethiopia
                      </h1>
                    </div>
                  </div>
                </a>
                <a href="https://www.linkedin.com/company/bimer-ethiopia">
                  <div className="w-[390px] flex gap-5 h-[70px] bg-white shadow-lg border-2 border-[#f5f5f5] rounded-md">
                    <div>
                      <lottie-player
                        src="https://lottie.host/6bcbfd3f-63eb-4ff8-92b1-b99236cb7cc1/sL9Q8Xjm0F.json"
                        background="transparent"
                        speed="1"
                        loop
                        autoplay
                      ></lottie-player>
                    </div>
                    <div className="flex flex-col justify-center">
                      <h1 className="text-lg text-black">LinkedIn</h1>
                      <h1 className="text-sm text-gray-800">
                        linkedin.com/company/bimer-ethiopia
                      </h1>
                    </div>
                  </div>
                </a>
              </div>
              <div className="flex flex-col gap-5 mb-10">
                <a href="https://www.facebook.com/bimerethiopia">
                  <div className="w-[390px] flex gap-5 h-[70px] bg-white shadow-lg border-2 border-[#f5f5f5] rounded-md">
                    <div>
                      <lottie-player
                        className="h-[65px] w-[65px]"
                        src="https://lottie.host/5d639ddc-eda1-468e-9933-03324ef78cfb/T3XaMlEiCX.json"
                        background="transparent"
                        speed="1"
                        loop
                        autoplay
                      ></lottie-player>
                    </div>
                    <div className="flex flex-col justify-center">
                      <h1 className="text-lg text-black">Facebook</h1>
                      <h1 className="text-sm text-gray-800">
                        facebook.com/bimerethiopia
                      </h1>
                    </div>
                  </div>
                </a>
                <a href="https://www.twitter.com/BimerEthiopia">
                  <div className="w-[390px] flex gap-5 h-[70px] bg-white shadow-lg border-2 border-[#f5f5f5] rounded-md">
                    <div>
                      <lottie-player
                        className="h-[65px] w-[65px]"
                        src="https://lottie.host/585d2274-a802-4dfc-a4ee-b6f890d78558/tHod5jelIq.json"
                        background="transparent"
                        speed="1"
                        loop
                        autoplay
                      ></lottie-player>
                    </div>
                    <div className="flex flex-col justify-center">
                      <h1 className="text-lg text-black">Twitter</h1>
                      <h1 className="text-sm text-gray-800">
                        twitter.com/BimerEthiopia
                      </h1>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </Modal>
          <Modal
            visible={showSuccessModal}
            footer={null}
            onCancel={() => {
              setShowSuccessModal(false);
            }}
          >
            <div className="flex flex-col justify-center">
              <div className="h-[300px]">
                <lottie-player
                  src="https://lottie.host/3132c3a5-2932-4bd1-8dcd-8f3a442727ea/WwXKfMl8Re.json"
                  background="transparent"
                  speed="1"
                  loop
                  autoplay
                ></lottie-player>
              </div>
              <h1 className="text-xl text-center -mt-5 font-bold text-gray-800 uppercase">
                You've registered successfully
              </h1>
              <h1 className="text-center p-5">
                Please join our WhatsApp group for direct communication with the
                property manager.
              </h1>
              <button onClick={handleJoinGroup} className="wbutton">
                Join
              </button>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Room802;
