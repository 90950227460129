import { useSelector } from "react-redux";
import Slider from "react-slick"
import TCard from "./TCard"

const Testimonials = () => {
    const { testimonialData } = useSelector((state) => state.root)
    const settings = {
        dots: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 10000,
      };
    return(
        <div className=" testimonal w-[450px] savsm:w-[280px] mt-5">
            <Slider {...settings}>
                {testimonialData.map((data)=> (
                    <TCard img={data.client_image.url} description={data.client_desc} name={data.full_name} title={data.work_title}/>
                ))}
            </Slider>
        </div>
    )
}

export default Testimonials