import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reset } from '../../Redux/auth/authSlice'
import Sidebar from "../Components/SidebarComponents/sidebar";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import LogisticsIntro from "../Components/AdminLogisticsPageComponents/LogisticsIntro/LogisticsIntro";
import LogisticsContent from "../Components/AdminLogisticsPageComponents/LogisticsContent/LogisticsContent";
import SafetyContent from "../Components/AdminLogisticsPageComponents/LogisticsSafety/SafetyContent";
import ScreenError from "./ScreenError";

const AdminLogisticsPage = () => {

    useEffect(() => {
        document.title = "Admin Logistics Page Management - Bimer Business Group"
    })

    const {logisticsData} = useSelector((state) => state.root)
    const [show,setShow] = useState(true)

    const ScreenSize = () => {
        if(window.innerWidth <= 1080){
            setShow(false)
        }
        else {
            setShow(true)
        }
    }

    useEffect(() => {
        ScreenSize()
        window.addEventListener('resize',() => {ScreenSize()})
    },[])

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.auth)

    useEffect(() => {
    
        if (!user) {
          navigate('/administrator')
        }
    
        return () => {
          dispatch(reset())
        }
      }, [user, navigate, dispatch])

    return(
        <div>
          {!show ? <ScreenError/> : <div>
          {logisticsData && (
            <div className="flex">
              <Sidebar/>
                <div className="flex flex-col w-full px-20 py-10 ml-[260px]">
                  <Tabs  defaultActiveKey="1">
                    <TabPane tab='Logistics Intro' key="1">
                      <LogisticsIntro/>
                    </TabPane>
                    <TabPane tab='Logistics Content' key="2">
                      <LogisticsContent/>
                    </TabPane>
                    <TabPane tab='Safty and Security' key="3">
                      <SafetyContent/>
                    </TabPane>
                  </Tabs>
                </div>
            </div>
          )}
        </div>}
        </div>
    )
}

export default AdminLogisticsPage;