/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect,  useState} from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Dots from "./dots"

const Banner = () =>{

    const { bannerData } = useSelector((state) => state.root)

    const len = bannerData.length - 1

    const [activeIndex,SetActiveIndex] =  useState(0)
    useEffect(() => {
        const interval = setInterval(() => {
            SetActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
        },10000)
        return () => clearInterval(interval)
    },[activeIndex])    

    return(
        <div>
        {bannerData.map((data,index) => (
            <div key={index} className={`w-full h-screen llg:h-[90vh] llg:bg-contain sm2:h-[70vh] csm:h-[60vh] vs2:h-[50vh] vvsm:h-[40vh] relative ${index === activeIndex ? 'inline-block' : 'hidden'}`} style={{backgroundImage: `url(${data.banner_image.url})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                <div className="absolute z-0 gap-3 vvsm:gap-0 flex flex-col w-full h-screen llg:h-[90vh] llg:bg-contain sm2:h-[70vh] csm:h-[60vh] vs2:h-[50vh] vvsm:h-[40vh] vsmm:h-[40vh] bg-black bg-opacity-30 justify-center inset-0">
                    <h1 className=" text-6xl llg:text-4xl bvsm:text-2xl vvsm:text-xl text-white px-20 vvsm:p-3 vs2:mt-16 vvsm:mt-16 vvsm:-mb-5  vsmm:-mb-5 uppercase font-semibold  ">{data.banner_title}</h1>
                    <div  className="text-xl llg:text-xl csm:text-base vvsm:text-sm text-white px-20 vvsm:p-3 w-[600px] vvsm:w-[350px] vvsm:-mb-5 csm:bannertext  z-0" dangerouslySetInnerHTML={{__html: data.banner_desc}}/>
                    <Link to="/about">
                    <div className="flex gap-2 px-20 vvsm:p-3 z-0 items-center">
                        <h1 className="text-xl llg:text-xl csm:text-base vvsm:text-sm text-white ">Learn More About Us</h1>
                        <i className="ri-arrow-right-s-line text-xl llg:text-xl vvsm:text-sm text-white "></i>
                    </div>
                </Link>
                </div>
                <Dots activeIndex={activeIndex} carouselData={bannerData} onClick={(activeIndex) => SetActiveIndex(activeIndex)}/>
            </div>
        ))}
    </div>
    )
}

export default Banner;