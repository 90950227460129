import { useEffect,  useState } from "react";
import Stepper from "../../../Components/Stepper Components/Stepper";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import AccessDenied from "../../../Components/Stepper Components/AccessDenied";
import { useParams } from "react-router-dom";

const Q601Room = () => {

    useEffect(() => {
      document.title = "Tenant Satisfaction Form - Bimer Business Group"
    })

    const [selectedValueQ1, setSelectedValueQ1] = useState("");
    const [selectedValueQ2, setSelectedValueQ2] = useState("")
    const [selectedValueQ3, setSelectedValueQ3] = useState("")
    const [selectedValueQ4, setSelectedValueQ4] = useState("")
    const [selectedValueQ5, setSelectedValueQ5] = useState("")
    const [permission,setPermission] = useState(false)
    const [showAddEditModal,setShowAddEditModal] = useState(false)
    const [selectedItemforEdit,setSelectedItemforEdit] = useState(null)

    

    const RoomNo = 601

    const {id} = useParams()
    const {room601Data} = useSelector((state) => state.root)

    useEffect(() => {
      if(room601Data){
        const roomPermission = room601Data.filter((data) => data._id === id).map((data) => data.submit)
        setPermission(roomPermission[0])
      }
    },[room601Data])

  const text = "We appreciate your decision to stay with BIMER. Your opinion matters greatly to us. Kindly spare a moment to complete this survey with utmost accuracy. Your feedback enable  us to cater to your needs even more effectively in the future."
  
  const handleRadioChangeQ1 = (value) => {
    setSelectedValueQ1(value);
  };
  const handleRadioChangeQ2 = (value) => {
    setSelectedValueQ2(value); 
  };
  const handleRadioChangeQ3 = (value) => {
    setSelectedValueQ3(value);
  };
  const handleRadioChangeQ4 = (value) => {
    setSelectedValueQ4(value);
  };
  const handleRadioChangeQ5 = (value) => {
    setSelectedValueQ5(value);
  };
  // const handleInputChangeQ6 = (value) => {
  //   setSelectedValueQ6(value)
  // }


    const Questions = [
    
        {
          Component: () => <div>
            <h1 className="text-xl text-center">How satisfied are you with your tenancy?</h1>
            <div className="flex justify-center text-2xl items-center mt-10 gap-10">
            {[1, 2, 3, 4, 5].map(value => (
                <div key={value}>
                    <input
                        className="radio-button"
                        type="radio"
                        id={`radio-${value}`}
                        name="selectValue"
                        value={value}
                        checked={selectedValueQ1 === value}
                        onChange={() => handleRadioChangeQ1(value)}
                    />
                    <label htmlFor={`radio-${value}`}>{value}</label>
                </div>
            ))}
            </div>
          </div>,
        },
        {
          Component: () => <div>
            <h1 className="text-xl text-center">How would you rate the responsiveness of our team to your inquiries or concerns?</h1>
            <div className="flex justify-center text-2xl items-center mt-10 gap-10">
            {[1, 2, 3, 4, 5].map(value => (
                <div key={value}>
                    <input
                        className="radio-button"
                        type="radio"
                        id={`radio-${value}`}
                        name="selectValue"
                        value={value}
                        checked={selectedValueQ2 === value}
                        onChange={() => handleRadioChangeQ2(value)}
                    />
                    <label htmlFor={`radio-${value}`}>{value}</label>
                </div>
            ))}
            </div>
            
          </div>,
        },
        {
          Component: () => <div>
            <h1 className="text-xl text-center">How would you rate the overall condition of the property?</h1>
            <div className="flex justify-center text-2xl items-center mt-10 gap-10">
            {[1, 2, 3, 4, 5].map(value => (
                <div key={value}>
                    <input
                        className="radio-button"
                        type="radio"
                        id={`radio-${value}`}
                        name="selectValue"
                        value={value}
                        checked={selectedValueQ3 === value}
                        onChange={() => handleRadioChangeQ3(value)}
                    />
                    <label htmlFor={`radio-${value}`}>{value}</label>
                </div>
            ))}
            </div>
          </div>,
        },
        {
          Component: () => <div> 
            <h1 className="text-xl text-center">How satisfied are you with the maintenance and repair services provided during your tenancy?</h1>
            <div className="flex justify-center text-2xl items-center mt-10 gap-10">
            {[1, 2, 3, 4, 5].map(value => (
                <div key={value}>
                    <input
                        className="radio-button"
                        type="radio"
                        id={`radio-${value}`}
                        name="selectValue"
                        value={value}
                        checked={selectedValueQ4 === value}
                        onChange={() => handleRadioChangeQ4(value)}
                    />
                    <label htmlFor={`radio-${value}`}>{value}</label>
                </div>
            ))}
            </div>
          </div>,
        },
        {
          Component: () => <div> 
            <h1 className="text-xl text-center">Were the lease terms and policies clear and easy to understand?</h1>
            <div className="flex justify-center text-2xl items-center mt-10 gap-10">
            {[1, 2, 3, 4, 5].map(value => (
                <div key={value}>
                    <input
                        className="radio-button"
                        type="radio"
                        id={`radio-${value}`}
                        name="selectValue"
                        value={value}
                        checked={selectedValueQ5 === value}
                        onChange={() => handleRadioChangeQ5(value)}
                    />
                    <label htmlFor={`radio-${value}`}>{value}</label>
                </div>
            ))}
            </div>
          </div>,
        },
        {
          Component: () => <div> 
           
          </div>
        }
      ];
    

    return(
      <div>
        {room601Data && (
          permission ? <div className="flex flex-col w-full p-16 avsm:p-5 ">
              <div className="flex self-center w-[180px] sm:w-[20vh] mb-8">
                <img src="https://res.cloudinary.com/dbewtrc1q/image/upload/v1710337229/Bimer%20Motion%20Graphics/zdyzqodct8wiknjejdip.png" alt="Logo" />
              </div>
              <h1 className="text-center text-2xl uppercase font-bold mb-14 avsm:mb-10">Tenant Satisfaction Form</h1>
              <div className="w-[800px] md:w-[630px] bvsm:w-[450px] vsmm:w-[300px] flex flex-col self-center">
                <h1 className="text-center text-xl md:text-[17px] bvsm:text-[15px]">{text}</h1>
                <h1 className="text-center text-xl md:text-[17px] bvsm:text-[15px] mt-5"><strong>Thank You!</strong></h1>
                <div className="flex justify-center mt-5">
                  <button className="cbutton w-[200px]" onClick={() => {
                        setSelectedItemforEdit(null)
                        setShowAddEditModal(true)
                    }}>Start</button>
                </div>
                
              </div>
          </div> : <AccessDenied/>
          
        )}
        <Modal visible={showAddEditModal} footer={null} onCancel={() => {setShowAddEditModal(false); setSelectedItemforEdit(null)}}>
            <div>
              {room601Data && (
                <div>
                  <h1 className="text-center text-xl uppercase font-semibold mt-5 mb-5">TENANT SATISFACTION FORM</h1>
                  <Stepper stepsConfig={Questions} selectedValueQ1={selectedValueQ1} selectedValueQ2={selectedValueQ2} selectedValueQ3={selectedValueQ3} selectedValueQ4={selectedValueQ4} selectedValueQ5={selectedValueQ5} userData={room601Data.filter((data) => data._id === id).map((new_data) => new_data)} RoomNo={RoomNo} id={id}/>
                </div>
              )}
            </div>
        </Modal>
      </div>
    )
}

export default Q601Room