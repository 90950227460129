/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Slider from "react-slick";
import Card from "./Card";
import { Modal } from "antd";

const VehiclesShowroom= ({showroomDatas}) => {
    const [wide,setwide] = useState(true)
    const [selectedImg,setSelectedImg] = useState(showroomDatas[0])
    const [showAddEditModal,setShowAddEditModal] = useState(false)
    const [progress,setProgress] = useState(0)
    const [slidetoshow,setslidetoshow] = useState(4)

    const setslide = () => {
        if(window.innerWidth <= 1280 && window.innerWidth > 1000){
            setslidetoshow(3)
        }
        else if(window.innerWidth <=1000 && window.innerWidth > 780){
            setslidetoshow(2)
        }
        else if(window.innerWidth <= 650){
            setslidetoshow(1)
        }
    }   
    useEffect(() => {
        setslide()
        setProgress(100/(showroomDatas.length - slidetoshow + 1))
        window.addEventListener('resize',() => {setslide()})
        console.log(showroomDatas)
    },[])

    const settings = {
        dots: true,
        nextArrow: false,
        prevArrow: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return(
        <div className="gallery relative">
            <Slider {...settings}>
                {showroomDatas.map((data,index) => (
                    <div onClick={() => {
                        setShowAddEditModal(true)
                        setSelectedImg(data)
                    }} key={index}>
                        <Card pic={data.showroom_image.url}/>
                    </div>
                ))}
            </Slider>
            <Modal  visible={showAddEditModal} width={`${wide === true ? "60%" : "90%"}`}  footer={null} onCancel={() => {setShowAddEditModal(false)}}>
                <div className="p-10 vsmm: vsmm:p-0 vsmm:mt-7 flex flex-col w-full justify-center items-center mb-5">
                    <img className=" w-[full] border-2 rounded object-contain" src={selectedImg.showroom_image.url} alt="img"/>
                </div>
            </Modal>
        </div>
    )
}

export default VehiclesShowroom;