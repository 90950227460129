import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reset } from '../../Redux/auth/authSlice'
import Sidebar from "../Components/SidebarComponents/sidebar";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import LiftingIntro from "../Components/AdminLiftingPageComponents/LiftingIntro/LiftingIntro";
import LiftingTopDesc from "../Components/AdminLiftingPageComponents/LiftingContent/LiftingTopDesc";
import LiftingBottomDesc from "../Components/AdminLiftingPageComponents/LiftingContent/LiiftingBottomDesc";
import OperationalRental from "../Components/AdminLiftingPageComponents/Operational&Rental/Operational&Rental";
import ScreenError from "./ScreenError";

const AdminLiftingPage = () => {

    useEffect(() => {
        document.title = "Admin Lifting Page Management - Bimer Business Group"
    })

    const { liftingData } = useSelector((state) => state.root)
    const [show,setShow] = useState(true)

    const ScreenSize = () => {
        if(window.innerWidth <= 1080){
            setShow(false)
        }
        else {
            setShow(true)
        }
    }

    useEffect(() => {
        ScreenSize()
        window.addEventListener('resize',() => {ScreenSize()})
    },[])


    

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.auth)

    

    useEffect(() => {
    
        if (!user) {
          navigate('/administrator')
        }
    
        return () => {
          dispatch(reset())
        }
      }, [user, navigate, dispatch])

    return(
      <div>
        {!show ? <ScreenError/> : <div>
        { liftingData && (
        <div className="flex">
          <Sidebar/>
          <div className="flex flex-col w-full px-20 py-10 ml-[260px]">
            <Tabs  defaultActiveKey="1">
              <TabPane tab='Lifting Intro' key="1">
                <LiftingIntro/>    
              </TabPane>
              <TabPane tab='Lifting Top Description' key="2">
                <LiftingTopDesc/>
              </TabPane>
              <TabPane tab='Lifting Bottom Description' key="3">
                <LiftingBottomDesc/>    
              </TabPane>
              <TabPane tab='Operational & Rental' key="4">
                <OperationalRental/>   
              </TabPane>
            </Tabs>
          </div>
        </div>
        )}
      </div>}
      </div>
    )
}

export default AdminLiftingPage;