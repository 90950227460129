const Card = ({img}) => {
    return(
        <div className="w-[150px]  hover:shadow-xl h-[117px] avsm:w-[155px] avsm:h-[90px]  rounded-md mb-2">
            <div>
                <img className="w-[150px] object-contain rounded-md mb-2 " src={img} alt="team"/>
            </div>
        </div>
    )
}

export default Card;