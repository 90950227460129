/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Footer from "../Components/FooterPageComponents/Footer";
import ReactWhatsapp from 'react-whatsapp';
import { FaEnvelope, FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ApartmentGallery from "../Components/BimerApartmentComplexComponents/ApartmentGallery/ApartmentGallery";
import BNavbar from "../Components/NavbarComponents/Apartment-Navbar/BNavbar";
import { Modal } from "antd";
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import Swal from 'sweetalert2'

const BimerApartmentComplexPage = () => {

    useEffect(() => {
        document.title = "Bimer Apartment Complex - Bimer Business Group"
        window.scrollTo(0, 0);
    })

    const [showAddEditModal,setShowAddEditModal] = useState(false)
    const [selectedItemforEdit,setSelectedItemforEdit] = useState(null)
    const [bedroom_name,setBedroomName] = useState('')
    const [bedroom_type,setBedroomType] = useState('')

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_9b23qjd', 'template_1yxetua', form.current, 'UO4vrB_1Rp87ClBvN')
        .then((result) => {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Your Message Submit Successfully.',
                showConfirmButton: false,
                timer: 2000
              })
            form.current.reset()
            setShowAddEditModal(false)
        }, (error) => {
            console.log(error.text);
        });
  };

    const {id} = useParams()
    const { roomsData, onebedroomAData, onebedroomBData,onebedroomCData,twobedroomAData,twobedroomBData,threebedroomData} = useSelector((state) => state.root)
    
    useEffect(() => {
        if(selectedItemforEdit){
            setBedroomName(selectedItemforEdit.bedroom_name)
            setBedroomType(selectedItemforEdit.bedroom_type)
        }
        else{
           setBedroomName('')
           setBedroomType('')
        }
    },[selectedItemforEdit])

    return(
        <div className="w-full">
            {roomsData && onebedroomAData && onebedroomBData && onebedroomCData && twobedroomAData && twobedroomBData && threebedroomData && (
                <div>
                <BNavbar/>
                <div >
                    {id === '645ce4bbb41f1767ce28b8fb' ? (
                        <div >
                            {roomsData.filter((room) => room._id === id).map((data) => (
                            <div className="flex flex-col justify-center items-center py-24">
                                <h1 className="text-primary text-2xl font-bold uppercase mt-5 mb-10">{`${data.bedroom_name} (${data.bedroom_type})`}</h1>
                            <div className="flex justify-center lgg:flex-col lgg:items-center w-full  ">
                                
                                <div className="w-[700px] vsm:w-full px-10 vsmm:px-5">
                                    
                                        <ApartmentGallery GalleryData={onebedroomAData}/>
                                       
                                </div>
                                <div className="flex flex-col gap-3 lg:px-0 vsmm:px-8 lgg:mt-8 ">
                                    <div className="w-[300px] flex flex-col border-2 rounded">
                                        <h1 className="text-xl px-5 uppercase font-semibold text-primary text-center">{data.bedroom_name} ({data.bedroom_type})</h1>
                                        <hr/>
                                        <div className="text-xl px-5" dangerouslySetInnerHTML={{__html: data.bedroom_desc}}/>
                                        <hr/>
                                        <h1  className="text-xl px-5"><span className=" font-bold">Area:-</span> {data.bedroom_area}msq</h1>
                                        <hr/>
                                        <h1  className="text-xl px-5"><span className=" font-bold">Floor:-</span> {data.bedroom_floor}</h1>
                                    </div>
                                    <a href="tel:+251907687719">
                                        <div className="bg-secondary w-[300px] p-2 rounded flex gap-5 items-center justify-center text-white">
                                            <FaPhoneAlt />
                                            <h1>Call Us</h1>
                                        </div>
                                    </a>
                                    <ReactWhatsapp className="bg-green-500 text-white w-[300px] p-2 rounded flex gap-5 justify-center items-center" number="+251907687719" message="Welcome to Bimer!!!">
                                        <FaWhatsapp size="20"/>
                                        Whatsapp
                                    </ReactWhatsapp>
                                    <button className="bg-blue-500 text-white w-[300px] p-2 rounded flex gap-5 justify-center items-center"  onClick={() => {
                                        setSelectedItemforEdit(data);
                                        setShowAddEditModal(true)
                                    }}>
                                            <FaEnvelope size="20"/>
                                            Email                                       
                                    </button>
                                </div>
                            </div>
                            </div>
                            ))}
                        </div>
                    ): <div className=" hidden"></div>}
                    {id === '645ce52ab41f1767ce28b90a' ? (
                        <div >
                            {roomsData.filter((room) => room._id === id).map((data) => (
                            <div className="flex flex-col justify-center items-center py-24">
                                <h1 className="text-primary text-2xl font-bold uppercase mt-5 mb-10">{`${data.bedroom_name} (${data.bedroom_type})`}</h1>
                            <div className="flex justify-center lgg:flex-col lgg:items-center w-full">
                                
                                <div className="w-[700px] vsm:w-full px-10 vsmm:px-5">
                                    
                                        <ApartmentGallery GalleryData={onebedroomBData}/>
                                       
                                </div>
                                <div className="flex flex-col gap-3 lg:px-0 vsmm:px-8 lgg:mt-8">
                                    <div className="w-[300px] flex flex-col border-2 rounded">
                                        <h1 className="text-xl px-5 uppercase font-semibold text-primary text-center">{data.bedroom_name} ({data.bedroom_type})</h1>
                                        <hr/>
                                        <div className="text-xl px-5" dangerouslySetInnerHTML={{__html: data.bedroom_desc}}/>
                                        <hr/>
                                        <h1  className="text-xl px-5"><span className=" font-bold">Area:-</span> {data.bedroom_area}msq</h1>
                                        <hr/>
                                        <h1  className="text-xl px-5"><span className=" font-bold">Floor:-</span> {data.bedroom_floor}</h1>
                                    </div>
                                    <a href="tel:+251907687719">
                                        <div className="bg-secondary w-[300px] p-2 rounded flex gap-5 items-center justify-center text-white">
                                            <FaPhoneAlt />
                                            <h1>Call Us</h1>
                                        </div>
                                    </a>
                                    <ReactWhatsapp className="bg-green-500 text-white w-[300px] p-2 rounded flex gap-5 justify-center items-center" number="+251907687719" message="Welcome to Bimer!!!">
                                        <FaWhatsapp size="20"/>
                                        Whatsapp
                                    </ReactWhatsapp>
                                    <button className="bg-blue-500 text-white w-[300px] p-2 rounded flex gap-5 justify-center items-center"  onClick={() => {
                                        setSelectedItemforEdit(data);
                                        setShowAddEditModal(true)
                                    }}>
                                            <FaEnvelope size="20"/>
                                            Email                                       
                                    </button>
                                </div>
                            </div>
                            </div>
                            ))}
                        </div>
                    ): <div className=" hidden"></div>}
                    {id === '645ce588b41f1767ce28b919' ? (
                        <div >
                            {roomsData.filter((room) => room._id === id).map((data) => (
                            <div className="flex flex-col justify-center items-center py-24">
                                <h1 className="text-primary text-2xl font-bold uppercase mt-5 mb-10">{`${data.bedroom_name} (${data.bedroom_type})`}</h1>
                            <div className="flex justify-center lgg:flex-col lgg:items-center w-full">
                                
                                <div className="w-[700px] vsm:w-full px-10 vsmm:px-5">
                                    
                                        <ApartmentGallery GalleryData={onebedroomCData}/>
                                       
                                </div>
                                <div className="flex flex-col gap-3 lg:px-0 vsmm:px-8 lgg:mt-8">
                                    <div className="w-[300px] flex flex-col border-2 rounded">
                                        <h1 className="text-xl px-5 uppercase font-semibold text-primary text-center">{data.bedroom_name} ({data.bedroom_type})</h1>
                                        <hr/>
                                        <div className="text-xl px-5" dangerouslySetInnerHTML={{__html: data.bedroom_desc}}/>
                                        <hr/>
                                        <h1  className="text-xl px-5"><span className=" font-bold">Area:-</span> {data.bedroom_area}msq</h1>
                                        <hr/>
                                        <h1  className="text-xl px-5"><span className=" font-bold">Floor:-</span> {data.bedroom_floor}</h1>
                                    </div>
                                    <a href="tel:+251907687719">
                                        <div className="bg-secondary w-[300px] p-2 rounded flex gap-5 items-center justify-center text-white">
                                            <FaPhoneAlt />
                                            <h1>Call Us</h1>
                                        </div>
                                    </a>
                                    <ReactWhatsapp className="bg-green-500 text-white w-[300px] p-2 rounded flex gap-5 justify-center items-center" number="+251907687719" message="Welcome to Bimer!!!">
                                        <FaWhatsapp size="20"/>
                                        Whatsapp
                                    </ReactWhatsapp>
                                    <button className="bg-blue-500 text-white w-[300px] p-2 rounded flex gap-5 justify-center items-center"  onClick={() => {
                                        setSelectedItemforEdit(data);
                                        setShowAddEditModal(true)
                                    }}>
                                            <FaEnvelope size="20"/>
                                            Email                                       
                                    </button>
                                </div>
                            </div>
                            </div>
                            ))}
                        </div>
                    ): <div className=" hidden"></div>}
                    {id === '645ce5eab41f1767ce28b928' ? (
                        <div >
                            {roomsData.filter((room) => room._id === id).map((data) => (
                            <div className="flex flex-col justify-center items-center py-24">
                                <h1 className="text-primary text-2xl font-bold uppercase mt-5 mb-10">{`${data.bedroom_name} (${data.bedroom_type})`}</h1>
                            <div className="flex justify-center lgg:flex-col lgg:items-center w-full">
                                
                                <div className="w-[700px] vsm:w-full px-10 vsmm:px-5">
                                    
                                        <ApartmentGallery GalleryData={twobedroomAData}/>
                                       
                                </div>
                                <div className="flex flex-col gap-3 lg:px-0 vsmm:px-8 lgg:mt-8">
                                    <div className="w-[300px] flex flex-col border-2 rounded">
                                        <h1 className="text-xl px-5 uppercase font-semibold text-primary text-center">{data.bedroom_name} ({data.bedroom_type})</h1>
                                        <hr/>
                                        <div className="text-xl px-5" dangerouslySetInnerHTML={{__html: data.bedroom_desc}}/>
                                        <hr/>
                                        <h1  className="text-xl px-5"><span className=" font-bold">Area:-</span> {data.bedroom_area}msq</h1>
                                        <hr/>
                                        <h1  className="text-xl px-5"><span className=" font-bold">Floor:-</span> {data.bedroom_floor}</h1>
                                    </div>
                                    <a href="tel:+251907687719">
                                        <div className="bg-secondary w-[300px] p-2 rounded flex gap-5 items-center justify-center text-white">
                                            <FaPhoneAlt />
                                            <h1>Call Us</h1>
                                        </div>
                                    </a>
                                    <ReactWhatsapp className="bg-green-500 text-white w-[300px] p-2 rounded flex gap-5 justify-center items-center" number="+251907687719" message="Welcome to Bimer!!!">
                                        <FaWhatsapp size="20"/>
                                        Whatsapp
                                    </ReactWhatsapp>
                                    <button className="bg-blue-500 text-white w-[300px] p-2 rounded flex gap-5 justify-center items-center"  onClick={() => {
                                        setSelectedItemforEdit(data);
                                        setShowAddEditModal(true)
                                    }}>
                                            <FaEnvelope size="20"/>
                                            Email                                       
                                    </button>
                                </div>
                            </div>
                            </div>
                            ))}
                        </div>
                    ): <div className=" hidden"></div>}
                    {id === '645ce63bb41f1767ce28b937' ? (
                        <div >
                            {roomsData.filter((room) => room._id === id).map((data) => (
                            <div className="flex flex-col justify-center items-center py-24">
                                <h1 className="text-primary text-2xl font-bold uppercase mt-5 mb-10">{`${data.bedroom_name} (${data.bedroom_type})`}</h1>
                            <div className="flex justify-center lgg:flex-col lgg:items-center w-full">
                                
                                <div className="w-[700px] vsm:w-full px-10 vsmm:px-5">
                                    
                                        <ApartmentGallery GalleryData={twobedroomBData}/>
                                       
                                </div>
                                <div className="flex flex-col gap-3 lg:px-0 vsmm:px-8 lgg:mt-8">
                                    <div className="w-[300px] flex flex-col border-2 rounded">
                                        <h1 className="text-xl px-5 uppercase font-semibold text-primary text-center">{data.bedroom_name} ({data.bedroom_type})</h1>
                                        <hr/>
                                        <div className="text-xl px-5" dangerouslySetInnerHTML={{__html: data.bedroom_desc}}/>
                                        <hr/>
                                        <h1  className="text-xl px-5"><span className=" font-bold">Area:-</span> {data.bedroom_area}msq</h1>
                                        <hr/>
                                        <h1  className="text-xl px-5"><span className=" font-bold">Floor:-</span> {data.bedroom_floor}</h1>
                                    </div>
                                    <a href="tel:+251907687719">
                                        <div className="bg-secondary w-[300px] p-2 rounded flex gap-5 items-center justify-center text-white">
                                            <FaPhoneAlt />
                                            <h1>Call Us</h1>
                                        </div>
                                    </a>
                                    <ReactWhatsapp className="bg-green-500 text-white w-[300px] p-2 rounded flex gap-5 justify-center items-center" number="+251907687719" message="Welcome to Bimer!!!">
                                        <FaWhatsapp size="20"/>
                                        Whatsapp
                                    </ReactWhatsapp>
                                    <button className="bg-blue-500 text-white w-[300px] p-2 rounded flex gap-5 justify-center items-center"  onClick={() => {
                                        setSelectedItemforEdit(data);
                                        setShowAddEditModal(true)
                                    }}>
                                            <FaEnvelope size="20"/>
                                            Email                                       
                                    </button>
                                </div>
                            </div>
                            </div>
                            ))}
                        </div>
                    ): <div className=" hidden"></div>}
                    {id === '645ce692b41f1767ce28b946' ? (
                        <div >
                            {roomsData.filter((room) => room._id === id).map((data) => (
                            <div className="flex flex-col justify-center items-center py-24">
                                <h1 className="text-primary text-2xl font-bold uppercase mt-5 mb-10">{`${data.bedroom_name} (${data.bedroom_type})`}</h1>
                            <div className="flex justify-center lgg:flex-col lgg:items-center w-full">
                                
                                <div className="w-[700px] vsm:w-full px-10 vsmm:px-5">
                                    
                                        <ApartmentGallery GalleryData={threebedroomData}/>
                                       
                                </div>
                                <div className="flex flex-col gap-3 lg:px-0 vsmm:px-8 lgg:mt-8">
                                    <div className="w-[300px] flex flex-col border-2 rounded">
                                        <h1 className="text-xl px-5 uppercase font-semibold text-primary text-center">{data.bedroom_name} ({data.bedroom_type})</h1>
                                        <hr/>
                                        <div className="text-xl px-5" dangerouslySetInnerHTML={{__html: data.bedroom_desc}}/>
                                        <hr/>
                                        <h1  className="text-xl px-5"><span className=" font-bold">Area:-</span> {data.bedroom_area}msq</h1>
                                        <hr/>
                                        <h1  className="text-xl px-5"><span className=" font-bold">Floor:-</span> {data.bedroom_floor}</h1>
                                    </div>
                                    <a href="tel:+251907687719">
                                        <div className="bg-secondary w-[300px] p-2 rounded flex gap-5 items-center justify-center text-white">
                                            <FaPhoneAlt/>
                                            <h1>Call Us</h1>
                                        </div>
                                    </a>
                                    <ReactWhatsapp className="bg-green-500 text-white w-[300px] p-2 rounded flex gap-5 justify-center items-center" number="+251907687719" message="Welcome to Bimer!!!">
                                        <FaWhatsapp size="20"/>
                                        Whatsapp
                                    </ReactWhatsapp>
                                    <button className="bg-blue-500 text-white w-[300px] p-2 rounded flex gap-5 justify-center items-center"  onClick={() => {
                                        setSelectedItemforEdit(data);
                                        setShowAddEditModal(true)
                                    }}>
                                            <FaEnvelope size="20"/>
                                            Email                                       
                                    </button>
                                </div>
                            </div>
                            </div>
                            ))}
                        </div>
                    ): <div className=" hidden"></div>}
                </div>
               <Footer/>
            </div>
            )}
            <Modal visible={showAddEditModal}  footer={null} onCancel={() => {setShowAddEditModal(false); setSelectedItemforEdit(null)}}>
                <div>
                    <div className="flex items-center justify-center mt-8 mb-3">
                        <h1 className="text-xl vsmm:text-sm font-semibold text-primary uppercase">Request For {bedroom_name} ({bedroom_type})</h1>
                    </div>
                    <form ref={form} onSubmit={sendEmail}>
                        <div className='flex flex-col gap-5'>
                            <input className="cinput w-full hidden" name="bedroom_name" value={bedroom_name} type="text"/>
                            <input className="cinput w-full hidden" name="bedroom_type" value={bedroom_type} type="text"/>
                            <input className='cinput w-full' name="user_name" placeholder='Full Name' type="text"/>
                            <input className='cinput w-full' name="user_email" type="email" placeholder='Email Address'/>
                            <textarea className='ctextarea w-full' name="message"  placeholder='Message' type="text"/>
                            <button className='cbutton w-[120px]'>Submit</button>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    )
}

export default BimerApartmentComplexPage;