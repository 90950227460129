import {Pagination, PaginationItem} from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import { ReloadData, hiddenloading, setVehicleProductsData, showloading } from '../../../../Redux/server/rootSlice';
import { useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { URL } from '../../../../Url/Url';

const Paginate = ({page}) => {
    const {vehicleProductsData,vehicleProductslengthData} = useSelector((state) => state.root)
    const classes = useStyles();
    const dispatch = useDispatch()

    const getVehiclesProduct = async (page) => {
        try{
          dispatch(showloading())
          const responce = await axios.get(`${URL}/api/Bimer/vehicleProducts?page=${page}`)
          dispatch(setVehicleProductsData(responce.data.vehicleproducts))
          dispatch(ReloadData(false))
          dispatch(hiddenloading())
        }catch(err){
          dispatch(hiddenloading())
        }
    }
    const total = vehicleProductslengthData.length
    const limit = 8

    const numberOfPage = Math.ceil(total/limit)

    useEffect(() => {
        if(page || !vehicleProductsData){
            getVehiclesProduct(page)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page])

    return(
        <Pagination 
            classes={{ul:classes.ul}}
            count={numberOfPage}
            page={Number(page) || 1}
            variant='outlined'
            color='primary'
            renderItem={ (item) => (
                <PaginationItem {...item} component={Link} to={`/admindashboard/productpage?page=${item.page}`}/>
            )}
        />
    )
}

export default Paginate;