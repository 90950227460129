import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ReloadData, hiddenloading, showloading } from "../../../Redux/server/rootSlice";
import Swal from 'sweetalert2'
import { URL } from "../../../Url/Url";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import JoditEditor from 'jodit-react';

const Stepper = ({stepsConfig = [], 
    selectedValueQ1,
    selectedValueQ2,
    selectedValueQ3,
    selectedValueQ4,
    selectedValueQ5,
    id,
    userData,
    RoomNo
}) => {

    let name 
    let new_email
    let new_phone
     
    const [selectedValueQ6, setSelectedValueQ6] = useState('')
    const editor = useRef(null);

    userData.map(data => {
       name =  data.fullName;
       new_email = data.email;
       new_phone = data.phonenumber
       

    })

    const navigate = useNavigate()
    const [currentStep, setCurrentStep] = useState(1);
    const [isComplete, setIsComplete] = useState(false);
    const [button,setButton] = useState(true)
    const [margins, setMargins] = useState({
        marginLeft: 0,
        marginRight: 0,
    });
    const dispatch = useDispatch()
    
    const stepRef = useRef([]);

    useEffect(() => {
        setMargins({
            marginLeft: stepRef.current[0].offsetWidth / 2,
            marginRight: stepRef.current[stepsConfig.length - 1].offsetWidth / 2,
        });
    }, [stepRef, stepsConfig.length]);

    if (!stepsConfig.length) {
        return <></>;
    }

    const handleNext = () => {
        setCurrentStep((prevStep) => {
            if (prevStep === stepsConfig.length) {
                setIsComplete(true);
                return prevStep;
            } else {
                return prevStep + 1;
            }
        });
    };

    const handlePrevious = () => {
        setCurrentStep((prevStep) => {
            if (prevStep === 1) {
                // At the first step, cannot go previous
                return prevStep;
            } else {
                // Decrease the current step by 1
                setIsComplete(false); // Assuming going back would make the form incomplete
                return prevStep - 1;
            }
        });
    };

    const handlesubmit = async (e) => {
        e.preventDefault();
    
        dispatch(showloading())

        const fullName = name
        const email = new_email
        const phonenumber = new_phone
        
            
        if(selectedValueQ1 === "" && selectedValueQ2 === "" && selectedValueQ3 === "" && selectedValueQ4 === "" && selectedValueQ5 === "" && selectedValueQ6 === ""){
            message.error("Please Enter All The Data Properly")
            dispatch(showloading())
            dispatch(ReloadData(true))
            dispatch(hiddenloading())
        }
        else if (selectedValueQ1 === "" || selectedValueQ2 === "" || selectedValueQ3 === "" || selectedValueQ4 === "" || selectedValueQ5 === "" || selectedValueQ6 === ""){
            message.error("Please Enter All The Data Properly")
            dispatch(showloading())
            dispatch(ReloadData(true))
            dispatch(hiddenloading())
        }
        else{
            if(RoomNo === 201){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/201`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 202){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/202`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 203){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/203`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 301){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/301`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 302){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/302`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 303){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/303`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 401){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/401`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 402){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/402`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 403){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/403`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 501){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/501`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 502){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/502`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 503){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/503`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 601){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/601`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 602){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/602`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 603){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/603`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 701){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/701`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 702){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/702`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 703){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/703`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 801){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/801`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 802){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/802`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 803){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/803`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 901){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/901`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 902){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/902`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
            else if (RoomNo === 903){
                setButton(false)
                const {data} = await axios.post(`${URL}/api/Bimer/tenantform/send-mail/903`, {
                    fullName,email,phonenumber,selectedValueQ1,selectedValueQ2,selectedValueQ3,selectedValueQ4,selectedValueQ5,selectedValueQ6,RoomNo,id
                });
    
                if(data.success === true){
                    dispatch(showloading())
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Tenant Satisfaction Form Submit Successfully.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                        dispatch(ReloadData(true))
                        dispatch(hiddenloading())
                        navigate('/')
                }
            }
        }
       
    }

    const calculateProgressBarWidth = () => {
        return ((currentStep - 1) / (stepsConfig.length - 1)) * 100;
    };

    const ActiveComponent = stepsConfig[currentStep - 1]?.Component;

    return(
        <>
            <div className="stepper">
                {stepsConfig.map((step, index) => {
                    return (
                        <div
                            key={step.name}
                            ref={(el) => (stepRef.current[index] = el)}
                            className={`step ${
                            currentStep > index + 1 || isComplete ? "complete" : ""
                            } ${currentStep === index + 1 ? "active" : ""}`}
                        >
                        <div className="step-number">
                            {currentStep > index + 1 || isComplete ? (
                            <span>&#10003;</span>
                            ) : (
                            index + 1
                            )}
                        </div>
                        
                        </div>
                    );
                })}
                <div
                    className="progress-bar"
                    style={{
                    width: `calc(100%-${margins.marginLeft + margins.marginRight}px)`,
                    marginLeft: margins.marginLeft,
                    marginRight: margins.marginRight,
                    }}
                >
                <div
                    className="progress"
                    style={{width: `${calculateProgressBarWidth()}%`}}
                ></div>
                </div>
            </div>

            <ActiveComponent />
            <div >
                {currentStep === 6 ? <div> 
            <h1 className="text-xl text-center">We welcome any feedback or suggestions you may have on how we can enhance our services. Your input is highly valued and will help us better serve you in the future. Thank you for taking the time to share your thoughts with us.</h1>
            <div className="flex justify-center text-2xl items-center mt-10 gap-10">
              <textarea className="ctextarea w-full" placeholder="Write Something here..." value={selectedValueQ6} onChange={(e) => setSelectedValueQ6(e.target.value)}/>
            </div>
          </div> : <div></div>}
            </div>

            <div className="flex justify-between mt-10">
                
                <button className="pbutton" onClick={handlePrevious}>Previous</button>

                {!isComplete && (
                    <div>
                        {currentStep === stepsConfig.length ? <button  className="fbutton" disabled={button === false} onClick={handlesubmit}>Finish</button> : <button className="fbutton" onClick={handleNext}>Next</button>}
                    </div>
                    // 
                    //     {currentStep === stepsConfig.length ? "Finish" : "Next"}
                    // </button>
                )}

            </div>
    </>
    )
}

export default Stepper;