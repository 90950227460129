
import AOS from 'aos';

import Testimonials from "./Testimonial";

const WhatOurPartnersSay = () => {
    AOS.init()

    return(
        <div className="bg-[url('https://res.cloudinary.com/dtlrrlpag/image/upload/v1676314238/Bimer%20Website/Background%20Image/Bg-2_m9aqhg.jpg')] mt-28 w-full h-[100vh] bg-no-repeat bg-cover">
                <div data-aos="fade-right" className="flex flex-col p-20 csm:p-10 gap-10">
                    <div className="flex flex-col gap-3 ">
                        <h1 className="text-white text-4xl uppercase font-semibold">What</h1>
                        <h1 className="text-white text-4xl uppercase font-semibold"><span className="text-secondary text-4xl">Our Partners</span> say</h1>
                    </div>
                    <Testimonials/>
                </div>
        </div>
    )
}

export default WhatOurPartnersSay;