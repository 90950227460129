const Card = ({img}) => {
    return(
        <div className="w-[200px] border-2 hover:shadow-xl h-[124px] avsm:w-[155px] avsm:h-[90px]  rounded mb-2">
            <div>
                <img className="w-[200px] h-[120px] rounded mb-2 " src={img} alt="team"/>
            </div>
        </div>
    )
}

export default Card;