/* eslint-disable no-unused-vars */
import { message, Modal } from "antd"
import axios from "axios";
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { hiddenloading , ReloadData, showloading } from "../../../../Redux/server/rootSlice";
import {URL} from '../../../../Url/Url'

const Services = () => {

    const { serviceData } = useSelector((state) => state.root)

    const [showAddEditModal,setShowAddEditModal] = useState(false)
    const [selectedItemforEdit,setSelectedItemforEdit] = useState(null)
    const [services_image,setServicesImage] = useState('')
    const [services_title,setServicesTitle] = useState('')
    const [services_link,setServicesLink] = useState('')
    const [preview,setPreview] = useState('') 
    const dispatch = useDispatch()

    const token = localStorage.getItem('token')
    

    useEffect(() => {
        if(selectedItemforEdit){
            setServicesImage(selectedItemforEdit.services_image)
            setServicesTitle(selectedItemforEdit.services_title)
            setServicesLink(selectedItemforEdit.services_link)
            setPreview(selectedItemforEdit.services_image.url)
        }
    },[selectedItemforEdit])

    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        
        transformFile(file)
    }

    const transformFile = (file) => {
        const reader = new FileReader();
        if(file){
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setServicesImage(reader.result);
                setPreview(reader.result)
            };
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.patch(`${URL}/api/Bimer/services/`+ selectedItemforEdit._id,{services_image,services_title,services_link},config)

                if(data.success === true){
                    dispatch(showloading())
                    setShowAddEditModal(false)
                    setServicesImage('')
                    setServicesTitle('')
                    setServicesLink('')
                    setPreview('')
                    dispatch(ReloadData(true))
                    dispatch(hiddenloading())
                    message.success('Services Updated successfully')
                }
                
        }catch(err){

        }
    }

    return(
        <div>
            <div className="flex flex-col">
                <div className="flex flex-wrap gap-5">
                    {serviceData.map((data) => (
                        <div className="flex flex-col w-[300px] border-2 rounded">
                            <img className=" w-[300px]  " src={data.services_image.url} alt="Clients" />
                            <h1 className="font-bold text-2xl uppercase text-primary px-2">{data.services_title}</h1>
                            <button  className="bg-red-500 mt-[2px] text-white w-full p-2" onClick={() => {
                        setSelectedItemforEdit(data);
                        setShowAddEditModal(true)
                        }}>Update</button>
                        </div>
                    ))}
                </div>
            </div>
            <Modal visible={showAddEditModal}  footer={null} onCancel={() => {setShowAddEditModal(false); setSelectedItemforEdit(null)}}>'
            <h1 className="text-center text-xl uppercase font-semibold mt-5 mb-5">editor Services</h1>
                <img className="w-full h-[250px] border-2 rounded object-cover " src={preview} alt=""/>
                <form onSubmit={handleSubmit}>
                    <input className="cinput w-full" type="file" onChange={handleFileInputChange} />
                    <div className=" flex flex-col gap-1 mt-2">
                        <label className="font-semibold uppercase">Service Title</label>
                        <input className="cinput w-full" placeholder="Services" onChange={(e) => setServicesTitle(e.target.value)} value={services_title}/>
                    </div>
                    <div className=" flex flex-col gap-1 mt-2">
                        <label className="font-semibold uppercase">Service Link</label>
                        <select className="cSelect w-full" value={services_link} onChange={(e) => setServicesLink(e.target.value)}>
                            <option value='' selected disabled >Link</option>
                            <option value='properties'>Properties</option>
                            <option value='vehicles'>Vehicles</option>
                            <option value='logistics'>Logistics</option>
                            <option value='lifting'>Lifting</option>
                        </select>
                    </div>
                    <div className="flex justify-end mt-3 gap-5 w-full">
                        <button type="submit" className="bg-primary text-white w-[150px] px-5 py-1 rounded">Update Service</button>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export default Services;