import { useSelector } from "react-redux";

const Clients = () => {

    const { clientData } = useSelector((state) => state.root)

    return(
        <div>
            <div className=" flex flex-col justify-center items-center vsmm:mt-5 mt-10 w-full sm:gap-5 gap-10 sm:mb-5 mb-10 ">
            <div >
            <h1 className="text-primary text-4xl font-semibold uppercase">OUR PARTNERS</h1>
            </div>
            <div className="flex flex-wrap w-5/6 vvsm:w-full vsmm:w-full vsm:w-full lg:w-full md:w-full smm:w-full llg:w-full sm:w-full justify-center items-center gap-10">
                {clientData.map(client => (
                    <img className=" w-[250px] vsmm:w-[50px] vvsm:w-[85px] vsm:w-[110px] sm:w-[140px] md:w-[160px] smm:w-[180px] llg:w-[200px] " src={client.client_image.url} alt="Clients" />
                ))}
            </div>
        </div>
        </div>
    )
}

export default Clients;