/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Form, message } from "antd";
import axios from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hiddenloading, ReloadData, showloading } from "../../../../Redux/server/rootSlice"
import JoditEditor from 'jodit-react';
import {URL} from '../../../../Url/Url'

const WhoWeAre = () => {
    const { aboutData } = useSelector((state) => state.root)
    const token = localStorage.getItem('token')
    const dispatch = useDispatch()
    const [preview,setPreview] = useState('')
    const [about_desc,setAboutDesc] = useState('')
    const [about_image,setAboutImage] = useState('')
    const editor = useRef(null);
    

  
    useEffect(() => {
        setAboutDesc(aboutData.about_desc)
        setAboutImage(aboutData.about_image)
        setPreview(aboutData.about_image.url)
    },[aboutData])


    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        
        transformFile(file)
    }

    

    const transformFile = (file) => {
        const reader = new FileReader();
        if(file){
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setAboutImage(reader.result);
                setPreview(reader.result)
            };
        }
    }
   
    const onSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.patch(`${URL}/api/Bimer/about/whoweare`,{
                about_desc,about_image
            },config)
            dispatch(hiddenloading())
            if(data.success === true){
                message.success('About content Updated Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }


    return(
        <div className="flex flex-col gap-5">

           <form onSubmit={onSubmit}>
                
                <h1 className="text-2xl mb-5 font-semibold text-primary text-center uppercase">Who We Are</h1>
                <div className="flex flex-col gap-5">
                <JoditEditor
                ref={editor}
                value={about_desc}
                onChange={newContent => setAboutDesc(newContent)}     
                />
                    {/* <textarea className="atextarea h-[250px] w-full" onChange={(e) => setAboutDesc(e.target.value)} value={about_desc}/> */}
                </div>
                <h1 className="text-2xl font-semibold mt-5 text-primary text-center uppercase">Who We Are Image</h1>
                <div className="flex mt-5 w-full gap-5 items-center">
                    <div className="w-1/2">
                        <img className="w-[400px] h-[300px] border-2 rounded" src={preview} alt=""/>
                    </div>
                    <div className="flex flex-col w-1/2">
                        <input type="file" className="cinput w-[400px]" onChange={handleFileInputChange}/>
                    </div>
                </div>
                <div className="flex mt-5 justify-end w-full">
                        <button type="submit" className="bg-primary text-white w-[100px] py-2 px-5 rounded">Update</button>
                </div>
           </form>
        </div>
    )
}

export default WhoWeAre;