import { Tabs } from "antd"
import TabPane from "antd/es/tabs/TabPane"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Sidebar from "../Components/SidebarComponents/sidebar"
import ScreenError from "./ScreenError"
import { reset } from "../../Redux/auth/authSlice"
import ExportIntro from "../Components/AdminExportPage/ExportIntro/ExportIntro"
import ExportTopDesc from "../Components/AdminExportPage/ExportContent/ExportTopDesc"
import AdminExportItem from "../Components/AdminExportPage/ExportContent/AdminExportItem"

const AdminExportPage = () => {

    useEffect(() => {
        document.title = "Admin Export Page Management - Bimer Business Group"
    })

    const { exportData, exportItemData } = useSelector((state) => state.root)
    const [show,setShow] = useState(true)

    const ScreenSize = () => {
        if(window.innerWidth <= 1080){
            setShow(false)
        }
        else {
            setShow(true)
        }
    }

    useEffect(() => {
        ScreenSize()
        window.addEventListener('resize',() => {ScreenSize()})
    },[])


    

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.auth)

    

    useEffect(() => {
    
        if (!user) {
          navigate('/administrator')
        }
    
        return () => {
          dispatch(reset())
        }
      }, [user, navigate, dispatch])

    return(
      <div>
        {!show ? <ScreenError/> : <div>
        { exportData && exportItemData && (
        <div className="flex">
          <Sidebar/>
          <div className="flex flex-col w-full px-20 py-10 ml-[260px]">
            <Tabs  defaultActiveKey="1">
              <TabPane tab='Export Intro' key="1">
                <ExportIntro/>
              </TabPane>
              <TabPane tab="Export Top Description" key="2">
                <ExportTopDesc/>
              </TabPane>
              <TabPane tab="Export Products" key="3">
                <AdminExportItem/>
              </TabPane>
            </Tabs>
          </div>
        </div>
        )}
      </div>}
      </div>
    )
}

export default AdminExportPage;