const LoadingPage = () => {
    return(
        <div className="items-center h-screen w-full fixed flex justify-center bg-white inset-0 z-10">
            <h1 className="text-secondary font-bold text-6xl sm:text-3xl uppercase b">B</h1>
            <h1 className="text-secondary font-bold text-6xl sm:text-3xl uppercase i">I</h1>
            <h1 className="text-secondary font-bold text-6xl sm:text-3xl uppercase m">M</h1>
            <h1 className="text-secondary font-bold text-6xl sm:text-3xl uppercase e">E</h1>
            <h1 className="text-secondary font-bold text-6xl sm:text-3xl uppercase r">R</h1>
        </div>
    )
}

export default LoadingPage;