import { URL } from "../../../Url/Url";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from "axios";
import Swal from 'sweetalert2'
import { ReloadData, hiddenloading, showloading } from '../../../Redux/server/rootSlice';

const ContactUs = () => {

    const [name,setName] = useState(null)
    const [email,setEmail] = useState(null)
    const [messages,setMessages] = useState(null)
    const dispatch = useDispatch()
      

    const handleSubmit = async (e) => {
            e.preventDefault();
    
            dispatch(showloading())
            
            const {data} = await axios.post(`${URL}/api/Bimer/contact/send-mail`, {
                name,email,messages
            });
    
            if(data.success === true){
                dispatch(showloading())
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Your Message Submit Successfully.',
                    showConfirmButton: false,
                    timer: 2000
                  })
                setName(null)
                setEmail(null)
                setMessages(null)
                dispatch(ReloadData(true))
                dispatch(hiddenloading())
            }
       
      };


    return(
        <div className='flex flex-col w-4/12 llg2:w-8/12 llg2:p-0 p-10'>
            <div className='flex flex-col gap-1'>
                <h1 className='text-2xl font-semibold text-white uppercase'>Contact Us</h1>
                <div className='w-[145px] h-[3px] mb-3 rounded bg-white'></div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='flex flex-col gap-5'>
                    <input className='cinput w-full' value={name} onChange={(e) => setName(e.target.value)} placeholder='Full Name' type="text"/>
                    <input className='cinput w-full' value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder='Email Address'/>
                    <textarea className='ctextarea w-full' value={messages} onChange={(e) => setMessages(e.target.value)}  placeholder='Message' type="text"/>
                    <button disabled={!name || !email || !messages} className='cbutton w-[120px]'>Submit</button>
                </div>
            </form>
        </div>
    )
}

export default ContactUs;