import { useEffect } from "react";
import { useParams } from "react-router-dom";
import StoreNavbar from "../Components/NavbarComponents/Store-Navbar/StoreNavbar";
import VehicleImage from "../Components/BimerStoreComponents/BimerVehiclesStore/BimerVehiclesStoreDetails/VehiclesImage";
import VehicleTitle from "../Components/BimerStoreComponents/BimerVehiclesStore/BimerVehiclesStoreDetails/VehiclesTitle";
import VehicleDescription from "../Components/BimerStoreComponents/BimerVehiclesStore/BimerVehiclesStoreDetails/VehiclesDescription";
import Footer from "../Components/FooterPageComponents/Footer";

const  VehicleDetails = () => {

    const {id} = useParams()


    useEffect(() => {
        window.scrollTo(0, 0);
    },[])

    return(
        <div className="w-full">
            <StoreNavbar/>
            <VehicleTitle id={id}/>
            <div className="flex justify-center md:flex-col md:items-center mb-10">
                <div className="w-[550px] vsm:w-full px-10 vsmm:px-5">
                    <VehicleImage id={id}/>
                </div>
                <div>
                    <VehicleDescription id={id}/>
                </div>
            </div>
            <Footer/>
            
        </div>
    )
}

export default VehicleDetails;