import { message } from "antd"
import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { hiddenloading, ReloadData, showloading } from "../../../../Redux/server/rootSlice"
import JoditEditor from 'jodit-react';
import {URL} from '../../../../Url/Url'

const LogisticsContent = () => {

    const { logisticsData } = useSelector((state) => state.root)

    const [logistics_desc,setLogisticsDesc] = useState('')
    const [logistics_image,setLogisticsImage] = useState('')
    const [preview,setPreview] = useState('')

    const dispatch = useDispatch()
    const editor = useRef(null);

    const token = localStorage.getItem('token')

    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        
        transformFile(file)
    }

    useEffect(() => {
        setLogisticsDesc(logisticsData.logistics_desc)
        setLogisticsImage(logisticsData.logistics_image)
        setPreview(logisticsData.logistics_image.url)
    },[logisticsData])

    const transformFile = (file) => {
        const reader = new FileReader();
        if(file){
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setLogisticsImage(reader.result);
                setPreview(reader.result)
            };
        }
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.patch(`${URL}/api/Bimer/logistics/description`,{
                logistics_desc,logistics_image
            },config)
            dispatch(hiddenloading())
            if(data.success === true){
                message.success('Logistics content Updated Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }
    return(
        <div className="flex flex-col gap-5">
              <form onSubmit={onSubmit}>
                <div className="flex flex-col gap-5">
                    <JoditEditor ref={editor} value={logistics_desc} onChange={newContent => setLogisticsDesc(newContent)} />
                </div>
                <div className="flex mt-5 w-full gap-5 items-center">
                    <div className="w-1/2">
                        <img className="w-[400px] h-[300px] border-2 rounded" src={preview} alt=""/>
                    </div>
                    <div className="flex flex-col w-1/2">
                        <input type="file" className="cinput w-[400px]" onChange={handleFileInputChange}/>
                    </div>
                </div>
                <div className="flex mt-5 justify-end w-full">
                        <button type="submit" className="bg-primary text-white w-[100px] py-2 px-5 rounded">Update</button>
                </div>
           </form>

        </div>
    )
}

export default LogisticsContent;