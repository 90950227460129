import { useSelector } from "react-redux";

const LogisticsSection = () => {

    const { logisticsData } = useSelector((state) => state.root)

    return(
        <div className="w-full flex flex-col px-32 llg:px-20 sm2:px-10  py-10 items-center justify-center">
            <div>
                <h1 className="text-primary text-4xl font-semibold">A Well-Managed Logistics Solutions</h1>
                <div className="flex w-full items-center mt-5 sm2:flex-col"> 
                    <div className="flex flex-col w-1/2 sm2:w-full gap-8">
                        <div>
                        <div dangerouslySetInnerHTML={{__html: logisticsData.logistics_desc}}/>
                        </div>
                    </div>
                    <div className="w-1/2 sm2:w-full ml-20 sm2:ml-0 sm2:-mt-10">
                        <img className="w-full h-[300px] object-contain rounded" src={logisticsData.logistics_image.url} alt="Des"/>
                    </div>
                </div>
          </div>
    </div>
    )
}

export default LogisticsSection;