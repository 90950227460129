import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reset } from "../../Redux/auth/authSlice";
import Sidebar from "../Components/SidebarComponents/sidebar";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import ContactForm from "../Components/AdminAboutPageComponents/ContactComponents/ContactForm";
import OurTeam from "../Components/AdminAboutPageComponents/TeamComponents/OurTeam";
import AboutIntro from "../Components/AdminAboutPageComponents/AdminContentComponents/AdminIntro";
import WhoWeAre from "../Components/AdminAboutPageComponents/AdminContentComponents/WhoWeAre";
import VisionAndValue from "../Components/AdminAboutPageComponents/AdminContentComponents/VisionAndValue";
import ScreenError from "./ScreenError";

const AdminAboutPage = () => {

    useEffect(() => {
        document.title = "Admin About Page Management - Bimer Business Group"
    })

    const { aboutData } = useSelector((state) => state.root)
    const [show,setShow] = useState(true)

    const ScreenSize = () => {
        if(window.innerWidth <= 1080){
            setShow(false)
        }
        else {
            setShow(true)
        }
    }

    useEffect(() => {
        ScreenSize()
        window.addEventListener('resize',() => {ScreenSize()})
    },[])

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.auth)
    
    useEffect(() => {
    
        if (!user) {
          navigate('/administrator')
        }
    
        return () => {
          dispatch(reset())
        }
      }, [user, navigate, dispatch])

    return(
      <div>
        {!show ? <ScreenError/> : <div>
        {aboutData && (
          <div className="flex">
            <Sidebar/>
            <div className="flex flex-col w-full px-20 py-10 ml-[260px]">
              <Tabs  defaultActiveKey="1">
                  <TabPane tab='About Intro' key="1">
                      <AboutIntro/>
                  </TabPane>
                  <TabPane tab='Who We Are' key="2">
                      <WhoWeAre/>
                  </TabPane>
                  <TabPane tab='Vision and Values' key="3">
                      <VisionAndValue/>
                  </TabPane>
                  <TabPane tab='Our Team' key="4">
                      <OurTeam/>
                  </TabPane>
                  <TabPane tab='Contact Info' key="5">
                      <ContactForm/>
                  </TabPane>
               </Tabs>
           </div>
       </div>
        )}
      </div>}
      </div>
    )
}

export default AdminAboutPage;