import { useSelector } from "react-redux";
import SafetyImageGallery from "./SafetyImageGallery";

const SafetyContent = () => {

    const { logisticsData,safetyData } = useSelector((state) => state.root)

    return(
        <div className="w-full flex flex-col px-32 llg:px-20 sm2:px-10 sm2:-mt-20 mb-12 items-center justify-center">
            <div className="flex flex-row-reverse w-full items-center mt-5 sm2:flex-col">
                <div className="flex flex-col w-1/2 sm2:w-full gap-8 sm2:gap-2">
                    <h1 className="text-primary text-4xl font-semibold">Safety and Security Training Programs</h1>
                    <div>
                        <div dangerouslySetInnerHTML={{__html: logisticsData.safety_desc}}/>
                    </div>
                </div>
                <div className="w-1/2 sm2:w-full mr-20 sm2:mr-0 sm2:mt-5">
                    <SafetyImageGallery imageDatas={safetyData}/>
                </div>
            </div>
        </div>
    )
}

export default SafetyContent;