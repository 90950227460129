import { Modal, message } from "antd"
import JoditEditor from "jodit-react"
import { useEffect, useRef, useState } from "react"
import { FaTrash } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { ReloadData, hiddenloading, setSparepartItemsData, showloading } from "../../../../Redux/server/rootSlice"
import axios from "axios"
import {URL} from '../../../../Url/Url'
import Pagination from "./Pagination";
import { useLocation } from "react-router-dom"

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

const SparepartManagement = () => {

    const { brandData, sparepartItemsData} = useSelector((state) => state.root)

    const [showAddEditModal,setShowAddEditModal] = useState(false)
    const [selectedItemforEdit,setSelectedItemforEdit] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteID,setDeleteId] = useState(null)
    const [sparepart_availability,setSparepartAvailability] = useState('in stock')
    const [sparepart_brand,setSparepartBrand] = useState('')
    const [sparepart_category,setSparepartCategory] = useState('')
    const [sparepart_description,setSparepartDescription] = useState('')
    const [sparepart_images,setSparepartImages] = useState([])
    const [sparepart_partnumber,setSparepartPartNumber] = useState('')
    const [sparepart_type,setSparepartType] = useState('')
    const [preview,setPreview] = useState([])
    const [newPreview,setNewPreview] = useState([])
    const [partNumberSearch,setPartNumberSearch] = useState('')
    const [typeSearch,setTypeSearch] = useState('')
    const [brandSearch,setBrandSearch] = useState('')
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')
    const editor = useRef(null);
    const query = useQuery()
    const page = query.get('page') || 1; 

    useEffect(() => {
        if(selectedItemforEdit){
            setSparepartAvailability(selectedItemforEdit.sparepart_availability)
            setSparepartBrand(selectedItemforEdit.sparepart_brand)
            setSparepartCategory(selectedItemforEdit.sparepart_category)
            setSparepartDescription(selectedItemforEdit.sparepart_description)
            setSparepartImages(selectedItemforEdit.sparepart_images)
            setPreview(selectedItemforEdit.sparepart_images)
            setSparepartPartNumber(selectedItemforEdit.sparepart_partnumber)
            setSparepartType(selectedItemforEdit.sparepart_type)
        }else{
            setSparepartAvailability('in stock')
            setSparepartBrand('')
            setSparepartCategory('')
            setSparepartDescription('')
            setSparepartImages([])
            setPreview([])
            setSparepartPartNumber('')
            setSparepartType('')
        }
    },[selectedItemforEdit])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            if(selectedItemforEdit){
                const {data} = await axios.patch(`${URL}/api/Bimer/sparepartitems/` + selectedItemforEdit._id,{sparepart_availability,sparepart_brand,sparepart_category,sparepart_description,sparepart_partnumber,sparepart_type},config)
                dispatch(hiddenloading())
                if(data.success === true){
                    setShowAddEditModal(false)
                    setSparepartAvailability('in stock')
                    setSparepartBrand('')
                    setSparepartCategory('')
                    setSparepartDescription('')
                    setSparepartImages([])
                    setPreview([])
                    setSparepartPartNumber('')
                    setSparepartType('')
                    message.success("Spare Part Updated Successfully")
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }else{
                const {data} = await axios.post(`${URL}/api/Bimer/sparepartitems`,{sparepart_availability,sparepart_brand,sparepart_category,sparepart_description,sparepart_images,sparepart_partnumber,sparepart_type},config)
                dispatch(hiddenloading())
                if(data.success === true){
                    setShowAddEditModal(false)
                    setSparepartAvailability('in stock')
                    setSparepartBrand('')
                    setSparepartCategory('')
                    setSparepartDescription('')
                    setSparepartImages([])
                    setPreview([])
                    setSparepartPartNumber('')
                    setSparepartType('')
                    message.success("Spare Part Added Successfully")
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }


    }

    const handleImageDelete = async (id) => {
        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
            dispatch(showloading())
            const data = await axios.delete(`${URL}/api/Bimer/sparepartitems/${selectedItemforEdit._id}/` + id, config)
            dispatch(hiddenloading())
            if(data.data.success === true){
                setShowAddEditModal(false)
                message.success('Vehicles Image Delete Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            message.error(err.message)
        }
    }

    

    const handleImageChange = (e) => {
        const files = e.target.files;
        const imagesArray = [];
    
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          // eslint-disable-next-line no-loop-func
          reader.onload = () => {
            if (reader.readyState === 2) {
              imagesArray.push(reader.result);
              setSparepartImages(imagesArray);
              setPreview(imagesArray)
            }
          };
    
          reader.readAsDataURL(files[i]);
        }
      };

      const handleNewImageInput = (e) => {
        const files = e.target.files;
        const imagesArray = [];
    
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          // eslint-disable-next-line no-loop-func
          reader.onload = () => {
            if (reader.readyState === 2) {
              imagesArray.push(reader.result);
              setSparepartImages(imagesArray);
              setNewPreview(imagesArray)
            }
          };
    
          reader.readAsDataURL(files[i]);
        }
      };

      const handleNewImages = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            if(selectedItemforEdit){
                const {data} = await axios.patch(`${URL}/api/Bimer/sparepartitems/image/` + selectedItemforEdit._id,{
                    sparepart_images
                },config)
                dispatch(hiddenloading())
                if(data.success === true){
                    setShowAddEditModal(false)
                    setSparepartImages([])
                    setNewPreview([])
                    message.success('Spare Part Image Addes Successfuly')
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
      }

      const handleDelete = async (id) => {
        try{
            console.log(id)
            const config = {
                headers:{
                    Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const data = await axios.delete(`${URL}/api/Bimer/sparepartitems/` + id, config)
            dispatch(hiddenloading())
            if(data.data.success === true){
                message.success('Spare Part Item Deleted Successfully')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            message.error(err.message)
        }
      }

      useEffect(() => {

      },[sparepartItemsData])
      
      const searchSparepart = async () => {
        try{
            const responce = await axios.get(`${URL}/api/Bimer/sparepartitems/search?searchQuery=${partNumberSearch || ""}&sparepart_type=${typeSearch || ""}&sparepart_brand=${brandSearch || ""}`)
            dispatch(setSparepartItemsData(responce.data.sparepartitems))
            dispatch(ReloadData(false))
            dispatch(hiddenloading())
        }catch(err){
            dispatch(hiddenloading())
        }
      }

    return(
        <div>
            <div className="flex flex-col">
                <div className="flex justify-end">
                    <button className="bg-primary  text-white w-[200px] py-2 px-5 rounded" onClick={() => {
                        setSelectedItemforEdit(null)
                        setShowAddEditModal(true)
                    }}>Add New Spare Part
                    </button>
                </div>

                <hr className="mt-5 mb-5"/>

                <div className="flex flex-col justify-center">
                   
                        <div className="flex justify-center gap-5">
                                
                                <input className="cinput w-[400px]" value={partNumberSearch} onChange={(e) => setPartNumberSearch(e.target.value)} placeholder="Part Number"/>
                                <input className="cinput w-[400px]" value={typeSearch} onChange={(e) => setTypeSearch(e.target.value)} placeholder="Spare Part Name"/>
                           
                        </div>
                        <div className="flex -ml-5 mt-3 justify-center gap-5">
                                
                                <select className="cinput w-[400px]" value={brandSearch} onChange={(e) => setBrandSearch(e.target.value)}>
                                    <option value="" disabled selected hidden>Mark</option>
                                    {brandData.map((data) => (
                                        <option value={data.brand_name}>{data.brand_name}</option>
                                    ))}
                                </select>
                        
                                <button onClick={searchSparepart} className="cbutton w-[400px]">
                                    Search
                                </button>
                        

                        </div>
                    
                </div>

                <div className="flex flex-wrap justify-center mt-10 gap-3 w-full">
                    {sparepartItemsData.length === 0 ? <img className="w-[400px] object-contain" src="https://res.cloudinary.com/dbewtrc1q/image/upload/v1687779786/Bimer%20Extra%20Items/dprgbk8ejv3tw0pkocab.jpg" alt="no_item_found"/> : <div className="flex flex-wrap justify-center mt-10 gap-8 w-full">
                        {sparepartItemsData.map((item) => (
                            <div className="flex flex-col  w-[275px] rounded-md">
                                <img className="w-[275px] h-[200px] " src={item.sparepart_images[0].url}  alt="VehicleImage"/>   
                                <h1 className="text-xl font-semibold text-center mt-3">{item.sparepart_type}</h1>
                                <div className="flex gap-3 mt-3 w-full">
                                    <button className="p-2 text-white bg-primary w-1/2" onClick={() => {
                                        setSelectedItemforEdit(item)
                                        setShowAddEditModal(true)
                                    }}>Edit</button>
                                     <button className="p-2 text-white bg-red-500 w-1/2" onClick={() => {
                                        setDeleteId(item._id)
                                        setShowDeleteModal(true)
                                    }}>Delete</button>
                                </div>
                            </div>
                        ))}
                    </div>}
                </div>
                <div className={sparepartItemsData.length === 0 ? " hidden" : "flex justify-center mt-5 "}>
                        <Pagination page={page}/>
                </div>
            </div>
            <Modal visible={showAddEditModal} width={'800px'} footer={null} onCancel={() => {setShowAddEditModal(false); setSelectedItemforEdit(null)}}>
                <h1 className="text-center text-xl uppercase font-semibold mt-5 mb-5">{selectedItemforEdit ? 'Update Spare Part' : 'Add Spare Part' }</h1>   
                <form onSubmit={handleSubmit}>
                    <div className="flex gap-5 w-full items-center">
                        <div className="flex flex-col w-1/2">
                            <div className="flex flex-col gap-1 w-full">
                                <label className="font-bold uppercase">Spare Part Type:</label>
                                <input className="cinput w-full" type="text" onChange={(e) => setSparepartType(e.target.value)} value={sparepart_type}/>
                            </div>
                            <div className="flex flex-col gap-1 w-full mt-2">
                                <label className="font-bold uppercase">Spare Part Number:</label>
                                <input className="cinput w-full" type="text" onChange={(e) => setSparepartPartNumber(e.target.value)} value={sparepart_partnumber}/>
                            </div>
                            <div className="flex flex-col gap-1 w-full mt-2">
                                <label className="font-bold uppercase">Spare Part Mark:</label>
                                <select className="cinput w-full" value={sparepart_brand} onChange={(e) => setSparepartBrand(e.target.value)}>
                                    <option value="" disabled selected hidden>Spare Part Mark</option>
                                    {brandData.map((data) => (
                                        <option value={data.brand_name}>{data.brand_name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex flex-col gap-1 w-full mt-2">
                                <label className="font-bold uppercase">Category:</label>
                                <select className="cinput w-full" value={sparepart_category} onChange={(e) => setSparepartCategory(e.target.value)}>
                                    <option value={"Spare Part"}>Spare Part</option>
                                </select>
                            </div>
                            <div className="flex flex-col gap-1 w-full mt-2">
                                <label className="font-bold uppercase">Spare Part Availability:</label>
                                <select className="cinput w-full" value={sparepart_availability} onChange={(e) => setSparepartAvailability(e.target.value)}>
                                    <option  value="in stock">IN STOCK</option>
                                    <option value="on shipping">ON SHIPPING</option>
                                    <option value="out off stock">OUT OFF STOCK</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 w-1/2">
                            <div className="flex flex-col gap-1 w-full">
                                <label className="font-bold uppercase">Spare Part Description:</label>
                                <JoditEditor ref={editor} value={sparepart_description} onChange={newContent => setSparepartDescription(newContent)} />
                            </div>
                            <div className={selectedItemforEdit ? 'hidden' : "flex flex-col gap-1 w-full mt-2"}>
                                <label className="font-bold uppercase">Model Images:</label>
                                <input className="cinput w-full" type="file" multiple onChange={handleImageChange}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end mt-3 gap-5 w-full">
                        <button type="submit" className="bg-primary text-white w-[175px] px-5 py-1 rounded">{selectedItemforEdit ? 'Update Spare Part' : 'Add Spare Part'}</button>
                    </div>
                </form>
                <div className="flex flex-wrap gap-5 mt-5 justify-center items-center">
                    {preview.map((data) => (
                        <div className=" w-[200px] object-contain">
                            <img className="h-[135px] object-cover" src={selectedItemforEdit ? data.url : data} alt="pic"/>
                            <button className={selectedItemforEdit ? 'text-red-500 ml-[180px] -mt-[130px] absolute z-10' : 'hidden'} onClick={() => {
                                handleImageDelete(data._id)
                            }}><FaTrash/></button>
                        </div>
                    ))}
                </div>
                <div className={selectedItemforEdit ? "flex flex-wrap gap-5 mt-5 justify-center items-center" : "hidden"}>
                    {newPreview.map((data) => (
                        <div className=" w-[200px] object-contain">
                            <img className="h-[135px] object-cover" src={data} alt="pic"/>
                        </div>
                    ))}
            </div>
            <div className={selectedItemforEdit ? 'flex flex-col gap-2' : 'hidden'}>
                <form onSubmit={handleNewImages}>
                    <label className='font-bold mt-10 mb-3'>Project Images</label>
                    <input className='cinput w-full' type="file" multiple onChange={selectedItemforEdit ? handleNewImageInput : handleImageChange}/>
                    <div className="flex justify-end mt-3 gap-5 w-full">
                        <button type="submit" className="bg-primary text-white w-[150px] px-5 py-1 rounded">Add Image</button>
                    </div>
                </form>
            </div>   
            </Modal>
            <Modal visible={showDeleteModal} footer={null} closable={false} centered={true} onCancel={() => {setShowDeleteModal(false); setDeleteId(null)}}>
                    <h1 className="text-center text-2xl">Are you sure want to delete?</h1>
                    <div className="flex justify-center items-center gap-5 mt-5">
                        <button className="bg-primary w-[80px] p-1 rounded text-white" onClick={() => {handleDelete(deleteID); setShowDeleteModal(false)}}>Ok</button>
                        <button className="bg-red-500 w-[80px] p-1 rounded text-white" onClick={() => {
                            setShowDeleteModal(false) 
                            setDeleteId(null)
                        }}>Cancel</button>
                    </div>
            </Modal>
        </div>
    )
}

export default SparepartManagement;