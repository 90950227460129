import {
    FaHome,
    FaInfo,
    FaTruck,
    FaCar,
    FaBuilding,
    FaSignOutAlt,
}from "react-icons/fa";
import {GiForklift} from 'react-icons/gi'
import {IoIosSettings} from 'react-icons/io'
import {TfiPackage} from 'react-icons/tfi'
import { CiExport } from "react-icons/ci";
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { logout, reset } from '../../../Redux/auth/authSlice'
import './sidebar.css'


const Sidebar = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const menuItem=[
        {
            path:"/admindashboard/homepage",
            name:"Home Page",
            icon:<FaHome/>
        },
        {
            path:"/admindashboard/aboutpage",
            name:"About Page",
            icon:<FaInfo/>
        },
        {
            path:"/admindashboard/propertiespage",
            name:"Properties Page",
            icon:<FaBuilding/>
        },
        {
            path:"/admindashboard/vehiclespage",
            name:"Vehicles Page",
            icon:<FaCar/>
        },
        {
            path:"/admindashboard/logisticspage",
            name:"Logistics Page",
            icon:<FaTruck/>
        },
        {
            path:"/admindashboard/liftingpage",
            name:"Lfting Page",
            icon:<GiForklift/>
        },
        {
            path:"/admindashboard/productpage",
            name: "Products Page",
            icon: <TfiPackage/>
        },
        {
            path:"/admindashboard/exportpage",
            name: "Export Page",
            icon: <CiExport/>
        },
        {
            path:"/admindashboard/setting",
            name:"Setting",
            icon:<IoIosSettings/>
        }
        
    ]
    const onLogout = () => {
        dispatch(logout())  
        dispatch(reset())
        navigate('/administrator')
     }
    return (
        <div>
           <div className='bg-primary w-[250px] fixed flex flex-col text-white h-[100vh] transition-all'>
               
                <div className='mt-10 mb-10 '>
                    <img className='flex w-[100px] h-[100px] rounded-full bg-white object-contain ml-[70px] ' src="https://res.cloudinary.com/dtlrrlpag/image/upload/v1674887984/Bimer%20Website/Bimer%20Logo/Bimer_Logo_flmrkj.png" alt="Logo" />
                </div>

               {
                   menuItem.map((item, index)=>(
                       <NavLink to={item.path} key={index} className="link" activeclassName="active">
                           <div className="icon">{item.icon}</div>
                           <div className="link_text">{item.name}</div>
                       </NavLink>
                   ))
               }
               <div  className='fixed bottom-0 left-0'>
                    <button className="link" onClick={onLogout}>
                        <div className="icon"><FaSignOutAlt/></div>
                        <div className="link_text">Logout</div>
                    </button>
               </div>
           </div>
        </div>
    );
};

export default Sidebar;