import { Avatar } from "antd"

const TCard = ({img,description,name,title}) => {
    return(
        <div className="flex flex-col  gap-1">
            <div className=" flex gap-5 items-center">
                <Avatar className="w-[80px] h-[80px] rounded-full" src={img} alt="avater"/>
                <div className="flex flex-col">
                    <h1 className="text-lg text-white uppercase font-bold">{name}</h1>
                    <h1 className="text-white font-light">{title}</h1>
                </div>
            </div>
            <h1 className="mt-4 ml-1 text-white">{description}</h1>
        </div>
    )
}

export default TCard;