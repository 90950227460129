import { Modal, message } from "antd"
import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { hiddenloading, ReloadData, showloading } from "../../../../Redux/server/rootSlice"
import JoditEditor from 'jodit-react';
import {URL} from '../../../../Url/Url'

const SafetyContent = () => {

    const { logisticsData, safetyData } = useSelector((state) => state.root)

    const [safety_desc,setSafetyDesc] = useState('')
    const [safety_image,setSafetyImage] = useState('')
    const [showAddEditModal,setShowAddEditModal] = useState(false)
    const [selectedItemforEdit,setSelectedItemforEdit] = useState(null)
    const [preview,setPreview] = useState('')

    const dispatch = useDispatch()
    const editor = useRef(null);

    const token = localStorage.getItem('token')

    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        
        transformFile(file)
    }
    useEffect(() => {
        if(selectedItemforEdit){
            setSafetyImage(selectedItemforEdit.safety_image)
            setPreview(selectedItemforEdit.safety_image.url)
        }
        else{
            setSafetyImage('')
            setPreview('')
        }
    },[selectedItemforEdit])

    useEffect(() => {
        setSafetyDesc(logisticsData.safety_desc)
    },[logisticsData])

    const transformFile = (file) => {
        const reader = new FileReader();
        if(file){
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setSafetyImage(reader.result);
                setPreview(reader.result)
            };
        }
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.patch(`${URL}/api/Bimer/logistics/safety`,{
                safety_desc,safety_image
            },config)
            dispatch(hiddenloading())
            if(data.success === true){
                message.success('Logistics content Updated Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }

    const onImageSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.patch(`${URL}/api/Bimer/safety/`+ selectedItemforEdit._id,{
                safety_image
            },config)
            dispatch(hiddenloading())
            if(data.success === true){
                message.success('Logistics content Updated Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }
    return(
        <div className="flex flex-col gap-5">
              <form onSubmit={onSubmit}>
                <div className="flex flex-col gap-5">
                    <JoditEditor ref={editor} value={safety_desc} onChange={newContent => setSafetyDesc(newContent)} />
                </div>

                
                <div className="flex mt-5 justify-end w-full">
                        <button type="submit" className="bg-primary text-white w-[100px] py-2 px-5 rounded">Update</button>
                </div>
           </form>
           {/* <div className="flex justify-end">
                    <button className="bg-primary text-white w-[200px] py-2 px-5 rounded" onClick={() => {
                    setSelectedItemforEdit(null);
                    setShowAddEditModal(true)
                }} >Add Image</button>
                </div> */}
            <div className="flex flex-wrap gap-5">

            {safetyData.map((data) => (
                <div className="flex flex-col w-[500px] border-2 rounded">
                    <img className=" w-[500px] h-[300px] object-cover " src={data.safety_image.url} alt="" />
                    <button  className="bg-red-500 mt-[2px] text-white w-full p-2" onClick={() => {
                        setSelectedItemforEdit(data);
                        setShowAddEditModal(true)
                    }}>Update</button>
                </div>  
            ))}
            </div>
           <Modal visible={showAddEditModal}  footer={null} onCancel={() => {setShowAddEditModal(false); setSelectedItemforEdit(null)}}>
                <div className="flex flex-col mt-5 w-full gap-5 items-center">
                    <div className="w-full mt-5" >
                        <img className="w-full h-[250px] border-2 rounded object-cover " src={preview} alt=""/>
                    </div>
                    <div className="flex flex-col w-full">
                        <form onSubmit={onImageSubmit}>
                            <input type="file" className="cinput w-full" onChange={handleFileInputChange}/>
                            <div className="flex justify-end mt-3 gap-5 w-full">
                                <button type="submit" className="bg-primary text-white w-[150px] px-5 py-1 rounded">Update Image</button>
                            </div>
                        </form>
                    </div>
                </div>
           </Modal>

        </div>
    )
}

export default SafetyContent;