import { useEffect } from "react";
import Navbar from "../Components/NavbarComponents/Main-Navbar/Navbar";
import Banner from "../Components/HomePageComponents/BannerComponents/Banner";
import { useSelector } from "react-redux";
import Services from "../Components/HomePageComponents/ServicesCompnents/Services";
import WhatOurPartnersSay from "../Components/HomePageComponents/TestimonialComponents/WhatOurPartnersSay";
import Clients from "../Components/HomePageComponents/ClientComponents/Clients";
import Footer from "../Components/FooterPageComponents/Footer";
import ScrollToTop from "react-scroll-to-top";

const HomePage = () => {

    useEffect(() => {
        document.title = "Home - Bimer Business Group"
    })

    const { bannerData,serviceData,testimonialData,clientData,contactData } = useSelector((state) => state.root) 

    return(
        <div>
            {bannerData && serviceData && testimonialData && clientData && contactData &&(
                <div className="w-full">
                    <Navbar/>
                    <Banner/>
                    <Services/>
                    <WhatOurPartnersSay/>
                    <Clients/>
                    <ScrollToTop smooth top="20" color="white" style={{
                        backgroundColor: "#CBA540",
                        width: "40px",
                        height: "40px",
                        borderRadius: "100%",
                        alignItems: "center"
                    }}
                        viewBox="5 3 10 30"
                        svgPath="M24,21a1,1,0,0,1-.71-.29L16,13.41l-7.29,7.3a1,1,0,1,1-1.42-1.42l8-8a1,1,0,0,1,1.42,0l8,8a1,1,0,0,1,0,1.42A1,1,0,0,1,24,21Z"
                    />
                    <Footer/>
                </div>
            )}
        </div>
    )
}

export default HomePage;