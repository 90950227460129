import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { reset } from "../../Redux/auth/authSlice"
import Sidebar from "../Components/SidebarComponents/sidebar"
import { Tabs } from "antd"
import TabPane from "antd/es/tabs/TabPane"
import ProductBrand from "../Components/AdminProductsPageComponents/BrandsManagement/ProductBrands"
import VehiclesManagement from "../Components/AdminProductsPageComponents/VehiclesManagement/VehiclesManagement"
import SparepartManagement from "../Components/AdminProductsPageComponents/SparepartsManagement/SparepartsManagement"
import ScreenError from "./ScreenError";

const AdminProductsPage = () => {

    useEffect(() => {
        document.title = "Admin Products Page Management - Bimer Business Group"
    })

    const [show,setShow] = useState(true)

    const ScreenSize = () => {
        if(window.innerWidth <= 1080){
            setShow(false)
        }
        else {
            setShow(true)
        }
    }

    useEffect(() => {
        ScreenSize()
        window.addEventListener('resize',() => {ScreenSize()})
    },[])

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.auth)
    
    useEffect(() => {
    
        if (!user) {
          navigate('/administrator')
        }
    
        return () => {
          dispatch(reset())
        }
      }, [user, navigate, dispatch])

    return(
        <div>
            {!show ? <ScreenError/> : <div>
                <div className="flex">
                    <Sidebar/>
                    <div className="flex flex-col w-full px-20 py-10 ml-[260px]">
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Brand Management" key="1">
                                <ProductBrand/>
                            </TabPane>
                            <TabPane tab="Vehicles Management" key="2">
                                <VehiclesManagement/>
                            </TabPane> 
                            <TabPane tab="Sparepart Management" key="3">
                               <SparepartManagement/>
                            </TabPane>     
                        </Tabs>    
                    </div>    
                </div>    
            </div>}
        </div>
    )
}

export default AdminProductsPage;