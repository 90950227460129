import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reset } from '../../Redux/auth/authSlice'
import Sidebar from "../Components/SidebarComponents/sidebar";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import VehiclesContent from "../Components/AdminVehiclesPageComponents/VehiclesContent/VehiclesContent";
import ImportVehicles from "../Components/AdminVehiclesPageComponents/VehiclesImport/ImportVehicles";
import VehiclesShowroom from "../Components/AdminVehiclesPageComponents/VehiclesShowroom/VehiclesShowroom";
import ScreenError from "./ScreenError";

const AdminVehiclesPage = () => {

    useEffect(() => {
        document.title = "Admin Vehicles Page Management - Bimer Business Group"
    })

    const { vehiclesData, importData, showroomData } = useSelector((state) => state.root)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.auth)
    const [show,setShow] = useState(true)

    const ScreenSize = () => {
        if(window.innerWidth <= 1080){
            setShow(false)
        }
        else {
            setShow(true)
        }
    }

    useEffect(() => {
        ScreenSize()
        window.addEventListener('resize',() => {ScreenSize()})
    },[])

    useEffect(() => {
    
        if (!user) {
          navigate('/administrator')
        }
    
        return () => {
          dispatch(reset())
        }
      }, [user, navigate, dispatch])

    return(
        <div>
          {!show ? <ScreenError/> : <div>
          {vehiclesData && importData && showroomData && (
          <div className="flex">
            <Sidebar/>
              <div className="flex flex-col w-full px-20 py-10 ml-[260px]">
                <Tabs  defaultActiveKey="1">
                  <TabPane tab='Vehicles Description' key="1">
                    <VehiclesContent/>
                  </TabPane>
                  <TabPane tab='Imported Vehicles' key="2">
                    <ImportVehicles/>     
                  </TabPane>
                  <TabPane tab='Showroom Gallery' key="3">
                    <VehiclesShowroom/>      
                  </TabPane>
                </Tabs>
              </div>
            </div>
            )}
        </div>}
        </div>
    )
}

export default AdminVehiclesPage;