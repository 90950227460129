import { message, Modal, Space, Table } from "antd";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hiddenloading , ReloadData, showloading } from "../../../../Redux/server/rootSlice"
import { URL } from "../../../../Url/Url";
import Column from "antd/es/table/Column";


const ProductBrand = () => {

    const [showAddEditModal,setShowAddEditModal] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [selectedItemforEdit,setSelectedItemforEdit] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteID,setDeleteId] = useState(null)
    const [brandName,setBrandName] = useState(null)
    const dispatch = useDispatch()

    const token = localStorage.getItem('token')
    const { brandData } = useSelector((state) => state.root)
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }

            const brand_name = brandName.toUpperCase()
            
            dispatch(showloading())
            const {data} = await axios.post(`${URL}/api/Bimer/brand`,{brand_name},config)
                
                if(data.success === true){
                    dispatch(showloading())
                    setShowAddEditModal(false)
                    setBrandName('');
                    dispatch(ReloadData(true))
                    dispatch(hiddenloading())
                    message.success('Brand created successfully')
                }
                
        }catch(err){
            dispatch(hiddenloading())
            message.error('Please try again! Brand not Created successfully')
        }
    }

    const handleDelete = async (id) => {
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const data = await axios.delete(`${URL}/api/Bimer/brand/`+ id,config)
            dispatch(hiddenloading())
            if(data.data.success === true){
                message.success('Brand Deleted Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            message.error(err.message)
        }
    }

    const compareByAlph = (a,b) => {
        if (a > b) { return -1; } if (a < b) { return 1; } return 0;
    }



    return(
        <div>
           <div className="flex flex-col">
                <div className="flex justify-end">
                    <button className="bg-primary text-white w-[200px] py-2 px-5 rounded" onClick={() => {
                        setSelectedItemforEdit(null)
                        setShowAddEditModal(true)
                    }}>Add Product Brand</button>
                </div>
                <hr className="mt-5 mb-5"/>
                <div className="flex items-center justify-center">
                    <Table dataSource={brandData} pagination={
                        {
                            defaultPageSize: 5,
                        }
                    }>
                        <Column title="Product Brand" dataIndex="brand_name" key="brand_name" sorter={ (a, b) => compareByAlph(a.brand_name, b.brand_name)} />
                        <Column title="Action" key="action" render={(brandData, record) => ( <Space size="middle"> <button className="bg-red-500 mt-[2px] text-white w-full p-2"onClick={() =>{
                            setDeleteId(brandData._id)
                            setShowDeleteModal(true)
                        }}>Delete</button> </Space> )}/>
                    </Table>
                </div>
           </div>
           <Modal visible={showAddEditModal} footer={null} onCancel={() => {setShowAddEditModal(false); setSelectedItemforEdit(null)}}>
           <h1 className="text-center text-xl uppercase font-semibold mt-5 mb-5">Add Product Brand </h1>
                <form onSubmit={handleSubmit}>
                    <input className="cinput w-full" placeholder="Product Brand" value={brandName} onChange={(e) => setBrandName(e.target.value)}/>
                    <div className="flex justify-end mt-3 gap-5 w-full">
                        <button type="submit" className="bg-primary text-white w-[150px] px-5 py-1 rounded">Add Brand</button>
                    </div>
                </form>
           </Modal>
           <Modal visible={showDeleteModal} footer={null} closable={false} centered={true} onCancel={() => {setShowDeleteModal(false); setDeleteId(null)}}>
                    <h1 className="text-center text-2xl">Are you sure want to delete?</h1>
                    <div className="flex justify-center items-center gap-5 mt-5">
                        <button className="bg-primary w-[80px] p-1 rounded text-white" onClick={() => {handleDelete(deleteID); setShowDeleteModal(false)}}>Ok</button>
                        <button className="bg-red-500 w-[80px] p-1 rounded text-white" onClick={() => {
                            setShowDeleteModal(false) 
                            setDeleteId(null)
                        }}>Cancel</button>
                    </div>
            </Modal>
        </div>
    )
}

export default ProductBrand;