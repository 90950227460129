/* eslint-disable no-unused-vars */
import { message, Modal } from "antd"
import { useEffect, useRef, useState } from "react"
import JoditEditor from 'jodit-react';
import { useDispatch, useSelector } from "react-redux";
import { hiddenloading, ReloadData, showloading } from "../../../../Redux/server/rootSlice"
import axios from "axios";
import {URL} from '../../../../Url/Url'

const Banner = () => {

    const { bannerData } = useSelector((state) => state.root)

    const [showAddEditModal,setShowAddEditModal] = useState(false)
    const [selectedItemforEdit,setSelectedItemforEdit] = useState(null)
    const [banner_image,setBannerImage] = useState( null)
    const [banner_title,setBannerTitle] = useState(null)
    const [banner_desc,setBannerDesc] = useState(null)
    const [preview,setPreview] = useState(null)
    const dispatch = useDispatch()

    const editor = useRef(null);
    const token = localStorage.getItem('token')

    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        
        transformFile(file)
    }

    const transformFile = (file) => {
        const reader = new FileReader();
        if(file){
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setBannerImage(reader.result);
                setPreview(reader.result)
            };
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.patch(`${URL}/api/Bimer/banner/`+ selectedItemforEdit._id,{banner_image,banner_title,banner_desc},config)
                if(data.success === true){
                    setShowAddEditModal(false)
                    setBannerImage('');
                    setBannerTitle('');
                    setBannerDesc('');
                    setPreview('')
                    dispatch(ReloadData(true))
                    dispatch(hiddenloading())
                    message.success('Banner updated successfully')
                }
                
        }catch(err){

        }
    }

    useEffect(() => {
        if(selectedItemforEdit){
            setBannerTitle(selectedItemforEdit.banner_title)
            setBannerDesc(selectedItemforEdit.banner_desc)
            setBannerImage(selectedItemforEdit.banner_image)
            setPreview(selectedItemforEdit.banner_image.url)
        }
    },[selectedItemforEdit])
    
    return(
        <div>
            <div className="flex flex-col">
                <div className="flex flex-wrap gap-5">
                    {bannerData.map((data) => (
                        <div className="flex flex-col w-[500px]  border-2 rounded">
                            <img className=" w-[600px] object-contain " src={data.banner_image.url} alt="Clients" />
                            <div className="flex justify-between p-5 items-center">
                                <h1 className="font-bold text-2xl uppercase text-primary">{data.banner_title}</h1>
                                <button className="bg-primary text-white w-[200px] py-2 px-5 rounded" onClick={() => {
                                    setSelectedItemforEdit(data)
                                    setShowAddEditModal(true)
                                }}>Update</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Modal visible={showAddEditModal}  footer={null} onCancel={() => {setShowAddEditModal(false); setSelectedItemforEdit(null)}}>'
            <h1 className="text-center text-xl uppercase font-semibold mt-5 mb-5">Update Banner</h1>
                <img className="w-full h-[250px] border-2 rounded object-cover " src={preview} alt=""/>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-5">
                        <div className="flex flex-col gap-2">
                            <label className="font-semibold uppercase">Banner image:</label>
                            <input className="cinput w-full" type="file" onChange={handleFileInputChange} />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label className="font-semibold uppercase">Banner title:</label>
                            <input className="cinput w-full" placeholder="Banner Title" onChange={(e) => setBannerTitle(e.target.value)} value={banner_title} />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label className="font-semibold uppercase">Banner title:</label>
                            <JoditEditor ref={editor} value={banner_desc} onChange={newContent => setBannerDesc(newContent)} />
                        </div>
                    </div>
                    <div className="flex justify-end mt-3 gap-5 w-full">
                        <button type="submit" className="bg-primary text-white w-[150px] px-5 py-1 rounded">Update</button>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export default Banner