/* eslint-disable react-hooks/exhaustive-deps */
import { message, Modal } from "antd";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hiddenloading, ReloadData, showloading } from "../../../../Redux/server/rootSlice"
import JoditEditor from 'jodit-react';
import { FaTrash } from "react-icons/fa";
import {URL} from '../../../../Url/Url'

const AddRooms = () => {

    const { roomsData,onebedroomAData,onebedroomBData,onebedroomCData,twobedroomAData,twobedroomBData,threebedroomData  } = useSelector((state) => state.root)

    const [showAddEditModal,setShowAddEditModal] = useState(false)
    const [selectedItemforEdit,setSelectedItemforEdit] = useState(null)
    const [bedroom_name,setBedroomName] = useState('')
    const [bedroom_type,setBedroomType] = useState('')
    const [bedroom_desc,setBedroomDesc] = useState('')
    const [bedroom_price,setBedroomPrice] = useState('')
    const [bedroom_area,setBedroomArea] = useState('')
    const [bedroom_floor,setBedroomFloor] = useState('')
    const [bedroom_image,setBedroomImage] = useState('')
    const [intro_image,setIntroImage] = useState('')
    const [preview,setPreview] = useState('')
    const [newPreview,setNewPreview] = useState('')
    
    

    useEffect(() => {
        if(selectedItemforEdit){
            setIntroImage(selectedItemforEdit.intro_image)
            setBedroomName(selectedItemforEdit.bedroom_name)
            setBedroomType(selectedItemforEdit.bedroom_type)
            setBedroomDesc(selectedItemforEdit.bedroom_desc)
            setBedroomPrice(selectedItemforEdit.bedroom_price)
            setBedroomArea(selectedItemforEdit.bedroom_area)
            setBedroomFloor(selectedItemforEdit.bedroom_floor)
            setPreview(selectedItemforEdit.intro_image.url)
        }
    },[selectedItemforEdit])

    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        previewFile(file)
        
    }

    const previewFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () =>{
            setIntroImage(reader.result);
            setPreview(reader.result)
        }
    }

    const handleFileNewInputChange = (e) => {
        const file = e.target.files[0]
        NewpreviewFile(file)
        
    }

    const NewpreviewFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () =>{
            setBedroomImage(reader.result);
            setNewPreview(reader.result)
        }
    }

    const editor = useRef(null);

    const dispatch = useDispatch()
    const token = localStorage.getItem('token')


    const handleDelete = async (id) => {
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            if(selectedItemforEdit.bedroom_name === 'Three Bedroom' && selectedItemforEdit.bedroom_type === 'Type-A' ){
                const {data} = await axios.delete(`${URL}/api/Bimer/rooms/threebedroom/`+id,config)
                if(data.success === true){
                    message.success('Delete Image Successfuly')
                    setBedroomImage('')
                    setNewPreview('')
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }
            if(selectedItemforEdit.bedroom_name === 'One Bedroom' && selectedItemforEdit.bedroom_type === 'Type-A' ){
                const {data} = await axios.delete(`${URL}/api/Bimer/rooms/onebedroom_A/`+ id,config)
                if(data.success === true){
                    message.success('Delete Image Successfuly')
                    setBedroomImage('')
                    setNewPreview('')
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }
            if(selectedItemforEdit.bedroom_name === 'One Bedroom' && selectedItemforEdit.bedroom_type === 'Type-B' ){
                const {data} = await axios.delete(`${URL}/api/Bimer/rooms/onebedroom_B/`+id,config)
                if(data.success === true){
                    message.success('Delete Image Successfuly')
                    setBedroomImage('')
                    setNewPreview('')
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }
            if(selectedItemforEdit.bedroom_name === 'One Bedroom' && selectedItemforEdit.bedroom_type === 'Type-C' ){
                const {data} = await axios.delete(`${URL}/api/Bimer/rooms/onebedroom_C/`+id,config)
                if(data.success === true){
                    message.success('Delete Image Successfuly')
                    setBedroomImage('')
                    setNewPreview('')
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }
            if(selectedItemforEdit.bedroom_name === 'Two Bedroom' && selectedItemforEdit.bedroom_type === 'Type-A' ){
                const {data} = await axios.delete(`${URL}/api/Bimer/rooms/twobedroom_A/`+id,config)
                if(data.success === true){
                    message.success('Delete Image Successfuly')
                    setBedroomImage('')
                    setNewPreview('')
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }
            if(selectedItemforEdit.bedroom_name === 'Two Bedroom' && selectedItemforEdit.bedroom_type === 'Type-B' ){
                const {data} = await axios.delete(`${URL}/api/Bimer/rooms/twobedroom_B/`+id,config)
                if(data.success === true){
                    message.success('Delete Image Successfuly')
                    setBedroomImage('')
                    setNewPreview('')
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.patch(`${URL}/api/Bimer/rooms/`+selectedItemforEdit._id,{
                intro_image,bedroom_name,bedroom_type,bedroom_desc,bedroom_area,bedroom_floor,bedroom_price
            },config)
            if(data.success === true){
                message.success('Rooms content Updated Successfuly')
                setShowAddEditModal(false)
                setBedroomArea('')
                setBedroomDesc('')
                setBedroomFloor('')
                setBedroomName('')
                setBedroomPrice('')
                setBedroomType('')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }
    const handleBedroom = async (e) => {
        e.preventDefault();

        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            console.log(selectedItemforEdit.bedroom_image)
            if(selectedItemforEdit.bedroom_name === 'Three Bedroom' && selectedItemforEdit.bedroom_type === 'Type-A' ){
                const {data} = await axios.post(`${URL}/api/Bimer/rooms/threebedroom`,{
                    bedroom_image
                },config)
                if(data.success === true){
                    message.success('Add Image Successfuly')
                    setBedroomImage('')
                    setNewPreview('')
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }
            if(selectedItemforEdit.bedroom_name === 'One Bedroom' && selectedItemforEdit.bedroom_type === 'Type-A' ){
                const {data} = await axios.post(`${URL}/api/Bimer/rooms/onebedroom_A`,{
                    bedroom_image
                },config)
                if(data.success === true){
                    message.success('Add Image Successfuly')
                    setBedroomImage('')
                    setNewPreview('')
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }
            if(selectedItemforEdit.bedroom_name === 'One Bedroom' && selectedItemforEdit.bedroom_type === 'Type-B' ){
                const {data} = await axios.post(`${URL}/api/Bimer/rooms/onebedroom_B`,{
                    bedroom_image
                },config)
                if(data.success === true){
                    message.success('Add Image Successfuly')
                    setBedroomImage('')
                    setNewPreview('')
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }
            if(selectedItemforEdit.bedroom_name === 'One Bedroom' && selectedItemforEdit.bedroom_type === 'Type-C' ){
                const {data} = await axios.post(`${URL}/api/Bimer/rooms/onebedroom_C`,{
                    bedroom_image
                },config)
                if(data.success === true){
                    message.success('Add Image Successfuly')
                    setBedroomImage('')
                    setNewPreview('')
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }
            if(selectedItemforEdit.bedroom_name === 'Two Bedroom' && selectedItemforEdit.bedroom_type === 'Type-A' ){
                const {data} = await axios.post(`${URL}/api/Bimer/rooms/twobedroom_A`,{
                    bedroom_image
                },config)
                if(data.success === true){
                    message.success('Add Image Successfuly')
                    setBedroomImage('')
                    setNewPreview('')
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }
            if(selectedItemforEdit.bedroom_name === 'Two Bedroom' && selectedItemforEdit.bedroom_type === 'Type-B' ){
                const {data} = await axios.post(`${URL}/api/Bimer/rooms/twobedroom_B`,{
                    bedroom_image
                },config)
                if(data.success === true){
                    message.success('Add Image Successfuly')
                    setBedroomImage('')
                    setNewPreview('')
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }
    return(
        <div className="flex flex-col">

            <div className="flex flex-wrap gap-5">
                {roomsData.map((data) => (
                    <div className="border-2 rounded w-[500px] h-[400px]">
                        <img className="w-[500px] h-auto" src={data.intro_image.url} alt=""/>
                        <div className="flex justify-between m-5 items-center">
                            <h1 className="text-2xl font-semibold text-primary">{data.bedroom_name} ({data.bedroom_type})</h1>
                            <button className="bg-primary text-white w-[100px] px-5 py-1 rounded" onClick={() => {
                            setSelectedItemforEdit(data);
                            setShowAddEditModal(true)
                            }}>Update</button>
                        </div>
                    </div>
                ))}
            </div>
            <Modal visible={showAddEditModal} width={'800px'}  footer={null} onCancel={() => {setShowAddEditModal(false); setNewPreview(null);setSelectedItemforEdit(null)}}>
            <div>
                <div className=" flex flex-col mt-10 mb-5 gap-5 ">
                    <h1 className="text-primary font-semibold text-2xl text-center">{bedroom_name} ({bedroom_type})</h1>
                    <hr/>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-2">
                        <label className="text-lg uppercase text-primary font-semibold ">Description:</label>
                        <JoditEditor
                        ref={editor}
                        value={bedroom_desc}
                        onChange={newContent => setBedroomDesc(newContent)}     
                        />
                        <label className="text-lg uppercase text-primary font-semibold ">Price ($):</label>
                        <input className="cinput w-full" onChange={(e) => setBedroomPrice(e.target.value)} value={bedroom_price}/>
                        <label className="text-lg uppercase text-primary font-semibold ">Area (msq):</label>
                        <input className="cinput w-full" onChange={(e) => setBedroomArea(e.target.value)} value={bedroom_area}/>
                        <label className="text-lg uppercase text-primary font-semibold ">Floor:</label>
                        <input className="cinput w-full" onChange={(e) => setBedroomFloor(e.target.value)} value={bedroom_floor}/>
                        <label className="text-lg uppercase text-primary font-semibold ">Image:</label>
                        <div className="flex gap-5 w-full items-center">
                            <img className="w-1/2 h-auto rounded" src={preview} alt=""/>
                            <input className="cinput w-1/2" type="file" onChange={handleFileInputChange}/>
                        </div>
                        <div className="flex justify-end mb-5">
                            <button className="bg-primary text-white w-[100px] px-5 py-1 rounded mt-5" type="submit">Update</button>
                        </div>
                    </div>
                </form>
            </div>

            <hr/>

            <h1 className="text-lg uppercase text-primary font-semibold mt-5 mb-5">Rooms Images</h1>

            {bedroom_name === 'One Bedroom' && bedroom_type === 'Type-A' ? (
                <div className="flex flex-wrap gap-5 justify-center">
                    {onebedroomAData.map((data) => (
                        <div className="w-[200px] h-[130px]">
                            <img className="w-[200px] h-[125px] rounded -z-10" src={data.bedroom_image.url} alt=""/>
                            <button onClick={() => handleDelete(data._id)} className="text-red-500 ml-[180px] -mt-[120px] absolute z-10"><FaTrash/></button>
                        </div>
                    ))}
                </div>
            ):<div></div>}

            {bedroom_name === 'One Bedroom' && bedroom_type === 'Type-B' ? (
                <div className="flex flex-wrap gap-5 justify-center">
                    {onebedroomBData.map((data) => (
                        <div className="w-[200px] h-[130px]">
                            <img className="w-[200px] h-[125px] rounded -z-10" src={data.bedroom_image.url} alt=""/>
                            <button onClick={() => handleDelete(data._id)} className="text-red-500 ml-[180px] -mt-[120px] absolute z-10"><FaTrash/></button>
                        </div>
                    ))}
                </div>
            ):<div></div>}
            
            {bedroom_name === 'One Bedroom' && bedroom_type === 'Type-C' ? (
                <div className="flex flex-wrap gap-5 justify-center">
                    {onebedroomCData.map((data) => (
                        <div className="w-[200px] h-[130px]">
                            <img className="w-[200px] h-[125px] rounded -z-10" src={data.bedroom_image.url} alt=""/>
                            <button onClick={() => handleDelete(data._id)} className="text-red-500 ml-[180px] -mt-[120px] absolute z-10"><FaTrash/></button>
                        </div>
                    ))}
                </div>
            ):<div></div>}

            {bedroom_name === 'Two Bedroom' && bedroom_type === 'Type-A' ? (
                <div className="flex flex-wrap gap-5 justify-center">
                    {twobedroomAData.map((data) => (
                        <div className="w-[200px] h-[130px]">
                            <img className="w-[200px] h-[125px] rounded -z-10" src={data.bedroom_image.url} alt=""/>
                            <button onClick={() => handleDelete(data._id)} className="text-red-500 ml-[180px] -mt-[120px] absolute z-10"><FaTrash/></button>
                        </div>
                    ))}
                </div>
            ):<div></div>}

            {bedroom_name === 'Two Bedroom' && bedroom_type === 'Type-B' ? (
                <div className="flex flex-wrap gap-5 justify-center">
                    {twobedroomBData.map((data) => (
                        <div className="w-[200px] h-[130px]">
                            <img className="w-[200px] h-[125px] rounded -z-10" src={data.bedroom_image.url} alt=""/>
                            <button onClick={() => handleDelete(data._id)} className="text-red-500 ml-[180px] -mt-[120px] absolute z-10"><FaTrash/></button>
                        </div>
                    ))}
                </div>
            ):<div></div>}

            {bedroom_name === 'Three Bedroom' && bedroom_type === 'Type-A' ? (
                <div className="flex flex-wrap gap-5 justify-center">
                    {threebedroomData.map((data) => (
                        <div className="w-[200px] h-[130px]">
                            <img className="w-[200px] h-[125px] rounded -z-10" src={data.bedroom_image.url} alt=""/>
                            <button onClick={() => handleDelete(data._id)} className="text-red-500 ml-[180px] -mt-[120px] absolute z-10"><FaTrash/></button>
                        </div>
                    ))}
                </div>
            ):<div></div>}

            <hr/>
            <h1 className="text-lg uppercase text-primary font-semibold mt-5 mb-5">Add New Image</h1>
                <form onSubmit={handleBedroom}>
                    <div className="flex gap-5 w-full items-center">
                        <img className="w-1/2 h-auto rounded" src={newPreview} alt=""/>
                        <input className="cinput w-1/2" type="file" onChange={handleFileNewInputChange}/>
                    </div>
                    <div className="flex justify-end mb-5">
                            <button className="bg-primary text-white w-[100px] px-5 py-1 rounded mt-5" type="submit">Add</button>
                        </div>
                </form>

            </Modal>
        </div>
    )
}

export default AddRooms;