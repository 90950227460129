/* eslint-disable jsx-a11y/iframe-has-title */

import BimerAddress from "./BimerAddress";
import BimerMap from "./BimerMap";
import ContactUs from "./ContactUs";
import EndFooter from "./EndFooter";
import SocialMedialLinks from "./SocialMediaLinks";

const Footer = () => {
    
    return(
        <div>
            <div className="bg-primary w-full h-auto flex flex-col justify-center">
                <div className="flex llg2:flex-col justify-center items-center gap-5">
                    <BimerMap/>
                    <div className="llg2:w-9/12 llg2:h-[1px] llg2:bg-white llg2:mb-5 llg2:mt-5"></div>
                    <BimerAddress/>
                    <div className="llg2:w-9/12 llg2:h-[1px] llg2:bg-white llg2:mb-5 llg2:mt-5"></div>
                    <ContactUs/>
                </div>
                <br/>
                <SocialMedialLinks/>
                <div className="h-[1px] w-full bg-white rounded mt-5 mb-3"></div>
                <EndFooter/>
            </div>
        </div>
    )
}

export default Footer;