import { useEffect, useRef, useState } from "react"
import JoditEditor from 'jodit-react';
import { useDispatch, useSelector } from "react-redux";
import { hiddenloading, ReloadData, showloading } from "../../../../Redux/server/rootSlice"
import axios from "axios";
import { message } from "antd";
import {URL} from '../../../../Url/Url'


const LiftingTopDesc = () => {

    const { liftingData } = useSelector((state) => state.root)

    const [lifting_desc1,setLiftingDesc1] = useState('')
    const [lifting_image1,setLiftingImage1] = useState('')
    const dispatch = useDispatch()

    const token = localStorage.getItem('token')
    
    const [preview1,setPreview1] = useState('')

    const editor = useRef(null);

    useEffect(() => { 
        setLiftingDesc1(liftingData.lifting_desc1)
        setLiftingImage1(liftingData.lifting_image1)
        setPreview1(liftingData.lifting_image1.url)
    },[liftingData])

    const handleFileInputChange1 = (e) => {
        const file = e.target.files[0]
        
        transformFile1(file)
    }

    const transformFile1 = (file) => {
        const reader = new FileReader();
        if(file){
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setLiftingImage1(reader.result);
                setPreview1(reader.result)
            };
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.patch(`${URL}/api/Bimer/lifting/description1`,{
                lifting_desc1,lifting_image1
            },config)
            dispatch(hiddenloading())
            if(data.success === true){
                message.success('Lifting content Updated Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }

    return(
        <div className="flex flex-col gap-5">
              <form onSubmit={onSubmit}>
                
                <h1 className="text-2xl mb-5 font-semibold text-primary text-center uppercase">Lifting Description</h1>
                <div className="flex flex-col gap-5">
                    <JoditEditor ref={editor} value={lifting_desc1} onChange={newContent => setLiftingDesc1(newContent)} />
                </div>
                <div className="flex mt-5 w-full gap-5 items-center">
                    <div className="w-1/2">
                        <img className="w-[400px] h-[300px] border-2 rounded" src={preview1} alt=""/>
                    </div>
                    <div className="flex flex-col w-1/2">
                        <input type="file" className="cinput w-[400px]" onChange={handleFileInputChange1}/>
                    </div>
                </div>
                <div className="flex mt-5 justify-end w-full">
                        <button type="submit" className="bg-primary text-white w-[100px] py-2 px-5 rounded">Update</button>
                </div>
           </form>

        </div>
    )
}

export default LiftingTopDesc