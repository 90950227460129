import { useEffect } from "react";
import { useParams } from "react-router-dom";
import StoreNavbar from "../Components/NavbarComponents/Store-Navbar/StoreNavbar";
import SparepartTitle from "../Components/BimerStoreComponents/BimerSparepartStore/BimerSparepartStoreDetails/SparepartTitle";
import SparepartImage from "../Components/BimerStoreComponents/BimerSparepartStore/BimerSparepartStoreDetails/SparepartImage";
import SparepartDescription from "../Components/BimerStoreComponents/BimerSparepartStore/BimerSparepartStoreDetails/SparepartDescription";

const SparepartDetails = () => {

    const {id} = useParams()


    useEffect(() => {
        window.scrollTo(0, 0);
    },[])

    return (
        <div className="w-full">
            <StoreNavbar/>
            <SparepartTitle id={id}/>
            <div className="flex justify-center md:flex-col md:items-center mb-10">
                <div className="w-[550px] vsm:w-full px-10 vsmm:px-5">
                    <SparepartImage id={id}/>
                </div>
                <div>
                    <SparepartDescription id={id}/>
                </div>
            </div>
        </div>
    )
}

export default SparepartDetails;