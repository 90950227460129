import { useSelector } from "react-redux";
import VehiclesGallery from "./VehiclesGallery/VehiclesGallery";

const VehicleImage = ({id}) => {

    const {vehicleProductsData} = useSelector((state) => state.root)

    return(
        <div>
            {vehicleProductsData.filter(vehicle => vehicle._id === id).map(vehicle => (
                <div>
                    
                        <VehiclesGallery GalleryData={vehicle}/>
                    
                </div>
            ))}
        </div>
    )
}

export default VehicleImage;