/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Slider from "react-slick";
import Card from "./Card";

const ApartmentGallery =  ({GalleryData}) =>  {
    const [selectedImg,setSelectedImg] = useState(GalleryData[0])
    const [progress,setProgress] = useState(0)
    const [slidetoshow,setslidetoshow] = useState(4)


    const setslide = () => {
        if(window.innerWidth <= 1280 && window.innerWidth > 1000){
            setslidetoshow(3)
        }
        else if(window.innerWidth <=1000 && window.innerWidth > 780){
            setslidetoshow(2)
        }
        else if(window.innerWidth <= 650){
            setslidetoshow(1)
        }
    }   
        useEffect(() => {
            setslide()
            setProgress(100/(GalleryData.length - slidetoshow + 1))
            window.addEventListener('resize',() => {setslide()})
        },[])

        const settings = {
          dots: true,
          nextArrow: false,
          prevArrow: false,
          infinite: false,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            // {
            //   breakpoint: 480,
            //   settings: {
            //     slidesToShow: 2,
            //     slidesToScroll: 2
            //   }
            // }
          ]
        };
    return(
        <div className="gallery relative">
            <div className="flex flex-col w-full justify-center items-center mb-5">
              <img className="w-[700px] vsmm:w-[300px] border-2 rounded object-contain" src={selectedImg.bedroom_image.url} alt="img"/>
            </div>
            <Slider {...settings}>
                {GalleryData.map((data,index) => (
                    <div onClick={() => setSelectedImg(data)} key={index} >
                        <Card img={data.bedroom_image.url}/>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default ApartmentGallery;