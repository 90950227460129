import { useSelector } from "react-redux";

const LiftingContent = () => {
    
    const { liftingData } = useSelector((state) => state.root)

    return(
        <div>
                <div className="flex flex-col px-28 md:px-5 gap-10 py-10 mt-5">
                    <div className="flex md:flex-col w-full justify-center items-center gap-8">
                        <div className="flex md:w-full flex-col gap-5 w-1/2">
                            <div dangerouslySetInnerHTML={{__html: liftingData.lifting_desc1}}/>
                        </div>
                        <div className="flex justify-center items-center md:w-full w-1/2">
                            <img className="w-[500px]" src={liftingData.lifting_image1.url} alt="images"/>
                        </div>
                    </div>
                    <div className="flex md:flex-col flex-row-reverse justify-center items-center w-full gap-8">
                        <div className="flex flex-col gap-5 w-1/2 md:w-full">
                            <div dangerouslySetInnerHTML={{__html: liftingData.lifting_desc2}}/>
                        </div>
                        <div className="flex justify-center items-center md:w-full w-1/2">
                            <img className="w-[500px] " src={liftingData.lifting_image2.url} alt="images"/>
                        </div>
                    </div>
                    <div className="flex w-full gap-20 md:gap-5 md:flex-col ">
                        <div className="flex flex-col md:w-full w-1/2 gap-5">
                            <h1 className="text-xl text-primary font-semibold uppercase">Operational solutions</h1>
                            <div className="flex flex-col">
                                <div dangerouslySetInnerHTML={{__html: liftingData.operational_desc}}/>
                            </div>
                        </div>
                        <div className="flex flex-col md:w-full w-1/2 gap-5">
                            <h1 className="text-xl text-primary font-semibold uppercase">Rental programs</h1>
                            <div className="flex flex-col">
                                <div dangerouslySetInnerHTML={{__html: liftingData.rental_desc}}/>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default LiftingContent;