import { useSelector } from "react-redux";
import SparepartGallery from "./SparepartGallery/SparepartGallery";

const SparepartImage = ({id}) => {

    const {sparepartItemsData} = useSelector((state) => state.root)

    return(
        <div>
            {sparepartItemsData.filter(sparepart => sparepart._id === id).map(sparepart => (
                <div>
                    <SparepartGallery GalleryData={sparepart}/>
                </div>
            ))}
        </div>
    )
}

export default SparepartImage;