import { useSelector } from "react-redux";

const AboutSection = ({show}) => {
    const { aboutData } = useSelector((state) => state.root)
    return(
        <div className="w-full flex flex-col px-32 llg:px-20 sm2:px-10  py-10 items-center justify-center">
            
                <div>
                    <div className="flex w-full items-center sm2:flex-col">
                        <div className="flex flex-col w-1/2 sm2:w-full gap-8">
                            <h1 className="text-primary text-4xl font-semibold">Who We Are</h1>
                            <div dangerouslySetInnerHTML={{__html: aboutData.about_desc}}/>
                        </div>
                        <div className="w-1/2 sm2:w-full ml-20 sm2:ml-0 sm2:mt-10">
                            <img className="w-full h-[350px] object-contain rounded" src={aboutData.about_image.url} alt="Des"/>
                        </div>
                    </div>
                    <div className="flex flex-row-reverse w-full items-center mt-5 sm2:flex-col">
                        <div className="flex flex-col w-1/2 sm2:w-full gap-8">
                            <h1 className="text-primary text-4xl font-semibold">Our Vision and Values</h1>
                            <div>
                                <div dangerouslySetInnerHTML={{__html: aboutData.vision_desc}}/>
                            </div>
                        </div>
                        <div className="w-1/2 sm2:w-full mr-20 sm2:mr-0 sm2:mt-10">
                            <img className="w-full h-[350px] object-contain rounded" src={aboutData.vision_image.url} alt="Des"/>
                        </div>
                    </div>
              </div>
            
        </div>
    )
}

export default AboutSection;