import { message, Modal } from "antd"
import axios from "axios";
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { hiddenloading , ReloadData, showloading } from "../../../../Redux/server/rootSlice";
import {URL} from '../../../../Url/Url'

const Testimonials = () => {

    const { testimonialData } = useSelector((state) => state.root)

    const [showAddEditModal,setShowAddEditModal] = useState(false)
    const [selectedItemforEdit,setSelectedItemforEdit] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteID,setDeleteId] = useState(null)
    const [full_name,setFullName] = useState('')
    const [work_title,setWorkTitle] = useState('')
    const [client_desc,setClientDesc] = useState('')
    const [client_image,setclientImage] = useState('')
    const [preview,setPreview] = useState('')
    const dispatch = useDispatch()

    const token = localStorage.getItem('token')

    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        previewFile(file)
        
    }

    const previewFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () =>{
            setclientImage(reader.result);
            setPreview(reader.result)
        }
    }

    useEffect(() => {
        if(selectedItemforEdit){
            setFullName(selectedItemforEdit.full_name)
            setWorkTitle(selectedItemforEdit.work_title)
            setClientDesc(selectedItemforEdit.client_desc)
            setclientImage(selectedItemforEdit.client_image)
            setPreview(selectedItemforEdit.client_image.url)
        }
        else{
            setFullName('')
            setWorkTitle('')
            setClientDesc('')
            setclientImage('')
            setPreview('')
        }
    },[selectedItemforEdit])

    const handleDelete = async (id) => {
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const data = await axios.delete(`${URL}/api/Bimer/testimonies/`+ id,config)
            dispatch(hiddenloading())
            if(data.data.success === true){
                message.success('testmonies Deleted Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            message.error(err.message)
        }
    }

    const submitForm = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            if(selectedItemforEdit){
                const {data} = await axios.patch(`${URL}/api/Bimer/testimonies/`+ selectedItemforEdit._id,{full_name,work_title,client_desc,client_image},config)
                if(data.success === true){
                    setShowAddEditModal(false)
                    setFullName('');
                    setWorkTitle('');
                    setclientImage('');
                    setClientDesc('')
                    setPreview('')
                    dispatch(ReloadData(true))
                    dispatch(hiddenloading())
                    message.success('testimonies updated successfully')
                }
            }
            else{
                const {data} = await axios.post(`${URL}/api/Bimer/testimonies`,{full_name,work_title,client_desc,client_image},config)
                
                if(data.success === true){
                    dispatch(showloading())
                    setShowAddEditModal(false)
                    setFullName('');
                    setWorkTitle('');
                    setclientImage('');
                    setClientDesc('')
                    setPreview('')
                    dispatch(ReloadData(true))
                    dispatch(hiddenloading())
                    message.success('testimonies created successfully')
                }
                
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }

    return(

        <div>
            <div className="flex flex-col">
                <div className="flex justify-end">
                    <button className="bg-primary text-white w-[200px] py-2 px-5 rounded" onClick={() => {
                    setSelectedItemforEdit(null);
                    setShowAddEditModal(true)
                }} >Add Testimonies</button>
                </div>
                <hr className="mt-5 mb-5"/>
                <div className="flex flex-wrap gap-8">
                    {testimonialData.map((data) => (
                        <div className="w-[250px] h-[400px] border-2 rounded mb-5">
                            <div className="flex flex-col">
                                <img className="h-[250px] w-full object-cover rounded mb-2" src={data.client_image.url} alt="team"/>
                                <h1 className="font-semibold uppercase text-center text-xl">{data.full_name}</h1>
                                <h1 className="text-lg text-center mb-5">{data.work_title}</h1>
                            </div>
                            <div className="flex gap-5 justify-center px-5 ">
                                <button className="bg-primary text-white w-[100px] py-2 px-5 rounded" onClick={() => {
                                    setSelectedItemforEdit(data)
                                    setShowAddEditModal(true)
                                }}>Update</button>
                                <button className="bg-red-500 text-white w-[100px] py-2 px-5 rounded" onClick={() => {
                                    setDeleteId(data._id)
                                    setShowDeleteModal(true)
                                }}>Delete</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Modal visible={showAddEditModal}  footer={null} onCancel={() => {setShowAddEditModal(false); setSelectedItemforEdit(null)}}>
                <div>
                <h1 className="text-center text-xl uppercase font-semibold mt-5 mb-5">{selectedItemforEdit ? 'Edit Testimonies' : 'Add Testimonies'}</h1>
                <hr/>
                <form onSubmit={submitForm}>
                        <div className="flex flex-col gap-2 mt-2">
                            <label className="font-semibold uppercase ">Full Name:</label>
                            <input className="cinput w-full" placeholder="Full Name" onChange={(e) => setFullName(e.target.value)} value={full_name} />
                        </div>
                        <div className="flex flex-col gap-2 mt-2">
                            <label className="font-semibold uppercase ">Work title:</label>
                            <input className="cinput w-full" placeholder="Title" onChange={(e) => setWorkTitle(e.target.value)} value={work_title} />
                        </div>
                        <div className="flex flex-col gap-2 mt-2">
                            <label className="font-semibold uppercase ">Client Describtion:</label>
                            <textarea className="ctextarea w-full" placeholder="Description" onChange={(e) => setClientDesc(e.target.value)} value={client_desc} />
                        </div>
                    <div className="flex w-full items-center mt-5">
                        <div className="w-1/2">
                            <img src={preview} alt="" className="border-2" style={{height:'100px',width:'100px',borderRadius:'50%', backgroundImage: `url('https://res.cloudinary.com/dtlrrlpag/image/upload/v1677832343/image_placeholder_k2xgc5.jpg')`,backgroundRepeat: 'no-repeat',backgroundSize:'contain'}}/>
                        </div>
                        <div className="flex flex-col gap-2 w-1/2">
                                <label className="font-semibold uppercase ">Image:</label>
                                <input className="cinput w-full" type="file" onChange={handleFileInputChange} />
                            
                        </div>
                    </div>     
                    <div className="flex justify-end gap-5 w-full">
                        <button type="submit" className="bg-primary text-white w-[150px] px-5 py-1 rounded">{selectedItemforEdit ? 'Update Testimonies' : 'Add Testimonies'}</button>
                    </div>
                </form>
                </div>
            </Modal>
            <Modal visible={showDeleteModal} footer={null} closable={false} centered={true} onCancel={() => {setShowDeleteModal(false); setDeleteId(null)}}>
                    <h1 className="text-center text-2xl">Are you sure want to delete?</h1>
                    <div className="flex justify-center items-center gap-5 mt-5">
                        <button className="bg-primary w-[80px] p-1 rounded text-white" onClick={() => {handleDelete(deleteID); setShowDeleteModal(false)}}>Ok</button>
                        <button className="bg-red-500 w-[80px] p-1 rounded text-white" onClick={() => {
                            setShowDeleteModal(false) 
                            setDeleteId(null)
                        }}>Cancel</button>
                    </div>
            </Modal>
        </div>
    )
}

export default Testimonials;