/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Slider from "react-slick";
import Card from "./Card";

const SafetyImageGallery= ({imageDatas}) => {
    const [wide,setwide] = useState(true)
    const [selectedImg,setSelectedImg] = useState(imageDatas[0])
    const [showAddEditModal,setShowAddEditModal] = useState(false)
    const [progress,setProgress] = useState(0)
    const [slidetoshow,setslidetoshow] = useState(1)
    const setslide = () => {
        if(window.innerWidth <= 1280 && window.innerWidth > 1000){
            setslidetoshow(1)
        }
        else if(window.innerWidth <=1000 && window.innerWidth > 780){
            setslidetoshow(1)
        }
        else if(window.innerWidth <= 650){
            setslidetoshow(1)
        }
    }   
    useEffect(() => {
        setslide()
        setProgress(100/(imageDatas.length - slidetoshow + 1))
        window.addEventListener('resize',() => {setslide()})
        console.log(imageDatas)
    },[])

    const settings = {
        dots: true,
        nextArrow: false,
        prevArrow: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
      };
    return(
        <div className="gallery relative">
            <Slider {...settings}>
                {imageDatas.map((data,index) => (
                    <div key={index}>
                        <Card pic={data.safety_image.url}/>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default SafetyImageGallery;