import { useEffect, useRef, useState } from "react"
import { hiddenloading, ReloadData, showloading } from "../../../../Redux/server/rootSlice"
import JoditEditor from 'jodit-react';
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import axios from "axios";
import {URL} from '../../../../Url/Url'

const VehiclesContent = () => {

    const { vehiclesData } = useSelector((state) => state.root)

    const [intro_image,setIntroImage] = useState('')
    const [vehicles_desc,setVehiclesDesc] =useState('')
    const [spare_part,setSparePart] = useState('')
    const [preview,setPreview] = useState('')
    const editor = useRef(null);
    const token = localStorage.getItem('token')
    const dispatch = useDispatch()

    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        
        transformFile(file)
    }

    useEffect(() => {
        setIntroImage(vehiclesData.intro_image)
        setVehiclesDesc(vehiclesData.vehicles_desc)
        setSparePart(vehiclesData.spare_part)
        setPreview(vehiclesData.intro_image.url)
    },[vehiclesData])

    const transformFile = (file) => {
        const reader = new FileReader();
        if(file){
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setIntroImage(reader.result);
                setPreview(reader.result)
            };
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.patch(`${URL}/api/Bimer/vehicles`,{
                intro_image,vehicles_desc,spare_part
            },config)
            dispatch(hiddenloading())
            if(data.success === true){
                message.success('Vehicles content Updated Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }


    return(
        <div className="flex flex-col gap-5">

           <form onSubmit={onSubmit}>

                <div className="flex flex-col w-full gap-3 mb-5 items-center">
                        <img className="w-full h-[500px] border-2 rounded" src={preview} alt=""/>
                        <input type="file" className="ml-5 cinput w-full" onChange={handleFileInputChange}/>
                </div>
                <div className="flex flex-col gap-5">
                <JoditEditor
                ref={editor}
                value={vehicles_desc}
                onChange={newContent => setVehiclesDesc(newContent)}     
                />
                <JoditEditor
                ref={editor}
                value={spare_part}
                onChange={newContent => setSparePart(newContent)}     
                />
                </div>
                <div className="flex mt-5 justify-end w-full">
                        <button type="submit" className="bg-primary text-white w-[100px] py-2 px-5 rounded">Update</button>
                </div>
           </form>
        </div>
    )
}

export default VehiclesContent