import axios from 'axios';
import JoditEditor from 'jodit-react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hiddenloading, ReloadData, showloading } from "../../../../Redux/server/rootSlice"
import { message } from 'antd';
import {URL} from '../../../../Url/Url'


const OperationalRental = () => {

    const { liftingData } = useSelector((state) => state.root)

    const [operational_desc,setOperationalDesc] = useState('')
    const [rental_desc,setRentalDesc] = useState('')
    const dispatch = useDispatch()

    const token = localStorage.getItem('token')
    
    const editor = useRef(null);
    
    useEffect(() => { 
        setOperationalDesc(liftingData.operational_desc)
        setRentalDesc(liftingData.rental_desc)
    },[liftingData])

    const submit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.patch(`${URL}/api/Bimer/lifting/operational`,{
                operational_desc,rental_desc
            },config)
            dispatch(hiddenloading())
            if(data.success === true){
                message.success('Lifting content Updated Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }

    return(
        <div className="flex flex-col gap-5">
            <form onSubmit={submit}>
                <h1 className="text-2xl mb-5 font-semibold text-primary text-center uppercase">OPERATIONAL SOLUTIONS</h1>
                <JoditEditor
                ref={editor}
                value={operational_desc}
                onChange={newContent => setOperationalDesc(newContent)}     
                />
                <h1 className="text-2xl mt-5 mb-5 font-semibold text-primary text-center uppercase">RENTAL PROGRAMS</h1>
                <JoditEditor
                ref={editor}
                value={rental_desc}
                onChange={newContent => setRentalDesc(newContent)}     
                />
                <div className="flex mt-5 justify-end w-full">
                        <button type="submit" className="bg-primary text-white w-[100px] py-2 px-5 rounded">Update</button>
                </div>
            </form>
        </div>
    )
}

export default OperationalRental;