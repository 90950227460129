import { useEffect } from "react";
import Navbar from "../Components/NavbarComponents/Main-Navbar/Navbar";
import LiftingIntro from "../Components/LiftingPageComponents/LiftingIntro/LiftingIntro";
import LiftingContent from "../Components/LiftingPageComponents/LiftingContent/LiftingContent";
import Footer from "../Components/FooterPageComponents/Footer";
import { useSelector } from "react-redux";
import ScrollToTop from "react-scroll-to-top";

const LiftingPage = () => {

    useEffect(() => {
        document.title = "Lifting - Bimer Business Group"
        window.scrollTo(0, 0);
    })

    const { liftingData } = useSelector((state) => state.root)

    return(
        <div>
            {liftingData && (
                <div className="w-full">
                    <Navbar/>
                    <LiftingIntro/>
                    <LiftingContent/>
                    <ScrollToTop smooth top="20" color="white" style={{
                        backgroundColor: "#CBA540",
                        width: "40px",
                        height: "40px",
                        borderRadius: "100%",
                        alignItems: "center"
                    }}
                        viewBox="5 3 10 30"
                        svgPath="M24,21a1,1,0,0,1-.71-.29L16,13.41l-7.29,7.3a1,1,0,1,1-1.42-1.42l8-8a1,1,0,0,1,1.42,0l8,8a1,1,0,0,1,0,1.42A1,1,0,0,1,24,21Z"
                    />
                    <Footer/>
                </div>
            )}
        </div>
    )
}

export default LiftingPage;