import TeamSlider from "./TeamSlider";

const OurTeam = () => {
  
    return (
      <div className="w-full mb-5 p-10 sm:p-10">
        <div className="w-full">
            <h1 className="text-primary text-4xl font-semibold text-center mb-16 sm:mb-5">Meet Our Team</h1>
            <div className="px-32 sm:p-5" >
                <TeamSlider/>
            </div>  
        </div>
      </div>
    );
}

export default OurTeam;