import JoditEditor from "jodit-react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReloadData, hiddenloading, showloading } from "../../../../Redux/server/rootSlice";
import axios from "axios";
import { message } from "antd";
import {URL} from '../../../../Url/Url'

const ExportTopDesc = () => {

    const { exportData } = useSelector((state) => state.root)

    const [export_desc1,setExportDesc1] = useState('')
    const dispatch = useDispatch()
    const editor = useRef(null);

    const token = localStorage.getItem('token')

    useEffect(() => { 
        setExportDesc1(exportData.export_desc1)
    },[exportData])

    const onSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.patch(`${URL}/api/Bimer/export/description1`,{
                export_desc1
            },config)
            dispatch(hiddenloading())
            if(data.success === true){
                message.success('Export content Updated Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }

    return (
        <div className="flex flex-col gap-5">
              <form onSubmit={onSubmit}>
                
                <h1 className="text-2xl mb-5 font-semibold text-primary text-center uppercase">Export Top Description</h1>
                <div className="flex flex-col gap-5">
                    <JoditEditor ref={editor} value={export_desc1} onChange={newContent => setExportDesc1(newContent)} />
                </div>
                <div className="flex mt-5 justify-end w-full">
                        <button type="submit" className="bg-primary text-white w-[100px] py-2 px-5 rounded">Update</button>
                </div>
           </form>

        </div>
    )
}

export default ExportTopDesc;