import { Tabs } from "antd";
import StoreNavbar from "../Components/NavbarComponents/Store-Navbar/StoreNavbar";
import TabPane from "antd/es/tabs/TabPane";
import VehicleStore from "../Components/BimerStoreComponents/BimerVehiclesStore/VehiclesStore";
import Footer from "../Components/FooterPageComponents/Footer";
import SparepartStore from "../Components/BimerStoreComponents/BimerSparepartStore/SparepartStore";
import { useEffect } from "react";

const BimerStore = () => {

    useEffect(() => {
        document.title = "Bimer Store - Bimer Business Group"
    })

    return(
        <div>
            <StoreNavbar/>
            <div className="flex flex-col w-full">
                <Tabs defaultActiveKey="1" centered>
                    <TabPane tab={<h1 className="text-xl uppercase">Vehicles</h1>} key="1">
                        <VehicleStore/>
                    </TabPane>
                    <TabPane tab={<h1 className="text-xl uppercase">Spare Part</h1>} key="2">
                        <SparepartStore/>
                    </TabPane>
                </Tabs>
            </div>
            <Footer/>
        </div>
    )
}

export default BimerStore;