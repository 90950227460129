import { useSelector } from "react-redux";

const VehiclesIntro = () => {

   const  {vehiclesData} = useSelector((state) => state.root)

    return(
        <div className="mb-5">
                <div className="w-full h-[100vh] llg:h-[90vh] llg:bg-contain sm2:h-[70vh] csm:h-[60vh] vs2:h-[50vh] vvsm:h-[40vh]" style={{backgroundImage: `url(${vehiclesData.intro_image.url})`,backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
                    <div className="flex flex-col px-20 vvsm:p-3 vs2:gap-3 vvsm:gap-2 z-0 justify-center h-screen llg:h-[90vh] sm2:h-[70vh] csm:h-[60vh] vs2:h-[50vh] bg-black bg-opacity-30 vvsm:h-[40vh] gap-5">
                        <h1 className=" text-4xl vs2:text-2xl font-thin vvsm:text-xl text-white uppercase ">Bimer Vehicles</h1>
                        <div className="flex flex-col">
                            <h1 className=" text-6xl vs2:text-4xl font-bold vvsm:text-2xl text-white uppercase ">pick your brand </h1>
                            <h1 className=" text-6xl vs2:text-4xl font-bold vvsm:text-2xl text-white uppercase ">new car</h1>
                        </div>
                        <div className="w-[150px] vvsm:w-[80px] h-[4px] bg-white rounded"></div>
                    </div>
                </div>
        </div>
    )
}

export default VehiclesIntro;