/* eslint-disable react-hooks/exhaustive-deps */
import './App.css';
import { BrowserRouter as Router, Routes , Route } from 'react-router-dom';
import HomePage from './Clients/Screens/HomePage';
import AboutPage from './Clients/Screens/AboutPage';
import PropertiesPage from './Clients/Screens/PropertiesPage';
import BimerApartmentComplexPage from './Clients/Screens/BimerApartmentComplexPage';
import VehiclesPage from './Clients/Screens/VehiclesPage';
import LogisticsPage from './Clients/Screens/LogisticsPage';
import LiftingPage from './Clients/Screens/LiftingPage';
import NotFoundPage from './Clients/Screens/NotFoundPage';
import AdminLoginPage from './Admin/Screens/AdminLoginPage';
import AdminHomePage from './Admin/Screens/AdminHomePage';
import AdminAboutPage from './Admin/Screens/AdminAboutPage';
import AdminPropertiesPage from './Admin/Screens/AdminPropertiesPage';
import AdminVehiclesPage from './Admin/Screens/AdminVehiclesPage';
import AdminLogisticsPage from './Admin/Screens/AdminLogisticsPage';
import AdminLiftingPage from './Admin/Screens/AdminLiftingPage';
import AdminSettingPage from './Admin/Screens/AdminSettingPage';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { showloading,
  setBannerData,
  setServicesData,
  setTestimonialData,
  setClientData,
  setContactData,
  setTeamData,
  setAboutData,
  setLogisticsData,
  setLiftingData,
  setVehiclesData,
  setImportData,
  setShowroomData,
  setPropertiesData,
  setRoomsData,
  setOneBedroomAData,
  setOneBedroomBData,
  setOneBedroomCData,
  setTwoBedroomAData,
  setTwoBedroomBData,
  setThreeBedroomData,
  setSafetyData,
  setBrandData,
  setVehicleProductsData,
  setVehicleProductslengthData,
  setSparepartItemsData,
  setSparepartItemsLengthData,
  setExportData,
  setExportItemData,
  setRoom201Data,
  setRoom202Data,
  setRoom203Data,
  setRoom301Data,
  setRoom302Data,
  setRoom303Data,
  setRoom401Data,
  setRoom402Data,
  setRoom403Data,
  setRoom501Data,
  setRoom502Data,
  setRoom503Data,
  setRoom601Data,
  setRoom602Data,
  setRoom603Data,
  setRoom701Data,
  setRoom702Data,
  setRoom703Data,
  setRoom801Data,
  setRoom802Data,
  setRoom803Data,
  setRoom901Data,
  setRoom902Data,
  setRoom903Data,
  hiddenloading,
  ReloadData, 
} from './Redux/server/rootSlice';
import axios from "axios";
import LoadingPage from './Clients/Screens/LoadingPage';
import {URL} from './Url/Url'
import AdminProductsPage from './Admin/Screens/AdminProductsPage';
import BimerStore from './Clients/Screens/BimerStore';
import VehicleDetails from './Clients/Screens/VehiclesDetails';
import SparepartDetails from './Clients/Screens/SparepartDetails';
import SocialMediaPage from './Clients/Screens/SocialMediaPage';
import ExportPage from './Clients/Screens/ExportPage';
import AdminExportPage from './Admin/Screens/AdminExportpage';
import Room201 from './Clients/Screens/Properties Page/2-Floor/Room-201';
import Q201Room from './Clients/Screens/Question Pages/2-Floor/Q201Room';
import Room202 from './Clients/Screens/Properties Page/2-Floor/Room-202';
import Q202Room from './Clients/Screens/Question Pages/2-Floor/Q202Room';
import Room203 from './Clients/Screens/Properties Page/2-Floor/Room-203';
import Room301 from './Clients/Screens/Properties Page/3-Floor/Room-301';
import Room302 from './Clients/Screens/Properties Page/3-Floor/Room-302';
import Room303 from './Clients/Screens/Properties Page/3-Floor/Room-303';
import Room401 from './Clients/Screens/Properties Page/4-Floor/Room-401';
import Room402 from './Clients/Screens/Properties Page/4-Floor/Room-402';
import Room403 from './Clients/Screens/Properties Page/4-Floor/Room-403';
import Room501 from './Clients/Screens/Properties Page/5-Floor/Room-501';
import Room502 from './Clients/Screens/Properties Page/5-Floor/Room-502';
import Room503 from './Clients/Screens/Properties Page/5-Floor/Room-503';
import Room601 from './Clients/Screens/Properties Page/6-Floor/Room-601';
import Room602 from './Clients/Screens/Properties Page/6-Floor/Room-602';
import Room603 from './Clients/Screens/Properties Page/6-Floor/Room-603';
import Room702 from './Clients/Screens/Properties Page/7-Floor/Room-702';
import Room703 from './Clients/Screens/Properties Page/7-Floor/Room-703';
import Room802 from './Clients/Screens/Properties Page/8-Floor/Room-802';
import Room803 from './Clients/Screens/Properties Page/8-Floor/Room-803';
import Room902 from './Clients/Screens/Properties Page/9-Floor/Room-902';
import Room903 from './Clients/Screens/Properties Page/9-Floor/Room-903';
import Room901 from './Clients/Screens/Properties Page/9-Floor/Room-901';
import Room801 from './Clients/Screens/Properties Page/8-Floor/Room-801';
import Room701 from './Clients/Screens/Properties Page/7-Floor/Room-701';
import Q203Room from './Clients/Screens/Question Pages/2-Floor/Q203Room';
import Q301Room from './Clients/Screens/Question Pages/3-Floor/Q301Room';
import Q302Room from './Clients/Screens/Question Pages/3-Floor/Q302Room';
import Q303Room from './Clients/Screens/Question Pages/3-Floor/Q303Room';
import Q401Room from './Clients/Screens/Question Pages/4-Floor/Q401Room';
import Q402Room from './Clients/Screens/Question Pages/4-Floor/Q402Room';
import Q403Room from './Clients/Screens/Question Pages/4-Floor/Q403Room';
import Q501Room from './Clients/Screens/Question Pages/5-Floor/Q501Room';
import Q502Room from './Clients/Screens/Question Pages/5-Floor/Q502Room';
import Q503Room from './Clients/Screens/Question Pages/5-Floor/Q503Room';
import Q601Room from './Clients/Screens/Question Pages/6-Floor/Q601Room';
import Q602Room from './Clients/Screens/Question Pages/6-Floor/Q602Room';
import Q603Room from './Clients/Screens/Question Pages/6-Floor/Q603Room';
import Q701Room from './Clients/Screens/Question Pages/7-Floor/Q701Room';
import Q702Room from './Clients/Screens/Question Pages/7-Floor/Q702Room';
import Q703Room from './Clients/Screens/Question Pages/7-Floor/Q703Room';
import Q801Room from './Clients/Screens/Question Pages/8-Floor/Q801Room';
import Q802Room from './Clients/Screens/Question Pages/8-Floor/Q802Room';
import Q803Room from './Clients/Screens/Question Pages/8-Floor/Q803Room';
import Q901Room from './Clients/Screens/Question Pages/9-Floor/Q901Room';
import Q902Room from './Clients/Screens/Question Pages/9-Floor/Q902Room';
import Q903Room from './Clients/Screens/Question Pages/9-Floor/Q903Room';

function App() {

  var hours = 12
  var now  = new Date().getTime()
  var setupTime = localStorage.getItem('setupTime')

  if(setupTime === null){
    localStorage.setItem('setupTime',now)
  }
  else{
    if(now - setupTime > hours*60*60*1000){
      localStorage.clear()
      localStorage.setItem('setupTime',now)
    }
  }

  const {
    isLoading,
    reloadData} = useSelector((state) => state.root)
    
  const dispatch = useDispatch()

  const [dataFetched, setDataFetched] = useState({
    bannerData: false,
    serviceData: false,
    testimonialData: false,
    clientData: false,
    contactData: false,
    teamData: false,
    aboutData: false,
    logisticsData: false,
    liftingData: false,
    vehiclesData: false,
    importData: false,
    showroomData: false,
    propertiesData: false,
    roomsData: false,
    onebedroomAData: false,
    onebedroomBData: false,
    onebedroomCData: false,
    twobedroomAData: false,
    twobedroomBData: false,
    threebedroomData: false,
    safetyData: false,
    brandData: false,
    vehicleProductsData: false,
    vehicleProductslengthData: false,
    sparepartItemsData: false,
    sparepartItemsLengthData: false,
    exportData: false,
    exportItemData: false,
    room201Data: false,
    room202Data: false,
    room203Data: false,
    room301Data: false,
    room302Data: false,
    room303Data: false,
    room401Data: false,
    room402Data: false,
    room403Data: false,
    room501Data: false,
    room502Data: false,
    room503Data: false,
    room601Data: false,
    room602Data: false,
    room603Data: false,
    room701Data: false,
    room702Data: false,
    room703Data: false,
    room801Data: false,
    room802Data: false,
    room803Data: false,
    room901Data: false,
    room902Data: false,
    room903Data: false,
  });
  //get all Banner Data 

  const getBannerData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/banner`)
      dispatch(setBannerData(responce.data.banner))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, bannerData: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }
  useEffect(() => {
    if (!dataFetched.bannerData) {
      getBannerData();
    }
  }, [dataFetched.bannerData]);


  // get all services Data

  const getServicesData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/services`)
      dispatch(setServicesData(responce.data.services))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, serviceData: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }
  useEffect(() => {
    if (!dataFetched.serviceData) {
      getServicesData();
    }
  }, [dataFetched.serviceData]);

  // get all testimonals Data

  const getTestimoniesData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/testimonies`)
      dispatch(setTestimonialData(responce.data.team))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({...prev,testimonialData:true}))
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.testimonialData){
      getTestimoniesData()
    }
  },[dataFetched.testimonialData])

  // get all client data

  const getClientData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/client`)
      dispatch(setClientData(responce.data.client))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({...prev,clientData:true}))
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() =>{
    if(!dataFetched.clientData){
      getClientData()
    }
  },[dataFetched.clientData])

  // get all contact data

  const getContactData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/contact`)
      dispatch(setContactData(responce.data.contact))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({...prev,contactData:true}))
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.contactData){
      getContactData()
    }
  }, [dataFetched.contactData])

  //get all team data

  const getTeamData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/team`)
      dispatch(setTeamData(responce.data.team))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({...prev, teamData:true}))
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.teamData){
      getTeamData()
    }
  }, [dataFetched.teamData])

  //get all about content Data

  const getAboutData = async () => {
    try{
      
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/about`)
      dispatch(setAboutData(responce.data.about))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({...prev, aboutData: true}))
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() =>{
    if(!dataFetched.aboutData){
      getAboutData()
    }
  },[dataFetched.aboutData])

  //get all logistics Data

  const getLogisticsData = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/logistics`)
      dispatch(setLogisticsData(responce.data.logistics))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({...prev,logisticsData:true}))
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.logisticsData){
      getLogisticsData()
    }
  },[dataFetched.logisticsData])

  // get all lifting Data

  const getLiftingData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/lifting`)
      dispatch(setLiftingData(responce.data.lifting))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({...prev, liftingData:true}))
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.liftingData){
      getLiftingData()
    }
  },[dataFetched.liftingData])

  // get all vehicles Data

  const getVehiclesData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/vehicles`)
      dispatch(setVehiclesData(responce.data.vehicles))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({...prev,vehiclesData:true}))
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() =>{
    if(!dataFetched.vehiclesData){
      getVehiclesData()
    }
  },[dataFetched.vehiclesData])

  //get all Import Vehicles Data

  const getImportData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/vehicles/import`)
      dispatch(setImportData(responce.data.imports))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, importData: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.importData){
      getImportData()
    }
  },[dataFetched.importData])

  // get all Showroom Data

  
  const getShowroomData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/vehicles/showroom`)
      dispatch(setShowroomData(responce.data.showroom))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, showroomData: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.showroomData){
      getShowroomData()
    }
  },[dataFetched.showroomData])

  // get all Properties Content Data 

  const getPropertiesData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/properties`)
      dispatch(setPropertiesData(responce.data.properties))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, propertiesData: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.propertiesData){
      getPropertiesData()
    }
  },[dataFetched.propertiesData])

  // get all Rooms content Data

  const getRoomsData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/rooms`)
      dispatch(setRoomsData(responce.data.rooms))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, roomsData: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.roomsData){
      getRoomsData()
    }
  },[dataFetched.roomsData])

  // get all Rooms Image Data

  const getAllRooms = async () => {
    try{
      dispatch(showloading())
      const onebedroomA = await axios.get(`${URL}/api/Bimer/rooms/onebedroom_A`)
      const onebedroomB = await axios.get(`${URL}/api/Bimer/rooms/onebedroom_B `)
      const onebedroomC = await axios.get(`${URL}/api/Bimer/rooms/onebedroom_C`)
      const twobedroomA = await axios.get(`${URL}/api/Bimer/rooms/twobedroom_A`)
      const twobedroomB = await axios.get(`${URL}/api/Bimer/rooms/twobedroom_B`)
      const threebedroom = await axios.get(`${URL}/api/Bimer/rooms/threebedroom`)
      dispatch(setOneBedroomAData(onebedroomA.data.onebedroom_A))
      dispatch(setOneBedroomBData(onebedroomB.data.onebedroom_B))
      dispatch(setOneBedroomCData(onebedroomC.data.onebedroom_C))
      dispatch(setTwoBedroomAData(twobedroomA.data.twobedroom_A))
      dispatch(setTwoBedroomBData(twobedroomB.data.twobedroom_B))
      dispatch(setThreeBedroomData(threebedroom.data.threebedroom))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, onebedroomAData: true,onebedroomBData:true, onebedroomCData:true, twobedroomAData:true, twobedroomBData:true, threebedroomData:true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.onebedroomAData && !dataFetched.onebedroomBData && !dataFetched.onebedroomCData && !dataFetched.twobedroomAData && !dataFetched.twobedroomBData && !dataFetched.threebedroomData){
      getAllRooms()
    }
  },[dataFetched.onebedroomAData,dataFetched.onebedroomBData,dataFetched.onebedroomCData,dataFetched.twobedroomAData,dataFetched.twobedroomBData,dataFetched.threebedroomData])

  //get all safety Images

  const getSafetyImage = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/safety`)
      dispatch(setSafetyData(responce.data.safety))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, safetyData: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.safetyData){
      getSafetyImage()
    }
  },[dataFetched.safetyData])

  // get All Brand Data

  const getBrandData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/brand`)
      dispatch(setBrandData(responce.data.brand))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, brandData: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }
  
  useEffect(() => {
    if(!dataFetched.brandData){
      getBrandData()
    }
  },[dataFetched.brandData])

  //get all vehicles product Data

  const getVehiclesProduct = async (page) => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/vehicleProducts?page=${page}`)
      dispatch(setVehicleProductsData(responce.data.vehicleproducts))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, vehicleProductsData: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.vehicleProductsData){
      getVehiclesProduct()
    }
  },[dataFetched.vehicleProductsData])

  const getVehicleProductsLength = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/vehicleProducts/length`)
      dispatch(setVehicleProductslengthData(responce.data.vehicleproducts))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, vehicleProductslengthData: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.vehicleProductslengthData){
      getVehicleProductsLength()
    }
  },[dataFetched.vehicleProductslengthData])

  // get all Spare part items Data

  const getSparepartItems = async (page) => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/sparepartitems?page=${page}`)
      dispatch(setSparepartItemsData(responce.data.spaerpartitems))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, sparepartItemsData: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.sparepartItemsData){
      getSparepartItems()
    }
  },[dataFetched.sparepartItemsData])

  const getSparepartItemsLength = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/sparepartitems/length`)
      dispatch(setSparepartItemsLengthData(responce.data.spaerpartitems))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, sparepartItemsLengthData: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.sparepartItemsLengthData){
      getSparepartItemsLength()
    }
  },[dataFetched.sparepartItemsLengthData])

  // Get all Export Data

  const getExportData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Bimer/export`)
      dispatch(setExportData(responce.data.exporting))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, exportData: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.exportData){
      getExportData()
    }
  },[dataFetched.exportData])

  // Get all export Item List

  const getExportItemData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/export/items`)
      dispatch(setExportItemData(responce.data.exportItem))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, exportItemData: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.exportItemData){
      getExportItemData()
    }
  },[dataFetched.exportItemData])

  // get room-201 data

  const getRoom201Data = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r201`)
      dispatch(setRoom201Data(responce.data.room201))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room201Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room201Data){
      getRoom201Data()
    }
  },[dataFetched.room201Data])

  // get room-202 data

  const getRoom202Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r202`)
      dispatch(setRoom202Data(responce.data.room202))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room202Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room202Data){
      getRoom202Data()
    }
  },[dataFetched.room202Data])

  // get room-203 data

  const getRoom203Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r203`)
      dispatch(setRoom203Data(responce.data.room203))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room203Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room203Data){
      getRoom203Data()
    }
  }, [dataFetched.room203Data])

  // get room-301 data

  const getRoom301Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r301`)
      dispatch(setRoom301Data(responce.data.room301))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room301Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room301Data){
      getRoom301Data()
    }
  },[dataFetched.room301Data])

  // get room-302 data

  const getRoom302Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r302`)
      dispatch(setRoom302Data(responce.data.room302))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room302Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room302Data){
      getRoom302Data()
    }
  },[dataFetched.room302Data])

  // get room-303 data

  const getRoom303Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r303`)
      dispatch(setRoom303Data(responce.data.room303))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room303Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room303Data){
      getRoom303Data()
    }
  },[dataFetched.room303Data])

  // get room-401 data

  const getRoom401Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r401`)
      dispatch(setRoom401Data(responce.data.room401))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room401Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room401Data){
      getRoom401Data()
    }
  },[dataFetched.room401Data])

  // get room-402 data

  const getRoom402Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r402`)
      dispatch(setRoom402Data(responce.data.room402))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room402Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room402Data){
      getRoom402Data()
    }
  },[dataFetched.room402Data])

  // get room-403 data

  const getRoom403Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r403`)
      dispatch(setRoom403Data(responce.data.room403))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room403Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room403Data){
      getRoom403Data()
    }
  },[dataFetched.room403Data])

  // get room-501 data

  const getRoom501Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r501`)
      dispatch(setRoom501Data(responce.data.room501))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room501Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room501Data){
      getRoom501Data()
    }
  },[dataFetched.room501Data])

  // get room-502 data

  const getRoom502Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r502`)
      dispatch(setRoom502Data(responce.data.room502))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room502Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room502Data){
      getRoom502Data()
    }
  },[dataFetched.room502Data])

  // get room-503 data

  const getRoom503Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r503`)
      dispatch(setRoom503Data(responce.data.room503))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room503Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room503Data){
      getRoom503Data()
    }
  },[dataFetched.room503Data])

  // get room-601 data

  const getRoom601Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r601`)
      dispatch(setRoom601Data(responce.data.room601))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room601Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room601Data){
      getRoom601Data()
    }
  },[dataFetched.room601Data])

  // get room-602 data

  const getRoom602Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r602`)
      dispatch(setRoom602Data(responce.data.room602))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room602Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room602Data){
      getRoom602Data()
    }
  },[dataFetched.room602Data])

  // get room-603 data

  const getRoom603Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r603`)
      dispatch(setRoom603Data(responce.data.room603))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room603Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room603Data){
      getRoom603Data()
    }
  },[dataFetched.room603Data])

  // get room-701 data

  const getRoom701Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r701`)
      dispatch(setRoom701Data(responce.data.room701))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room701Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room701Data){
      getRoom701Data()
    }
  },[dataFetched.room701Data])

  // get room-702 data

  const getRoom702Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r702`)
      dispatch(setRoom702Data(responce.data.room702))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room702Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room702Data){
      getRoom702Data()
    }
  },[dataFetched.room702Data])

  // get room-703 data

  const getRoom703Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r703`)
      dispatch(setRoom703Data(responce.data.room703))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room703Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room703Data){
      getRoom703Data()
    }
  },[dataFetched.room703Data])

  // get room-801 data

  const getRoom801Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r801`)
      dispatch(setRoom801Data(responce.data.room801))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room801Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room801Data){
      getRoom801Data()
    }
  },[dataFetched.room801Data])

  // get room-802 data

  const getRoom802Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r802`)
      dispatch(setRoom802Data(responce.data.room802))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room802Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room802Data){
      getRoom802Data()
    }
  },[dataFetched.room802Data])

  // get room-803 data

  const getRoom803Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r803`)
      dispatch(setRoom803Data(responce.data.room803))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room803Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room803Data){
      getRoom803Data()
    }
  },[dataFetched.room803Data])

  // get room-901 data

  const getRoom901Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r901`)
      dispatch(setRoom901Data(responce.data.room901))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room901Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room901Data){
      getRoom901Data()
    }
  },[dataFetched.room901Data])

  // get room-902 data

  const getRoom902Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r902`)
      dispatch(setRoom902Data(responce.data.room902))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room902Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room902Data){
      getRoom902Data()
    }
  },[dataFetched.room902Data])

  // get room-903 data

  const getRoom903Data = async () =>{
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/bimer/room/r903`)
      dispatch(setRoom903Data(responce.data.room903))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
      setDataFetched((prev) => ({ ...prev, room903Data: true }));
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!dataFetched.room903Data){
      getRoom903Data()
    }
  },[dataFetched.room903Data])

  // get all data when reload 

  useEffect(() => {
    if(reloadData){
      getBannerData()
      getServicesData()
      getTestimoniesData()
      getClientData()
      getContactData()
      getTeamData()
      getLogisticsData()
      getLiftingData()
      getVehiclesData()
      getImportData()
      getShowroomData()
      getPropertiesData()
      getRoomsData()
      getAllRooms()
      getSafetyImage()
      getBrandData()
      getVehiclesProduct()
      getVehicleProductsLength()
      getSparepartItems()
      getSparepartItemsLength()
      getExportData()
      getExportItemData()
      getRoom201Data()
      getRoom202Data()
      getRoom203Data()
      getRoom301Data()
      getRoom302Data()
      getRoom303Data()
      getRoom401Data()
      getRoom402Data()
      getRoom403Data()
      getRoom501Data()
      getRoom502Data()
      getRoom601Data()
      getRoom602Data()
      getRoom603Data()
      getRoom701Data()
      getRoom702Data()
      getRoom703Data()
      getRoom801Data()
      getRoom802Data()
      getRoom803Data()
      getRoom901Data()
      getRoom902Data()
      getRoom903Data()

  }},[reloadData])

  return (
    <Router basename='/'>
      {isLoading ? <LoadingPage/>  : null}
      <div className='App'>
        <Routes>
          <Route exact path='/' element={<HomePage/>}/>
          <Route path='about' element={<AboutPage/>}/>
          <Route path='properties' element={<PropertiesPage/>}/>
          <Route path='properties/:id' element={<BimerApartmentComplexPage/>}/>
          <Route path='vehicles' element={<VehiclesPage/>}/>
          <Route path='exports' element={<ExportPage/>}/>
          <Route path='logistics' element={<LogisticsPage/>}/>
          <Route path='lifting' element={<LiftingPage/>}/>
          <Route path='bimerstore' element={<BimerStore/>}/>
          <Route path='social-media' element={<SocialMediaPage/>}/>
          <Route path='bimerrooms=201/b2imer01room' element={<Room201/>}/>
          <Route path='bimerrooms=201/b2imer01room/question201/:id' element={<Q201Room/>}/>
          <Route path='bimerrooms=202/bi2mer0ro02m' element={<Room202/>}/>
          <Route path='bimerrooms=202/bi2mer0ro02m/question202/:id' element={<Q202Room/>}/>
          <Route path='bimerrooms=203/bime2rr03oom' element={<Room203/>}/>
          <Route path='bimerrooms=203/bime2rr03oom/question203/:id' element={<Q203Room/>}/>
          <Route path='bimerrooms=301/bime3r01room' element={<Room301/>}/>
          <Route path='bimerrooms=301/bime3r01room/question301/:id' element={<Q301Room/>}/>
          <Route path='bimerrooms=302/3bimer02room' element={<Room302/>}/>
          <Route path='bimerrooms=302/3bimer02room/question302/:id' element={<Q302Room/>}/>
          <Route path='bimerrooms=303/b3imerroom03' element={<Room303/>}/>
          <Route path='bimerrooms=303/b3imerroom03/question303/:id' element={<Q303Room/>}/>
          <Route path='bimerrooms=401/40bim1erroom' element={<Room401/>}/>
          <Route path='bimerrooms=401/40bim1erroom/question401/:id' element={<Q401Room/>}/>
          <Route path='bimerrooms=402/bim4erroom02' element={<Room402/>}/>
          <Route path='bimerrooms=402/bim4erroom02/question402/:id' element={<Q402Room/>}/>
          <Route path='bimerrooms=403/bimerr4oom03' element={<Room403/>}/>
          <Route path='bimerrooms=403/bimerr4oom03/question403/:id' element={<Q403Room/>}/>
          <Route path='bimerrooms=501/bimer501room' element={<Room501/>}/>
          <Route path='bimerrooms=501/bimer501room/question501/:id' element={<Q501Room/>}/>
          <Route path='bimerrooms=502/502bimerroom' element={<Room502/>}/>
          <Route path='bimerrooms=502/502bimerroom/question502/:id' element={<Q502Room/>}/>
          <Route path='bimerrooms=503/bimerr503oom' element={<Room503/>}/>
          <Route path='bimerrooms=503/bimerr503oom/question503/:id' element={<Q503Room/>}/>
          <Route path='bimerrooms=601/bimerroom601' element={<Room601/>}/>
          <Route path='bimerrooms=601/bimerroom601/question601/:id' element={<Q601Room/>}/>
          <Route path='bimerrooms=602/6bimer0room2' element={<Room602/>}/>
          <Route path='bimerrooms=602/6bimer0room2/question602/:id' element={<Q602Room/>}/>
          <Route path='bimerrooms=603/b6imer0room3' element={<Room603/>}/>
          <Route path='bimerrooms=603/b6imer0room3/question603/:id' element={<Q603Room/>}/>
          <Route path='bimerrooms=701/bi7mer0roo1m' element={<Room701/>}/>
          <Route path='bimerrooms=701/bi7mer0roo1m/question701/:id' element={<Q701Room/>}/>
          <Route path='bimerrooms=702/bim7er0ro20m' element={<Room702/>}/>
          <Route path='bimerrooms=702/bim7er0ro20m/question702/:id' element={<Q702Room/>}/>
          <Route path='bimerrooms=703/bime7r0r3oom' element={<Room703/>}/>
          <Route path='bimerrooms=703/bime7r0r3oom/question703/:id' element={<Q703Room/>}/>
          <Route path='bimerrooms=801/b80imerroom1' element={<Room801/>}/>
          <Route path='bimerrooms=801/b80imerroom1/question801/:id' element={<Q801Room/>}/>
          <Route path='bimerrooms=802/b80imerroo2m' element={<Room802/>}/>
          <Route path='bimerrooms=802/b80imerroo2m/question802/:id' element={<Q802Room/>}/>
          <Route path='bimerrooms=803/b80imerro3om' element={<Room803/>}/>
          <Route path='bimerrooms=803/b80imerro3om/question803/:id' element={<Q803Room/>}/>
          <Route path='bimerrooms=901/bimer9room01' element={<Room901/>}/>
          <Route path='bimerrooms=901/bimer9room01/question901/:id' element={<Q901Room/>}/>
          <Route path='bimerrooms=902/bimer9roo02m' element={<Room902/>}/>
          <Route path='bimerrooms=902/bimer9roo02m/question902/:id' element={<Q902Room/>}/>
          <Route path='bimerrooms=903/bimer9ro03om' element={<Room903/>}/>
          <Route path='bimerrooms=903/bimer9ro03om/question903/:id' element={<Q903Room/>}/>
          <Route path='bimerstore/vehicles/:id' element={<VehicleDetails/>}/>
          <Route path='bimerstore/sparepart/:id' element={<SparepartDetails/>}/>
          <Route path='administrator' element={<AdminLoginPage/>}/>
          <Route path='admindashboard/homepage' element={<AdminHomePage/>}/>
          <Route path='admindashboard/aboutpage' element={<AdminAboutPage/>}/>
          <Route path='admindashboard/propertiespage' element={<AdminPropertiesPage/>}/>
          <Route path='admindashboard/vehiclespage' element={<AdminVehiclesPage/>}/>
          <Route path='admindashboard/logisticspage' element={<AdminLogisticsPage/>}/>
          <Route path='admindashboard/liftingpage' element={<AdminLiftingPage/>}/>
          <Route path='admindashboard/exportpage' element={<AdminExportPage/>}/>
          <Route path='admindashboard/productpage' element={<AdminProductsPage/>}/>
          <Route path='admindashboard/setting' element={<AdminSettingPage/>}/>
          <Route path='*' element={<NotFoundPage/>}/>
        </Routes>
      </div>
      
    </Router>
  );
}

export default App;
