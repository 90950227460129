import { message } from "antd"
import axios from "axios"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { hiddenloading, ReloadData, showloading } from "../../../../Redux/server/rootSlice"
import {URL} from '../../../../Url/Url'

const LiftingIntro = () => {

    const { liftingData } = useSelector((state) => state.root)

    const [preview,setPreview] = useState('')
    const [intro_image,setIntroImage] = useState('')
    const dispatch = useDispatch()

    const token = localStorage.getItem('token')

    useEffect(() => { 
        setIntroImage(liftingData.intro_image)
        setPreview(liftingData.intro_image.url)
    },[liftingData])

    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        
        transformFile(file)
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.patch(`${URL}/api/Bimer/lifting/intro`,{
                intro_image
            },config)
            dispatch(hiddenloading())
            if(data.success === true){
                message.success('Lifting content Updated Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }
    

    const transformFile = (file) => {
        const reader = new FileReader();
        if(file){
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setIntroImage(reader.result);
                setPreview(reader.result)
            };
        }
    }
    return(
        <div className="flex flex-col gap-8">
        <div>
        <img className="w-full h-[400px] border-2 rounded object-cover " src={preview} alt=""/>
        </div>
        <div className="flex w-full items-center gap-5" >
            <div className="w-full ">
                <form onSubmit={onSubmit}>
                    
                <input className="cinput w-full" type="file" onChange={handleFileInputChange}  />
                    

                    <div className="flex justify-end mt-5 gap-5 w-full">
                        <button type="submit" className="bg-primary text-white w-[150px] px-5 py-1 rounded">Update</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    )
}

export default LiftingIntro;