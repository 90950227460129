/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSelector } from "react-redux";

const SparepartTitle = ({id}) => {

    const {sparepartItemsData} = useSelector((state) => state.root)

    useEffect(() => {
        sparepartItemsData.filter(sparepart => sparepart._id === id).map(sparepart => (
            document.title = `${sparepart.sparepart_type} - Bimer Business Group`
        ))
    },[id])

    return(
        <div className="flex items-center justify-center">
            {sparepartItemsData.filter(sparepart => sparepart._id === id).map(sparepart => (
                <h1 className="text-2xl mt-5 mb-10 uppercase font-bold">{sparepart.sparepart_type} ({sparepart.sparepart_partnumber})</h1>
            ))}
        </div>
    )
}

export default SparepartTitle;