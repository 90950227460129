import { useSelector } from "react-redux"
import Card from "./Card";

const ExportContent = () => {
    const { exportData,exportItemData } = useSelector((state) => state.root)
    return (
        <div className="w-full flex flex-col px-32 llg:px-20 sm2:px-10  py-10 items-center justify-center">
            
                <div>
                    <div className="flex w-full items-center sm2:flex-col">
                        <div className="flex flex-col w-full gap-8">
                            <div dangerouslySetInnerHTML={{__html: exportData.export_desc1}}/>
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-5 mt-5 justify-center items-center">
                    {exportItemData.map((data) => (
                        <Card name={data.item_name} img={data.item_image.url}/>
                    ))}
                    </div>
              </div>
            
        </div>
    )
}

export default ExportContent;