const SocialMediaList = () => {
    return (
        <div className="flex flex-col justify-center items-center gap-10 mt-16">
            <div className="flex gap-5">
                <a href="https://www.instagram.com/bimer.ethiopia/">
                    <div className="w-[390px] flex gap-5 h-[70px] bg-white shadow-lg border-2 border-[#f5f5f5] rounded-md">
                        <div>
                            <lottie-player src="https://lottie.host/6f10a622-6f24-41f2-84e5-b63c2e7942ef/PMrGsfHo1v.json" background="transparent" speed="1" loop autoplay></lottie-player>
                        </div>
                        <div className="flex flex-col justify-center">
                            <h1 className="text-lg text-black">Instagram</h1>
                            <h1 className="text-sm text-gray-800">instagram.com/bimer.ethiopia</h1>
                        </div>
                    </div>
                </a>
                <a href="https://www.linkedin.com/company/bimer-ethiopia">
                    <div className="w-[390px] flex gap-5 h-[70px] bg-white shadow-lg border-2 border-[#f5f5f5] rounded-md">
                        <div>
                            <lottie-player src="https://lottie.host/6bcbfd3f-63eb-4ff8-92b1-b99236cb7cc1/sL9Q8Xjm0F.json" background="transparent" speed="1" loop autoplay></lottie-player>
                        </div>
                        <div className="flex flex-col justify-center">
                            <h1 className="text-lg text-black">LinkedIn</h1>
                            <h1 className="text-sm text-gray-800">linkedin.com/company/bimer-ethiopia</h1>
                        </div>
                    </div>
                </a>
            </div>
            <div className="flex gap-5 mb-10">
                <a href="https://www.facebook.com/bimerethiopia">
                <div className="w-[390px] flex gap-5 h-[70px] bg-white shadow-lg border-2 border-[#f5f5f5] rounded-md">
                    <div>
                        <lottie-player className="h-[65px] w-[65px]" src="https://lottie.host/5d639ddc-eda1-468e-9933-03324ef78cfb/T3XaMlEiCX.json" background="transparent" speed="1" loop autoplay></lottie-player>
                    </div>
                    <div className="flex flex-col justify-center">
                        <h1 className="text-lg text-black">Facebook</h1>
                        <h1 className="text-sm text-gray-800">facebook.com/bimerethiopia</h1>
                    </div>
                </div>
                </a>
                <a href="https://www.twitter.com/BimerEthiopia">
                    <div className="w-[390px] flex gap-5 h-[70px] bg-white shadow-lg border-2 border-[#f5f5f5] rounded-md">
                        <div>
                            <lottie-player className="h-[65px] w-[65px]" src="https://lottie.host/585d2274-a802-4dfc-a4ee-b6f890d78558/tHod5jelIq.json" background="transparent" speed="1" loop autoplay></lottie-player>
                        </div>
                        <div className="flex flex-col justify-center">
                            <h1 className="text-lg text-black">Twitter</h1>
                            <h1 className="text-sm text-gray-800">twitter.com/BimerEthiopia</h1>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default SocialMediaList