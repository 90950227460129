const SocialMediaLogo = () => {
    return(
        <div className="flex flex-col justify-center items-center mt-16 ">
                <div className=" w-[800px] h-[250px] md:w-[600px] vs2:w-[400px] vs2:h-[150px] rounded-lg shadow-2xl flex flex-col gap-5 items-center">
                    <div className="w-[150px] h-[150px] vs2:w-[100px] vs2:h-[100px] border-2 rounded-md -mt-5 shadow-2xl bg-[#262262] flex p-5 items-center">
                        <img src="https://res.cloudinary.com/dtlrrlpag/image/upload/v1674887984/Bimer%20Website/Bimer%20Logo/Bimer_Logo_flmrkj.png" alt="Logo" />
                    </div>
                    <h1 className="text-2xl font-bold vs2:text-xl vs2:mt-0 mt-5">@bimerethiopia</h1>
                    <a className="-mt-3" href="/">Back to HomePage</a>
                </div>
        </div>
    )
}

export default SocialMediaLogo;