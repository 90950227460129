const Card = ({img,name}) => {
    return(
        <div className="flex flex-col">
            <div className="mb-5">
                <div>
                    <img className="w-[400px] vsmm:w-[300px]  border-2 hover:shadow-xl vsmm:object-contain rounded mb-2 " src={img} alt="team"/>
                </div>
            </div>
            <h1 className="text-center text-primary font-semibold text-xl">{name}</h1>
        </div>
    )
}

export default Card;