/* eslint-disable jsx-a11y/iframe-has-title */
import { useSelector } from "react-redux";
import Card from "./Card";

const VehiclesContent = () => {

    const  {vehiclesData,importData} = useSelector((state) => state.root)

    return(
        <div className="flex flex-col px-28 sm2:px-10 py-10 justify-center items-center">
                <div>
                    <div dangerouslySetInnerHTML={{__html: vehiclesData.vehicles_desc}}/>
                    <div className="flex flex-wrap gap-8 justify-center items-center mt-5">
                        {importData.map((vehicle) => (
                            <Card name={vehicle.vehicles_name} img={vehicle.vehicles_image.url}/>
                        ))}
                    </div>
                    <div className="flex  sm:flex-col  w-full mt-5 justify-center gap-10  items-center">
                        <div className="w-1/2 sm:w-full flex">
                            <div dangerouslySetInnerHTML={{__html: vehiclesData.spare_part}}/>
                        </div>
                        <div className="w-1/2 sm:w-full flex flex-col justify-center items-center">
                            <h1 className="text-xl text-primary text-center font-semibold uppercase">Kaliti Showroom</h1>
                            <div className="flex vsmm:flex-col mt-5 gap-5 justify-center items-center">
                                <iframe className="w-[250px] h-[150px]"   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.629633029588!2d38.77743507467084!3d8.913980991142864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b83457fa22543%3A0x6e38035889a4dbe4!2sWejera%20Berhe%20Ware!5e0!3m2!1sen!2sus!4v1684690392059!5m2!1sen!2sus"  allowfullscreen="" aria-hidden="false" tabindex="0" width="100%" height="250" frameborder="0"></iframe>
                                <h1 className="w-6/12 vsmm:w-full"><span className="font-bold text-lg uppercase">Location:- </span>Kaliti Korki Fabrica, on the road to Maru Metal Industry</h1>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default VehiclesContent;