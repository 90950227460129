import SocialMediaList from "../Components/SocialMedia Components/SocialMediaList";
import SocialMediaLogo from "../Components/SocialMedia Components/SocialMediaLogo";

const SocialMediaPage = () => {
    return(
        <div className="w-full h-screen  bg-[#f5f5f5] flex flex-col ">
            <SocialMediaLogo/>
            <SocialMediaList/>
        </div>
    )
}

export default SocialMediaPage;