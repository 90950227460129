/* eslint-disable no-unused-vars */
import { message, Modal } from "antd";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hiddenloading , ReloadData, showloading } from "../../../../Redux/server/rootSlice";
import {URL} from '../../../../Url/Url'

const Clients = () => {

    const [showAddEditModal,setShowAddEditModal] = useState(false)
    const [selectedItemforEdit,setSelectedItemforEdit] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteID,setDeleteId] = useState(null)
    const [client_image,setClientImage] = useState(null)
    const [preview,setPreview] = useState(null)
    const dispatch = useDispatch()

    const { clientData } = useSelector((state) => state.root)

    const token = localStorage.getItem('token')

    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        
        transformFile(file)
    }

    const transformFile = (file) => {
        const reader = new FileReader();
        if(file){
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setClientImage(reader.result);
                setPreview(reader.result)
            };
        }
    }

    const handleDelete = async (id) => {
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const data = await axios.delete(`${URL}/api/Bimer/client/`+ id,config)
            dispatch(hiddenloading())
            if(data.data.success === true){
                message.success('Client Deleted Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            message.error(err.message)
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.post(`${URL}/api/Bimer/client`,{client_image},config)
                
                if(data.success === true){
                    dispatch(showloading())
                    setShowAddEditModal(false)
                    setClientImage('');
                    setPreview('')
                    dispatch(ReloadData(true))
                    dispatch(hiddenloading())
                    message.success('Client created successfully')
                }
                
        }catch(err){

        }
    }

    return(
        <div>
            <div className="flex flex-col">
                <div className=" flex  justify-end"> 
                    <button className="bg-primary text-white w-[200px] py-2 px-5 rounded" onClick={() => {
                    setSelectedItemforEdit(null);
                    setShowAddEditModal(true)
                }}>Add Client</button>
                </div>
                <hr className="mt-5 mb-5"/>
                <div className="flex flex-wrap gap-5">
                    {clientData.map((data) => (
                        <div className="flex flex-col w-[300px] h-[190px] border-2 rounded">
                            <img className=" w-[300px] h-[150px] " src={data.client_image.url} alt="Clients" />
                            <button onClick={() => {
                                setDeleteId(data._id)
                                setShowDeleteModal(true)
                            }} className="bg-red-500 mt-[2px] text-white w-full p-2">Delete</button>
                        </div>
                    ))}
                </div>
            </div>
            <Modal visible={showAddEditModal}  footer={null} onCancel={() => {setShowAddEditModal(false); setSelectedItemforEdit(null)}}>
            <h1 className="text-center text-xl uppercase font-semibold mt-5 mb-5">Add Clients</h1>
                <img className="w-full h-[250px] border-2 rounded object-cover " src={preview} alt=""/>
                <form onSubmit={handleSubmit}>
                    <input className="cinput w-full" type="file" onChange={handleFileInputChange} />
                    <div className="flex justify-end mt-3 gap-5 w-full">
                        <button type="submit" className="bg-primary text-white w-[150px] px-5 py-1 rounded">Add Client</button>
                    </div>
                </form>
            </Modal>
            <Modal visible={showDeleteModal} footer={null} closable={false} centered={true} onCancel={() => {setShowDeleteModal(false); setDeleteId(null)}}>
                    <h1 className="text-center text-2xl">Are you sure want to delete?</h1>
                    <div className="flex justify-center items-center gap-5 mt-5">
                        <button className="bg-primary w-[80px] p-1 rounded text-white" onClick={() => {handleDelete(deleteID); setShowDeleteModal(false)}}>Ok</button>
                        <button className="bg-red-500 w-[80px] p-1 rounded text-white" onClick={() => {
                            setShowDeleteModal(false) 
                            setDeleteId(null)
                        }}>Cancel</button>
                    </div>
            </Modal>
        </div>
    )
}

export default Clients;