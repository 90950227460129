const EndFooter = () => {

    const today = new Date();
    const year = today.getFullYear();

    return(
        <div>
                <h1 className="text-center sm:text-sm uppercase text-white mb-2">Copyright @{year} <a href="https://www.nextcommunicationeth.com/">NEXT STUDIO</a> All Rights Reserved.</h1>
        </div>
    )
}

export default EndFooter