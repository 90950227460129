import { useEffect, useRef, useState } from "react"
import JoditEditor from 'jodit-react';
import { hiddenloading, ReloadData, showloading } from "../../../../Redux/server/rootSlice"
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { message } from "antd";
import {URL} from '../../../../Url/Url'

const LiftingBottomDesc = () => {

    const { liftingData } = useSelector((state) => state.root)

    const [preview2,setPreview2] = useState('')
    const [lifting_image2,setLiftingImage2] = useState('')
    const [lifting_desc2,setLiftingDesc2] = useState('')
    const editor = useRef(null);

    const dispatch = useDispatch()

    const token = localStorage.getItem('token')

    useEffect(() => { 
        setLiftingDesc2(liftingData.lifting_desc2)
        setLiftingImage2(liftingData.lifting_image2)
        setPreview2(liftingData.lifting_image2.url)
    },[liftingData])

    const handleFileInputChange2 = (e) => {
        const file = e.target.files[0]
        
        transformFile2(file)
    }

    const transformFile2 = (file) => {
        const reader = new FileReader();
        if(file){
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setLiftingImage2(reader.result);
                setPreview2(reader.result)
            };
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.patch(`${URL}/api/Bimer/lifting/description2`,{
                lifting_desc2,lifting_image2
            },config)
            dispatch(hiddenloading())
            if(data.success === true){
                message.success('Lifting content Updated Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }

    return(
        <div>
            <form onSubmit={onSubmit}>
            <h1 className="text-2xl mb-5 font-semibold text-primary text-center uppercase">Lifting Description</h1>
                <div className="flex flex-col mt-5 gap-5">
                <JoditEditor ref={editor} value={lifting_desc2} onChange={newContent => setLiftingDesc2(newContent)} />
                    {/* <textarea className="atextarea h-[250px] w-full" onChange={(e) => setLiftingDesc2(e.target.value)} value={lifting_desc2}/> */}
                </div>
                <div className="flex mt-5 w-full gap-5 items-center">
                    <div className="w-1/2">
                        <img className="w-[400px] h-[300px] border-2 rounded" src={preview2} alt=""/>
                    </div>
                    <div className="flex flex-col w-1/2">
                        <input type="file" className="cinput w-[400px]" onChange={handleFileInputChange2}/>
                    </div>
                </div>
                <div className="flex mt-5 justify-end w-full">
                    <button type="submit" className="bg-primary text-white w-[100px] py-2 px-5 rounded">Update</button>
                </div>
            </form>
        </div>
    )
}

export default LiftingBottomDesc;