import { useSelector } from "react-redux";

const SparepartDescription = ({id}) => {

    const {sparepartItemsData} = useSelector((state) => state.root)

    return(
        <div className="flex flex-col gap-3 lg:px-0 vsmm:px-8 lgg:mt-8 ">
            {sparepartItemsData.filter(sparepart => sparepart._id === id).map(sparepart => (
                <div>
                     <div className="w-[300px] flex flex-col border-2 rounded">
                        <h1 className="text-xl p-2 uppercase font-semibold text-primary text-center">{sparepart.sparepart_type} ({sparepart.sparepart_partnumber})</h1>
                        <hr/>
                        <h1 className="text-xl py-1 px-5"><span className="font-bold">Brand:-</span> {sparepart.sparepart_brand}</h1>
                        <hr/>
                        <h1 className="text-xl py-1 px-5"><span className="font-bold">Availability:-</span> {sparepart.sparepart_availability}</h1>
                        <hr/>
                        <h1 className=" py-1 px-5" dangerouslySetInnerHTML={{__html: sparepart.sparepart_description}}/>
                     </div>
                </div>
            ))}
        </div>
    )
}

export default SparepartDescription;