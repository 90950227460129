/* eslint-disable react-hooks/exhaustive-deps */
import { Form, message} from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { hiddenloading, ReloadData, showloading } from "../../../../Redux/server/rootSlice";
import {URL} from '../../../../Url/Url'

const ContactForm = () => {
    
    const { contactData } = useSelector((state) => state.root)

    const token = localStorage.getItem('token')
    const dispatch = useDispatch()

    const onFinished = async (values) => {
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const responce = await axios.patch(`${URL}/api/Bimer/contact`,{
                ...values
            },config)
            dispatch(hiddenloading())
            if(responce.data.status){
                message.success('Contact Updated Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            message.error(err.message)
        }
    }

    
    return(
        <div className="flex flex-col w-full mt-5 justify-center items-center">
            <Form onFinish={onFinished} layout="vertical" initialValues={contactData}>
                <Form.Item className=" font-semibold" label="Location:" name={"location"}>
                    <input className="cinput w-[500px]"  placeholder="Location"/>
                </Form.Item>
                <Form.Item className=" font-semibold" label="Phone Number:" name={"phonenumber"}>
                    <input className="cinput w-[500px]" placeholder="Phone Number"/>
                </Form.Item>
                <Form.Item className=" font-semibold" label="Email:" name={"email"}>
                    <input className="cinput w-[500px]" placeholder="Email"/>
                </Form.Item>
                <Form.Item className=" font-semibold" label="Instagram Link:" name={"instagram_link"}>
                    <input className="cinput w-[500px]" placeholder="Instagram Link"/>
                </Form.Item>
                <Form.Item className=" font-semibold" label="Linkedin Link:" name={"linkedin_link"}>
                    <input className="cinput w-[500px]" placeholder="Linkedin Link"/>
                </Form.Item>
                <Form.Item className=" font-semibold" label="Twitter Link:" name={"twitter_link"}>
                    <input className="cinput w-[500px]" placeholder="Twitter Link"/>
                </Form.Item>
                <div className="flex justify-end w-full">
                    <button className="bg-primary text-white w-[100px] py-2 px-5 rounded">Update</button>
                </div>
            </Form>
        </div>
    )
}

export default ContactForm;